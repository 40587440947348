<script>
import {
  required,
  email,
  sameAs,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import store from "@/state/store";

/**
 * Login component
 */
export default {
  page: {
    title: "Register",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      email: "",
      password: "",
      confirm_password: "",
      first_name: "",
      last_name: "",
      contact_number: "",
      company_name: null,
      showSuccessMessage: false,
      submitted: false,
      authError: null,
      isAuthError: false,
      passwordIcon: "fa fa-eye-slash",
      passwordInputType: "password",
      confirmPasswordIcon: "fa fa-eye-slash",
      confirmPasswordInputType: "password",
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      regex: function () {
        let regexPassword = new RegExp(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/
        );
        let regexValidation = !!regexPassword.exec(this.password);
        let lengthOfPassword = this.password.length;
        return regexValidation && lengthOfPassword >= 8;
      },
      spaceCheck: function () {
        return !this.password.includes(' ');
      }
    },
    confirm_password: {
      required,
      sameAs: sameAs("password"),
    },
    first_name: {
      required,
      maxLength: maxLength(15),
      textOnly: function () {
        let textRegex = new RegExp(/^[a-zA-Z]*$/);
        let textValidation = !!textRegex.exec(this.first_name);
        return textValidation;
      }
    },
    last_name: {
      required,
      maxLength: maxLength(15),
      textOnly: function () {
        let textRegex = new RegExp(/^[a-zA-Z]*$/);
        let textValidation = !!textRegex.exec(this.last_name);
        return textValidation;
      }
    },
    company_name: {},
    contact_number: {
      required,
      maxLength: maxLength(14),
      minLength: minLength(14),
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    document.body.classList.add("authentication-bg");
  },
  methods: {
    showPassword() {
      if (this.passwordInputType == "password") {
        this.passwordInputType = "text";
        this.passwordIcon = "fa fa-eye";
      } else {
        this.passwordInputType = "password";
        this.passwordIcon = "fa fa-eye-slash";
      }
    },

    showConfirmPassword() {
      if (this.confirmPasswordInputType == "password") {
        this.confirmPasswordInputType = "text";
        this.confirmPasswordIcon = "fa fa-eye";
      } else {
        this.confirmPasswordInputType = "password";
        this.confirmPasswordIcon = "fa fa-eye-slash";
      }
    },

    async submitForm() {
      this.isAuthError = false;
      this.authError = null;
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let {
          first_name,
          last_name,
          email,
          password,
          contact_number,
          company_name,
        } = this;
        contact_number = contact_number.replace(/[^0-9]/g, "");
        email = email.toLowerCase();
        first_name = first_name.trim();
        last_name = last_name.trim();

        if ((email && password && last_name && first_name, contact_number)) {
          await this.$store.dispatch("authentication/register", {
            first_name,
            last_name,
            email,
            password,
            contact_number,
            company_name,
          });

          if (store.getters["authentication/isValidationError"]) {
            this.isAuthError = true;
            this.authError = store.getters["authentication/validationMessage"];
          } else {
            this.showSuccessMessage = true;
            // this.$router.push(
            //   this.$route.query.redirectFrom || {
            //     path: "/login",
            //   }
            // );
          }
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12"></div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <router-link to="/" class="mb-5 d-block auth-logo">
              <img
                src="@/assets/images/brands/moontowerlogo.png"
                alt
                height="70"
                class="logo logo-dark"
              />
            </router-link>
            <div class="card" v-if="!showSuccessMessage">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h3>Promoter Registration</h3>
                </div>
                <div class="p-2 mt-4">
                  <b-alert
                    :show="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >

                  <b-form @submit.prevent="submitForm">
                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group
                          id="input-group-1"
                          class="mb-3"
                          label="First Name"
                          label-for="input-1"
                        >
                          <b-form-input
                            id="input-1"
                            v-model="first_name"
                            type="text"
                            placeholder="First Name"
                            :class="{
                              'is-invalid': submitted && $v.first_name.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="submitted && $v.first_name.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.first_name.required"
                              >First name is required.</span
                            >
                            <span v-else-if="!$v.first_name.maxLength">
                              Maximum length is 15 characters long.
                            </span>
                            <span v-else-if="!$v.first_name.textOnly">
                              Give valid first name.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group
                          id="input-group-1"
                          class="mb-3"
                          label="Last Name"
                          label-for="input-1"
                        >
                          <b-form-input
                            id="input-1"
                            v-model="last_name"
                            type="text"
                            placeholder="Last Name"
                            :class="{
                              'is-invalid': submitted && $v.last_name.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="submitted && $v.last_name.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.last_name.required"
                              >Last name is required.</span
                            >
                            <span v-else-if="!$v.last_name.maxLength">
                              Maximum length is 15 characters long.
                            </span>
                            <span v-else-if="!$v.last_name.textOnly">
                              Give valid last name.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <b-form-group
                      id="input-group-1"
                      class="mb-3"
                      label="Email"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="email"
                        type="text"
                        placeholder="Email"
                        :class="{ 'is-invalid': submitted && $v.email.$error }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.email.required"
                          >Email is required.</span
                        >
                        <span v-if="!$v.email.email"
                          >Please enter valid email.</span
                        >
                      </div>
                    </b-form-group>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group id="input-group-2" class="mb-3">
                          <label for="input-2">Password</label>

                          <div class="input-group">
                            <b-form-input
                              id="input-2"
                              v-model="password"
                              :type="passwordInputType"
                              placeholder="Password"
                              :class="{
                                'is-invalid': submitted && $v.password.$error,
                              }"
                            ></b-form-input>
                            <div class="input-group-text">
                              <a
                                style="color: #000"
                                href="javascript:void(0)"
                                @click="showPassword"
                              >
                                <i :class="passwordIcon"></i>
                              </a>
                            </div>
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >
                              Password is required.
                            </div>
                            <div
                              v-else-if="submitted && !$v.password.spaceCheck"
                              class="invalid-feedback"
                            >
                              Password should not contain any space.
                            </div>
                            <div
                              v-else-if="submitted && !$v.password.regex"
                              class="invalid-feedback"
                            >
                              Password must be at least 8 characters long and
                              contain at least one lowercase, one uppercase
                              character and one special character.
                            </div>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group id="input-group-2" class="mb-3">
                          <label for="input-2">Confirm Password</label>

                          <div class="input-group">
                            <b-form-input
                              id="input-2"
                              v-model="confirm_password"
                              :type="confirmPasswordInputType"
                              placeholder="Confirm Password"
                              :class="{
                                'is-invalid':
                                  submitted && $v.confirm_password.$error,
                              }"
                            ></b-form-input>
                            <div class="input-group-text">
                              <a
                                style="color: #000"
                                href="javascript:void(0)"
                                @click="showConfirmPassword"
                              >
                                <i :class="confirmPasswordIcon"></i>
                              </a>
                            </div>

                            <div
                              v-if="submitted && !$v.confirm_password.required"
                              class="invalid-feedback"
                            >
                              Confirm Password is required.
                            </div>

                            <div
                              v-if="submitted && !$v.confirm_password.sameAs"
                              class="invalid-feedback"
                            >
                              Password and confirm password does not match.
                            </div>
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group
                          id="input-group-1"
                          class="mb-3"
                          label="Contact Number"
                          label-for="input-1"
                        >
                          <b-form-input
                            id="input-1"
                            v-mask="'(###)-###-####'"
                            v-model="contact_number"
                            type="text"
                            placeholder="Contact Number"
                            :class="{
                              'is-invalid':
                                submitted && $v.contact_number.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="submitted && $v.contact_number.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.contact_number.required"
                              >Contact Number is required.</span
                            >

                            <span
                              v-else-if="
                                !$v.contact_number.maxLength ||
                                !$v.contact_number.minLength
                              "
                              >Contact Number should be 10 digit long.</span
                            >
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group
                          id="input-group-1"
                          class="mb-3"
                          label="Company Name (optional)"
                          label-for="input-1"
                        >
                          <b-form-input
                            id="input-1"
                            v-model="company_name"
                            type="text"
                            placeholder="Company Name"
                            :class="{
                              'is-invalid': submitted && $v.company_name.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="submitted && $v.company_name.$error"
                            class="invalid-feedback"
                          >
                            <!-- <span v-if="!$v.company_name.required"
                              >Company name is required.</span
                            > -->
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="mt-3 text-end">
                      <b-button
                        type="submit"
                        class="w-md"
                        size="lg"
                        variant="purple"
                        >Register</b-button
                      >
                    </div>

                    <div class="float-end mt-3">
                      <router-link to="/login" class="text-muted"
                        >Already have an account?
                      </router-link>
                    </div>
                    <div class="mt-4 text-center"></div>
                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>

            <div class="card" v-else>
              <div class="card-body p-4 align-center">
                <div class="text-center mt-2">
                  <h3>Register</h3>
                </div>
                <b-alert
                  :show="!!showSuccessMessage"
                  variant="success"
                  class="mt-5"
                  >Registered successfully.</b-alert
                >
              </div>
              <div class="float-end m-3">
                <router-link to="/login" class="text-muted"
                  >Back to login
                </router-link>
              </div>
            </div>
            <div class="mt-5 text-center"></div>
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>