export const state = {
  chartLoader: false,
};

export const getters = {
  isChartLoading(state) {
    return !!state.chartLoader;
  },
};
export const actions = {
  // eslint-disable-next-line no-unused-vars
  showChartLoader({ dispatch, commit }) {
    commit("chartLoader", true);
  },

  // eslint-disable-next-line no-unused-vars
  hideChartLoader({ dispatch, commit }) {
    commit("chartLoader", false);
  },
};

export const mutations = {
  chartLoader(state, data) {
    state.chartLoader = data;
  },
};
