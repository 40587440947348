export const state = {
    type: null,
    message: null,
    notify: false,
};

export const mutations = {
    validationMessage(state, data) {
        state.type = data.type;
        state.notify = data.notify;
        state.message = data.message;
    },
    error(state, message) {
        state.type = 'danger';
        state.message = message;
    },
    clear(state) {
        state.type = null;
        state.message = null;
        state.notify = false;
    },
    notify(state) {
        state.notify = true;
    }
};

export const actions = {
    validationMessage({ commit }, data) {
        commit('validationMessage',  data);
    },
    error({ commit }, message) {
        commit('error', message);
    },
    clear({ commit }) {
        commit('clear');
    },
    notify({commit}) {
        commit("notify");
    }
};
