<script>
export default {
  components: {
  },
  data() {
    return {
      worker: {
        id: null,
        name: null,
        email: null,
        contact_number: null,
        canDelete: false
      }
    }
  },
  methods: {
    removeWorker() {
      this.$emit("remove", { worker_id: this.id });
    }
  },
  props: {
    id: {default: 0, type: Number },
    name: {required: true, default: "Anonymous", type: String },
    email: {required: true, default: "anonymous", type: String },
    contact_number: {required: false, default: "", type: String },
    canDelete: {required: false, default: false, type: Boolean }
  },
};
</script>

<template>
  <div class="cardd bg-soft-main-theme-color text-dark ps-3 p-2 m-2" style="border-radius: 10px">
    <div class="cardd-body">
      <div v-if="canDelete" class="float-end mt-0">
        <b-button @click="removeWorker" variant="transparent"><i class="fa fa-times"></i></b-button>
      </div>
      <div>
        <div class="mt-0 font-size-15 text-dark">
          <b>{{ email }}</b>
        </div>
        <div>
          <p class=" mb-0">{{name}} <span v-if="contact_number"> - {{ contact_number }}</span></p>
        </div>
      </div>
    </div>
  </div>
</template>