<style scoped>
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}
</style>
<script>
import { required, maxLength } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { taxListingFields } from "./listingFields";
import listingPermissions from "./listing-permission.json";

//import axios from "axios";

import "vue-multiselect/dist/vue-multiselect.min.css";
import axios from "axios";

export default {
  page: {
    title: "Event Taxes",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  validations: {
    name: {
      required,
    },
    fees: {
      required,
      numeric: function () {
        return !isNaN(this.fees) && this.fees >= 0;
      },
      range: function() {
        if (!this.taxType) {
          return true;
        } else if (this.taxType.type == "percentage") {
          return !isNaN(this.fees) && this.fees >= 0 && this.fees <= 100;
        } else {
          return !isNaN(this.fees) && this.fees >= 0;
        }
      },
      maxLength: maxLength(5),
    },
    taxType: {
      required,
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.taxes.length;
    },
  },
  data() {
    return {
      taxes: [],
      isBusy: true,
      options: [
        { type: "fixed", category: "order"},
        { type: "fixed", category: "product"},
        //{ type: "percentage", category: "order"},
        { type: "percentage", category: "product"},
      ],
      showForm: false,
      canCreate: false,
      currentPage: 1,
      id: null,
      name: "",
      fees: null,
      taxType: null,
      perPage: 10,
      taxFormButtonName: "Create",
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      submitted: false,
      fields: taxListingFields,
    };
  },

  mounted() {
    // Set the initial number of items
    this.setListingFields();
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.isBusy = true;
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        let response = await axios.get("taxes");
        if (response) {
          this.isBusy = false;
          this.taxes = [];
          this.taxes = response.data.data;
          this.canCreate = response.data.canCreate;
        } else {
          this.isBusy = false;
          this.canCreate = false;
          this.taxes = [];
          console.log("error");
        }
      } catch (error) {
        console.log("ERROR......", error);
        this.canCreate = false;
        this.taxes = [];
      }
    },

    setListingFields() {
      this.fields = JSON.parse(JSON.stringify(taxListingFields));
      let user = JSON.parse(localStorage.getItem("user"));
      let listingFieldPermission = listingPermissions.taxPermissions[user.role];
      this.fields = this.fields.filter((item) => {
        let flag = false;
        if (item.id) {
          if (listingFieldPermission.includes(item.id)) {
            flag = true;
          }
        }
        return flag;
      });
    },

    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
    },

    taxForm(data = null) {
      window.scrollTo({top: 0, behavior: 'smooth'});
      this.taxFormButtonName = "Create";
      this.name = "";
      this.id = null;
      this.fees = null;
      this.taxType = null;
      if (data) {
        this.id = data.id;
        this.name = data.name;
        this.fees = data.fees;
        this.taxType = { type: data.type, category: data.category };
      }

      if (this.id) {
        this.taxFormButtonName = "Update";
      }
      this.showForm = !this.showForm;
    },

    confirmDelete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          this.deleteTax(id);
        }
      });
    },

    async deleteTax(id) {
      try {
        let response = await axios.delete("/tax/delete/" + id);
        if (response) {
          this.setCustomValidationMessage(
            response.data.message,
            "success",
            true
          );
          this.getData();
        } else {
          this.setCustomValidationMessage(
            localStorage.getItem("error_message"),
            "danger",
            true
          );
        }
      } catch (error) {
        console.log(error);
      }
    },

    async submitForm() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if (this.id) {
          let tax_obj = {
            name: this.name,
            fees: this.fees,
            type: this.taxType.type,
            category: this.taxType.category,
            id: this.id,
          };
          let token = localStorage.getItem("token");
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          let response = await axios.post("tax/update", tax_obj);
          if (response) {
            this.setCustomValidationMessage(
              response.data.message,
              "success",
              true
            );
            this.getData();
          } else {
            this.setCustomValidationMessage(
              localStorage.getItem("error_message"),
              "danger",
              true
            );
            return;
          }
        } else {
          let tax_obj = {
            name: this.name,
            fees: this.fees,
            type: this.taxType.type,
            category: this.taxType.category,
          };
          let token = localStorage.getItem("token");
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          let response = await axios.post("tax/create", tax_obj);
          if (response) {
            this.setCustomValidationMessage(
              response.data.message,
              "success",
              true
            );
            this.getData();
          } else {
            this.setCustomValidationMessage(
              localStorage.getItem("error_message"),
              "danger",
              true
            );
            return;
          }
        }
        this.showForm = false;
        this.name = "";
        this.fees = null;
        this.taxType = null;
        this.$v.$reset();
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="[]" />

    <!-- <transition name="slide-fade"> -->
    <div class="row mb-2">
      <div class="col-md-4">
        <h3>Event Taxes</h3>
      </div>
      <div class="col-md-8">
        <div class="float-end">
          <div>
            <!-- <button type="button" @click="downloadCSVData" class="btn btn-info mb-3">
            <i class="mdi mdi-file me-1"></i> Export
          </button> -->
            <button
              v-if="canCreate"
              type="button"
              @click.prevent="taxForm(null)"
              class="btn btn-warning ms-1 mb-3"
            >
              <i class="mdi mdi-plus me-1"></i> Add Tax
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- </transition> -->
    <transition name="slide-fade">
      <div v-if="showForm" class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <b-form @submit.prevent="submitForm">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="col-md-12">
                        <b-form-group
                          id="input-group-1"
                          class="mb-3"
                          label="Name"
                          label-for="input-1"
                        >
                          <b-form-input
                            id="input-1"
                            v-model="name"
                            type="text"
                            placeholder="Name"
                            :class="{
                              'is-invalid': submitted && $v.name.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="submitted && $v.name.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.name.required"
                              >Name is required.</span
                            >
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="col-md-12">
                        <b-form-group
                          id="input-group-1"
                          class="mb-3"
                          label="Fees"
                          label-for="input-1"
                        >
                          <b-form-input
                            id="input-1"
                            v-model="fees"
                            type="text"
                            placeholder="Fees"
                            :class="{
                              'is-invalid': submitted && $v.fees.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="submitted && $v.fees.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.fees.required"
                              >Fees is required.</span
                            >
                            <span v-else-if="!$v.fees.numeric"
                              >This value should be digits</span
                            >
                            <span v-else-if="!$v.fees.range"
                              >Percentage should be in between 0 to 100</span
                            >
                            <span v-else-if="!$v.fees.maxLength"
                              >Maximum length is 5 digits.</span
                            >
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="col-md-12">
                        <b-form-group
                          id="input-group-1"
                          class="mb-3"
                          label="Type"
                          label-for="typeselect"
                        >
                          <multiselect
                            placeholder="Type"
                            id="typeselect"
                            v-model="taxType"
                            :options="options"
                            :class="{
                              'is-invalid': submitted && $v.taxType.$error,
                            }"
                          >
                            <template slot="singleLabel" slot-scope="props">
                              {{ props.option.type | taxInfo(props.option.category) | capitalFilter }}
                            </template>
                            <template slot="option" slot-scope="props">
                              {{ props.option.type | taxInfo(props.option.category) | capitalFilter }}
                            </template>
                          </multiselect>
                          <div
                            v-if="submitted && $v.taxType.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.taxType.required"
                              >Type is required.</span
                            >
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                  </div>

                  <div class="mt-3 text-start">
                    <b-button
                      type="submit"
                      class="me-2"
                      size="md"
                      variant="warning"
                      >{{ taxFormButtonName }}</b-button
                    >

                    <b-button
                      type="reset"
                      class=""
                      @click.prevent="() => (showForm = false)"
                      size="md"
                      variant="secondary"
                      >Cancel</b-button
                    >
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div class="row">
      <div class="col-12">
        <div class="cards">
          <div class="cards-body">
            <div class="row">
              <div class="row">
                <!-- Search -->
                <div class="col-md-10">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-start"
                  >
                    <div class="input-group">
                      <div class="input-group-text">
                        <i class="uil-search"></i>
                      </div>
                      <b-form-input
                        v-model="filter"
                        placeholder="Search Tax"
                        type="search"
                        class="form-control form-control-md px-1"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
                <!-- End search -->

                <div class="col-md-2">
                  <div
                    id="tickets-table_length"
                    class="dataTables_length text-md-start"
                  >
                    <label
                      class="d-inline-flex align-items-center fw-normal mt-1"
                    >
                      Show&nbsp;&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="lg"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;&nbsp;entries
                    </label>
                  </div>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                show-empty
                empty-text="No data found"
                :busy="isBusy"
                table-class="table table-centered datatable table-card-list"
                thead-tr-class="bg-transparent"
                :items="taxes"
                :fields="fields"
                responsive="sm"
                sort-icon-left
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #table-busy>
                  <div class="text-center text-theme m-2">
                    <b-spinner
                      class="align-middle"
                    ></b-spinner>
                    <strong> Loading...</strong>
                  </div>
                </template>
                <template #cell(type)="data">
                  {{ data.item.type | taxInfo(data.item.category) | capitalFilter }}
                </template>
                <template #cell(fees)="data">
                  <div v-if="data.item.type == 'percentage'">
                    {{ data.item.fees | percentageFilter }}
                  </div>
                  <div v-else>
                    {{ data.item.fees | priceFilter }}
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div>
                    <!-- edit v-if="data.item.permission.canEdit" -->
                    <b-button v-if="data.item.permission.canEdit"
                      class="me-2"
                      @click="taxForm(data.item)"
                      size="sm"
                      variant="success"
                      ><i class="fas fa-pen"></i
                    ></b-button>
                    <!-- delete -->
                    <b-button v-if="data.item.permission.canDelete"
                      size="sm"
                      @click.prevent="confirmDelete(data.item.id)"
                      variant="danger"
                      ><i class="fas fa-trash"></i
                    ></b-button>
                  </div>
                </template>
              </b-table>
            </div>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      style="margin: auto"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>