<script>
import appConfig from "@/app.config";
import { required, email } from "vuelidate/lib/validators";
import store from '@/state/store';

/**
 * Forgot Password component
 */
export default {
  page: {
    title: "Forgot Password",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  mounted() {
    document.body.classList.add("authentication-bg");
  },
  data() {
    return {
      email: "",
      otp: "",
      submitted: false,
      error: null,
      title: "Recoverpwd",
      isAuthError: false,
      authError: "",
      successMessage: null,
    };
  },
  validations: {
    email: {
      required,
      email,
    },

  },
  methods: {

    async sendVerificationLink() {
      this.submitted = true;
      this.$v.$touch();
      this.isAuthError = false;
      this.successMessage = null;
      this.authError = null
      if (this.$v.$invalid) {
        return;
      } else {
        let email = this.email;
        await this.$store.dispatch("authentication/sendVerificationLink", {
            email,
          });
        if (store.getters['authentication/isValidationError']) {
          this.isAuthError = true;
          this.authError = store.getters['authentication/validationMessage'];
        } else {
          if (store.getters['authentication/successMessage']) {
            this.successMessage = store.getters['authentication/successMessage'];
          } else {
            this.$router.push(
              this.$route.query.redirectFrom || {
                path: "/login",
              }
            );
          }
        }

      }
    }
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div>
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/brands/moontowerlogo.png"
                  alt
                  height="70"
                  class="logo logo-dark"
                />
                
              </router-link>
              <div class="card">
                <div class="card-body p-4">
                  <div class="text-center mt-2">
                    <h3>Forgot Password</h3>
                  </div>
                    <b-alert
                    :show="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert>
                    <b-alert
                    :show="!!successMessage"
                    variant="success"
                    class="mt-5"
                    >{{ successMessage }}</b-alert>
                  <div class="p-2 mt-4">
                    
                    <form v-if="!successMessage" @submit.prevent="sendVerificationLink">
                      <div class="mb-3">
                        <div>
                            <label for="useremail">Email</label>
                            <input
                              type="email"
                              v-model="email"
                              class="form-control"
                              id="useremail"
                              placeholder="Enter email"
                              :class="{
                                'is-invalid': submitted && $v.email.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.email.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.email.required"
                                >Email is required.</span
                              >
                              <span v-if="!$v.email.email"
                                >Please enter valid email.</span
                              >
                            </div>
                        </div>                 
                      </div>
                      <div class="row mb-0">
                        <div class="col-12 text-end">
                          <b-button type="submit" class="w-md" size="lg" variant="purple"
                            >Reset</b-button
                          >
                        </div>
                      </div>
                      <div class="mt-3 text-end">
                        <p class="mb-0">
                          <router-link
                            to="/login"
                            class="text-muted"
                            >Back to login</router-link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->

              
            </div>
            <!-- end col -->
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>
