<script>
import countTo from "vue-count-to";
export default {
   components: {
        countTo
    },
    data() {
        return {
            series: [{
                data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
            }],
            chartOptions: {
                fill: {
                    colors: this.color
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    enabled: false
                }
            },
        }
    },
    props: {
        startVal: {default: 0, type: Number },
        endVal: {required: true, default: 0, type: Number },
        duration: { default: 2000, type: Number },
        title: {default: "Total Revenue", type: String },
        color: {default: "#5b73e8", type: String },
        symbol: {default: "", type: String },
        cardText: {default:"", type: String},
    },
};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <div class="float-end mt-2">
                <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart>
            </div>
            <div>
                <h4 class="mb-1 mt-1">
                    {{symbol}}
                    <span data-plugin="counterup">
                        <countTo :startVal="startVal" :decimals="!symbol ? 0 : 2" :endVal="endVal" :duration="duration"></countTo>
                    </span>
                </h4>
                <p class="text-muted mb-0">{{title}}</p>
            </div>
            <p class="text-muted mt-3 mb-0">
                {{cardText}}
            </p>
        </div>
    </div>
</template>