var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"account-pages my-5 pt-sm-5"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row align-items-center justify-content-center"},[_c('div',{staticClass:"col-md-8 col-lg-6 col-xl-5"},[_c('router-link',{staticClass:"mb-5 d-block auth-logo",attrs:{"to":"/"}},[_c('img',{staticClass:"logo logo-dark",attrs:{"src":require("@/assets/images/brands/moontowerlogo.png"),"alt":"","height":"70"}})]),(!_vm.showSuccessMessage)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-4"},[_vm._m(1),_c('div',{staticClass:"p-2 mt-4"},[_c('b-alert',{staticClass:"mt-3",attrs:{"show":_vm.isAuthError,"variant":"danger","dismissible":""}},[_vm._v(_vm._s(_vm.authError))]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"input-group-1","label":"First Name","label-for":"input-1"}},[_c('b-form-input',{class:{
                            'is-invalid': _vm.submitted && _vm.$v.first_name.$error,
                          },attrs:{"id":"input-1","type":"text","placeholder":"First Name"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),(_vm.submitted && _vm.$v.first_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.first_name.required)?_c('span',[_vm._v("First name is required.")]):(!_vm.$v.first_name.maxLength)?_c('span',[_vm._v(" Maximum length is 15 characters long. ")]):(!_vm.$v.first_name.textOnly)?_c('span',[_vm._v(" Give valid first name. ")]):_vm._e()]):_vm._e()],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"input-group-1","label":"Last Name","label-for":"input-1"}},[_c('b-form-input',{class:{
                            'is-invalid': _vm.submitted && _vm.$v.last_name.$error,
                          },attrs:{"id":"input-1","type":"text","placeholder":"Last Name"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),(_vm.submitted && _vm.$v.last_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.last_name.required)?_c('span',[_vm._v("Last name is required.")]):(!_vm.$v.last_name.maxLength)?_c('span',[_vm._v(" Maximum length is 15 characters long. ")]):(!_vm.$v.last_name.textOnly)?_c('span',[_vm._v(" Give valid last name. ")]):_vm._e()]):_vm._e()],1)],1)]),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"input-group-1","label":"Email","label-for":"input-1"}},[_c('b-form-input',{class:{ 'is-invalid': _vm.submitted && _vm.$v.email.$error },attrs:{"id":"input-1","type":"text","placeholder":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(_vm.submitted && _vm.$v.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.email.required)?_c('span',[_vm._v("Email is required.")]):_vm._e(),(!_vm.$v.email.email)?_c('span',[_vm._v("Please enter valid email.")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"input-group-2"}},[_c('label',{attrs:{"for":"input-2"}},[_vm._v("Password")]),_c('div',{staticClass:"input-group"},[_c('b-form-input',{class:{
                              'is-invalid': _vm.submitted && _vm.$v.password.$error,
                            },attrs:{"id":"input-2","type":_vm.passwordInputType,"placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"input-group-text"},[_c('a',{staticStyle:{"color":"#000"},attrs:{"href":"javascript:void(0)"},on:{"click":_vm.showPassword}},[_c('i',{class:_vm.passwordIcon})])]),(_vm.submitted && !_vm.$v.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):(_vm.submitted && !_vm.$v.password.spaceCheck)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password should not contain any space. ")]):(_vm.submitted && !_vm.$v.password.regex)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password must be at least 8 characters long and contain at least one lowercase, one uppercase character and one special character. ")]):_vm._e()],1)])],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"input-group-2"}},[_c('label',{attrs:{"for":"input-2"}},[_vm._v("Confirm Password")]),_c('div',{staticClass:"input-group"},[_c('b-form-input',{class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.confirm_password.$error,
                            },attrs:{"id":"input-2","type":_vm.confirmPasswordInputType,"placeholder":"Confirm Password"},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}}),_c('div',{staticClass:"input-group-text"},[_c('a',{staticStyle:{"color":"#000"},attrs:{"href":"javascript:void(0)"},on:{"click":_vm.showConfirmPassword}},[_c('i',{class:_vm.confirmPasswordIcon})])]),(_vm.submitted && !_vm.$v.confirm_password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Confirm Password is required. ")]):_vm._e(),(_vm.submitted && !_vm.$v.confirm_password.sameAs)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password and confirm password does not match. ")]):_vm._e()],1)])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"input-group-1","label":"Contact Number","label-for":"input-1"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(###)-###-####'),expression:"'(###)-###-####'"}],class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.contact_number.$error,
                          },attrs:{"id":"input-1","type":"text","placeholder":"Contact Number"},model:{value:(_vm.contact_number),callback:function ($$v) {_vm.contact_number=$$v},expression:"contact_number"}}),(_vm.submitted && _vm.$v.contact_number.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.contact_number.required)?_c('span',[_vm._v("Contact Number is required.")]):(
                              !_vm.$v.contact_number.maxLength ||
                              !_vm.$v.contact_number.minLength
                            )?_c('span',[_vm._v("Contact Number should be 10 digit long.")]):_vm._e()]):_vm._e()],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"input-group-1","label":"Company Name (optional)","label-for":"input-1"}},[_c('b-form-input',{class:{
                            'is-invalid': _vm.submitted && _vm.$v.company_name.$error,
                          },attrs:{"id":"input-1","type":"text","placeholder":"Company Name"},model:{value:(_vm.company_name),callback:function ($$v) {_vm.company_name=$$v},expression:"company_name"}}),(_vm.submitted && _vm.$v.company_name.$error)?_c('div',{staticClass:"invalid-feedback"}):_vm._e()],1)],1)]),_c('div',{staticClass:"mt-3 text-end"},[_c('b-button',{staticClass:"w-md",attrs:{"type":"submit","size":"lg","variant":"purple"}},[_vm._v("Register")])],1),_c('div',{staticClass:"float-end mt-3"},[_c('router-link',{staticClass:"text-muted",attrs:{"to":"/login"}},[_vm._v("Already have an account? ")])],1),_c('div',{staticClass:"mt-4 text-center"})],1)],1)])]):_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-4 align-center"},[_vm._m(2),_c('b-alert',{staticClass:"mt-5",attrs:{"show":!!_vm.showSuccessMessage,"variant":"success"}},[_vm._v("Registered successfully.")])],1),_c('div',{staticClass:"float-end m-3"},[_c('router-link',{staticClass:"text-muted",attrs:{"to":"/login"}},[_vm._v("Back to login ")])],1)]),_c('div',{staticClass:"mt-5 text-center"})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center mt-2"},[_c('h3',[_vm._v("Promoter Registration")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center mt-2"},[_c('h3',[_vm._v("Register")])])}]

export { render, staticRenderFns }