<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import {
  required,
  email,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import axios from "axios";
import moment from 'moment';
import { saveAs } from "file-saver";
import EmbedWidgetModal from "@/components/widgets/embedWidgetModal.vue";
const Excel = require("exceljs");

export default {
  page: {
    title: "Promoters",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmbedWidgetModal,
  },


  validations: {
    email: {
      required,
      email,
    },
    // password: {
    //   required: function () {
    //     if (this.isEditing) {
    //       return true;
    //     } else {
    //       return !!this.password;
    //     }
    //   },
    //   regex: function () {
    //     let validatePassword = true;
    //     if (this.isEditing) {
    //       validatePassword = true;
    //     } else {
    //       if (this.password) {
    //         let regexPassword = new RegExp(
    //           /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/
    //         );
    //         let regexValidation = !!regexPassword.exec(this.password);
    //         let lengthOfPassword = this.password.length;
    //         if (regexValidation && lengthOfPassword >= 8) {
    //           validatePassword = true;
    //         } else {
    //           validatePassword = false;
    //         }
    //       }
    //     }
    //     return validatePassword;
    //   },
    //   spaceCheck: function () {
    //     if (this.id){
    //       return true;
    //       }
    //       return !this.password.includes(' ');
    //   }
    // },
    // confirm_password: {
    //   required: function () {
    //     if (this.isEditing) {
    //       return true;
    //     } else {
    //       return !!this.confirm_password;
    //     }
    //   },
    //   sameAs: function () {
    //     let validatePassword = true;
    //     if (this.isEditing) {
    //       validatePassword = true;
    //     } else {
    //       if (this.confirm_password && this.password) {
    //         if (this.password == this.confirm_password) {
    //           validatePassword = true;
    //         } else {
    //           validatePassword = false;
    //         }
    //       }
    //     }
    //     return validatePassword;
    //   },
    // },
    first_name: {
      required,
      maxLength: maxLength(15),
      textOnly: function () {
        let textRegex = new RegExp(/^[a-zA-Z]*$/);
        let textValidation = !!textRegex.exec(this.first_name);
        return textValidation;
      }
    },
    last_name: {
      required,
      maxLength: maxLength(15),
      textOnly: function () {
        let textRegex = new RegExp(/^[a-zA-Z]*$/);
        let textValidation = !!textRegex.exec(this.last_name);
        return textValidation;
      }
    },
    contact_number: {
      maxLength: maxLength(14),
      minLength: minLength(14),
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.operators.length;
    },
  },
  data() {
    return {
      operators: [],
      title: "",
      isBusy: false,
      email: null,
      password: "",
      confirm_password: null,
      first_name: null,
      last_name: null,
      contact_number: null,
      canCreate: false,
      submitted: false,
      isAuthError: false,
      authError: "",
      showForm: false,
      isEditing: false,
      id: null,
      adminFormButtonName: "Create",
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: false,
      user: null,
      email_notification: true,
      email_notification_for_tickets: true,
      fields: [
        {
          key: "first_name",
          label: "Name",
          sortable: true,
        },
        {
          key: "email",
          sortable: false,
        },
        {
          key: "contact_number",
          sortable: false,
        },
        {
          key: "company_name",
          sortable: true,
        },
        {
          key: "date",
          sortable: true,
        },
        {
          key: "status",
          sortable: false,
        },
        {
          key: "actions",
          sortable: false,
        },
        {
          key: "promoter_slug",
          icon: 'fa fa-link',
          label: "URL Slug",
        },
        {
          key: "widgets",
          sortable: false,
        },
      ],
      selectedItemId: null,
      modalTitle: 'Embed Widget Instructions',
    };
  },

  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    // Set the initial number of items
    this.getData();
  },
  methods: {
    copyToClipboard(data, link) {
      navigator.clipboard.writeText(link);
      data.item.clipboard_tooltip = '';

    },

    removeCopiedFromTooltip(data) {
      setTimeout(() => {
        data.item.clipboard_tooltip = null;
      }, 200);
    },
    confirmApproveDisapprove(approve, id) {
      let confirmButtonText = approve == 1 ? "Activate" : "Deactivate";
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: confirmButtonText,
      }).then((result) => {
        if (result.value) {
          this.approveDisapprove(approve, id);
        }
      });
    },

    async approveDisapprove(approve, id) {
      try {
        let successMessage = "Approved successfully.";
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        let response = await axios.post("operators/approve", {
          id,
          approve: approve,
        });
        if (response) {
          successMessage = response.data.message;
          Swal.fire("Success!", successMessage, "success");
        }
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },

    async getData() {
      try {
        this.isBusy = true;
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        let response = await axios.get("operators");
        if (response) {
          this.isBusy = false;
          this.operators = [];
          this.operators = response.data.data;
        } else{
          this.isBusy = false;
          this.operators = [];
          console.log("error");
        }
      } catch (error) {
        this.isBusy = false;
        this.operators = [];
      }
    },

    confirmDelete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          this.deleteOperator(id);
        }
      });
    },

    async deleteOperator(id) {
      try {
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        let response = await axios.delete("operators/delete/" + id);
        if (response) {
          this.setCustomValidationMessage(
              response.data.message,
              "success",
              true
          );
          this.getData();
        } else {
          this.setCustomValidationMessage(
              localStorage.getItem("error_message"),
              "danger",
              true
          );
        }
      } catch (error) {
        console.log(error);
      }
    },

    openEmbedWidgetModal(id) {
      this.selectedItemId = id;
    },

    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
    },
    promoterForm(data = null) {
      console.log(data);
      window.scrollTo({top: 0, behavior: 'smooth'});
      this.email = null;
      this.password = "";
      this.confirm_password = null;
      this.first_name = null;
      this.last_name = null;
      this.contact_number = null;
      this.email_notification = true;
      this.email_notification_for_tickets = true;
      this.isEditing = false;
      this.id = null;
      if (data) {
        this.id = data.id;
        this.email = data.email;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.password = null;
        this.confirm_password = null;
        this.contact_number = data.contact_number;
        this.email_notification = data.email_notification;
        this.email_notification_for_tickets = data.email_notification_for_tickets;
        this.isEditing = true;
      }

      if (this.id) {
        this.adminFormButtonName = "Update";
      } else {
        this.adminFormButtonName = "Create";
      }
      this.showForm = !this.showForm;
    },

    async submitForm() {
      try {
        this.isAuthError = false;
        this.authError = null;
        this.submitted = true;
        // stop here if form is invalid
        this.$v.$touch();

        if (this.$v.$invalid) {
          return;
        } else {
          console.log("Form is valid");
          let token = localStorage.getItem("token");
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          if (this.id) {
            let { id, first_name, last_name, email, contact_number, email_notification, email_notification_for_tickets } = this;
            if (contact_number)
              contact_number = contact_number.replace(/[^0-9]/g, "");

            let response = await axios.patch("admins/update", {
              id: id,
              first_name: first_name,
              last_name: last_name,
              email: email,
              contact_number: contact_number ? contact_number : null,
              email_notification: email_notification,
              email_notification_for_tickets: email_notification_for_tickets,
              role: "operator"
            });

            if (response) {
              this.setCustomValidationMessage(
                response.data.message,
                "success",
                true
              );
              this.getData();
            } else {
              this.setCustomValidationMessage(
                localStorage.getItem("error_message"),
                "danger",
                true
              );
              return;
            }
          } else {
            let { first_name, last_name, email, password, contact_number } =
              this;
            if (contact_number)
              contact_number = contact_number.replace(/[^0-9]/g, "");
            let response = await axios.post("admins/create", {
              first_name: first_name,
              last_name: last_name,
              email: email,
              password: password,
              contact_number: contact_number,
            });

            if (response) {
              this.setCustomValidationMessage(
                response.data.message,
                "success",
                true
              );
              this.getData();
            } else {
              this.setCustomValidationMessage(
                localStorage.getItem("error_message"),
                "danger",
                true
              );
              return;
            }
          }
          this.showForm = false;
          this.name = "";
          this.description = "";
          this.slug = "";
          this.$v.$reset();
        }
      } catch (error) {
        console.log("Error : ", error.message);
        this.showForm = false;
        this.name = "";
        this.description = "";
        this.slug = "";
        this.$v.$reset();
      }
    },

    async downloadCSVData() {
      //export all product data as an excel file
      var workbook = new Excel.Workbook();
      var worksheet = workbook.addWorksheet("Sheet1");
      worksheet.columns = [
        { header: "Name", key: "first_name", width: 20, style: { alignment: {horizontal: 'left'} } },
        { header: "Email", key: "email", width: 20 },
        { header: "Contact Number", key: "contact_number", width: 20 },
        { header: "Company Name", key: "company_name", width: 20 },
        { header: "Date", key: "date", width: 20 },
        { header: "Status", key: "status", width: 20 },
      ];
      let excelData = JSON.parse(JSON.stringify(this.operators));
      excelData = excelData.map((data) => {
        data.date = moment(data.date, "MM/DD/YYYY HH:mm:ss").format("MM/DD/YYYY");
        data.status = (data.status) ? (data.status.charAt(0).toUpperCase() + data.status.slice(1)) : null;
        return data;
      });

      worksheet.addRows(excelData);
      worksheet.getRow(1).font = { bold: true };

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      var filename = "Promoters_" + new Date().toDateString() + ".xlsx";
      saveAs(data, filename);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="[]" />
    <div class="row mb-4">
      <div class="col-md-4">
        <h3>Promoters</h3>
      </div>
      <div class="col-md-8">
        <div class="float-end">
          <button
              type="button"
              @click="downloadCSVData"
              class="btn btn-info mb-3"
            >
              <i class="mdi mdi-file me-1"></i> Export
            </button>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="showForm" class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <b-alert
                  :show="isAuthError"
                  variant="danger"
                  class="mt-3"
                  dismissible
                  >{{ authError }}</b-alert
                >

                <b-form @submit.prevent="submitForm">
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-1"
                        class="mb-3"
                        label="First Name"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          v-model="first_name"
                          type="text"
                          placeholder="First Name"
                          :class="{
                            'is-invalid': submitted && $v.first_name.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitted && $v.first_name.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.first_name.required"
                              >First name is required.</span
                          >
                          <span v-else-if="!$v.first_name.maxLength">
                            Maximum length is 15 characters long.
                          </span>
                          <span v-else-if="!$v.first_name.textOnly">
                            Give valid first name.
                          </span>
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="input-group-2"
                        class="mb-3"
                        label="Last Name"
                        label-for="input-2"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="last_name"
                          type="text"
                          placeholder="Last Name"
                          :class="{
                            'is-invalid': submitted && $v.last_name.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitted && $v.last_name.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.last_name.required"
                              >Last name is required.</span
                          >
                          <span v-else-if="!$v.last_name.maxLength">
                            Maximum length is 15 characters long.
                          </span>
                          <span v-else-if="!$v.last_name.textOnly">
                            Give valid last name.
                          </span>
                        </div>
                      </b-form-group>
                    </div>
                  </div>

                  <b-form-group
                    id="input-group-3"
                    class="mb-3"
                    label="Email"
                    label-for="input-3"
                  >
                      <b-form-input
                      id="input-3"
                      v-model="email"
                      :disabled="!(this.user.role === 'super-admin' && isEditing) && !isEditing"
                      type="text"
                      placeholder="Email"
                      :class="{ 'is-invalid': submitted && $v.email.$error }"
                    ></b-form-input>
                    <div
                      v-if="submitted && $v.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.email.required">Email is required.</span>
                      <span v-if="!$v.email.email"
                        >Please enter valid email.</span
                      >
                    </div>
                  </b-form-group>

                  <div class="row" v-if="!isEditing">
                    <div class="col-md-6">
                      <b-form-group id="input-group-4" class="mb-3">
                        <label for="input-4">Password</label>

                        <div class="input-group">
                          <b-form-input
                            id="input-4"
                            v-model="password"
                            :type="passwordInputType"
                            placeholder="Password"
                            :class="{
                              'is-invalid': submitted && $v.password.$error,
                            }"
                          ></b-form-input>
                          <div class="input-group-text">
                            <a
                              style="color: #000"
                              href="javascript:void(0)"
                              @click="showPassword"
                            >
                              <i :class="passwordIcon"></i>
                            </a>
                          </div>
                          <div
                            v-if="submitted && !$v.password.required"
                            class="invalid-feedback"
                          >
                            Password is required.
                          </div>
                          <div
                              v-else-if="submitted && !$v.password.spaceCheck"
                              class="invalid-feedback"
                            >
                              Password should not contain any space.
                            </div>
                          <div
                            v-else-if="submitted && !$v.password.regex"
                            class="invalid-feedback"
                          >
                            Password must be at least 8 characters long and
                            contain at least one lowercase, one uppercase
                            character, one numeric and one special character.
                          </div>
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group id="input-group-5" class="mb-3">
                        <label for="input-5">Confirm Password</label>

                        <div class="input-group">
                          <b-form-input
                            id="input-5"
                            v-model="confirm_password"
                            :type="confirmPasswordInputType"
                            placeholder="Confirm Password"
                            :class="{
                              'is-invalid':
                                submitted && $v.confirm_password.$error,
                            }"
                          ></b-form-input>
                          <div class="input-group-text">
                            <a
                              style="color: #000"
                              href="javascript:void(0)"
                              @click="showConfirmPassword"
                            >
                              <i :class="confirmPasswordIcon"></i>
                            </a>
                          </div>

                          <div
                            v-if="submitted && !$v.confirm_password.required"
                            class="invalid-feedback"
                          >
                            Confirm Password is required.
                          </div>

                          <div
                            v-if="submitted && !$v.confirm_password.sameAs"
                            class="invalid-feedback"
                          >
                            Password and confirm password does not match.
                          </div>
                        </div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group
                        id="input-group-6"
                        class="mb-3"
                        label="Contact Number"
                        label-for="input-6"
                      >
                        <b-form-input
                          id="input-6"
                          v-mask="'(###)-###-####'"
                          v-model="contact_number"
                          type="text"
                          placeholder="Contact Number"
                          :class="{
                            'is-invalid': submitted && $v.contact_number.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitted && $v.contact_number.$error"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.contact_number.maxlength ||
                              !$v.contact_number.minlength
                            "
                            >Contact Number should be 10 digit long.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group row mb-2">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="input-group">
                              <label class="col-form-label">Email Notification - Event is Created</label>
                              <div class="input-group-text bg-transparent border-0"></div>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <b-form-radio
                              v-model="email_notification"
                              class="mt-2"
                              :value="true"
                              plain
                              >Enabled</b-form-radio
                            >
                          </div>
                          <div class="col-md-2">
                            <b-form-radio
                              v-model="email_notification"
                              class="mt-2"
                              :value="false"
                              plain
                              >Disabled</b-form-radio
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group row mb-2">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="input-group">
                              <label class="col-form-label">Email Notification - Tickets are Bought</label>
                              <div class="input-group-text bg-transparent border-0"></div>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <b-form-radio
                              v-model="email_notification_for_tickets"
                              class="mt-2"
                              :value="true"
                              plain
                              >Enabled</b-form-radio
                            >
                          </div>
                          <div class="col-md-2">
                            <b-form-radio
                              v-model="email_notification_for_tickets"
                              class="mt-2"
                              :value="false"
                              plain
                              >Disabled</b-form-radio
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-3 text-start">
                    <b-button
                      type="submit"
                      class="me-2"
                      size="md"
                      variant="warning"
                      >{{ adminFormButtonName }}</b-button
                    >

                    <b-button
                      type="reset"
                      class=""
                      @click.prevent="() => (showForm = false)"
                      size="md"
                      variant="secondary"
                      >Cancel</b-button
                    >
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="row">
      <div class="col-12">
        <div class="">
          <div class="">
            <div class="row">
              <div class="row">
                <!-- Search -->
                <div class="col-md-10">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-start"
                  >
                    <div class="input-group">
                      <div class="input-group-text">
                        <i class="uil-search"></i>
                      </div>
                      <b-form-input
                        v-model="filter"
                        placeholder="Search Promoters"
                        type="search"
                        class="form-control form-control-md px-1"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
                <!-- End search -->

                <div class="col-md-2">
                  <div
                    id="tickets-table_length"
                    class="dataTables_length text-md-start"
                  >
                    <label
                      class="d-inline-flex align-items-center fw-normal mt-1"
                    >
                      Show&nbsp;&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="lg"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;&nbsp;entries
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                show-empty
                empty-text="No data found"
                :busy="isBusy"
                table-class="table table-centered datatable table-card-list"
                thead-tr-class="bg-transparent"
                :items="operators"
                :fields="fields"
                responsive="sm"
                sort-icon-left
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #table-busy>
                  <div class="text-center text-theme m-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Loading...</strong>
                  </div>
                </template>
                <template #cell(first_name)="data">
                  <span
                    >{{ data.item.first_name }} {{ data.item.last_name }}</span
                  >
                </template>
                <template #cell(contact_number)="data">
                  <span v-if="data.item.contact_number">{{
                    data.item.contact_number | VMask('(###)-###-####')
                  }}</span>
                  <span v-else>
                    -
                  </span>
                </template>
                <template #cell(company_name)="data">
                  <span>{{
                    data.item.company_name ? data.item.company_name : "-"
                  }}</span>
                </template>

                <template #cell(date)="data">
                  <span>{{ data.item.date | formatDate }}</span>
                </template>

                <template #cell(status)="data">
                  <span
                    class="text-dark badge fw-fold font-size-12 p-2"
                    :class="{
                      'bg-soft-success': data.item.status === 'Active',
                      'bg-soft-danger': data.item.status === 'Inactive',
                      'bg-soft-warning': data.item.status === 'Deactivated',
                    }"
                    >{{ data.item.status }}</span
                  >
                </template>

                <template #cell(actions)="data">
                  <div
                    v-if="
                      data.item.permission.canApprove &&
                      data.item.status == 'Inactive'
                    "
                  >
                    <!-- admin approves -->
                    <b-button
                      class="me-2 text-succdess"
                      @click.prevent="confirmApproveDisapprove(1, data.item.id)"
                      size="sm"
                      variant="success"
                      ><i class="fas fa-check"></i
                    ></b-button>
                    <!-- admin disapprove -->
                    <b-button
                      size="sm text-dangedr"
                      @click.prevent="confirmApproveDisapprove(2, data.item.id)"
                      variant="danger"
                      ><i class="fas fa-times"></i
                    ></b-button>
                  </div>
                  <!-- deactivate operator-->
                  <div v-else-if="data.item.permission.canApprove && data.item.status == 'Active'">
                    <b-button
                      
                      class="me-2 text-succdess"
                      @click.prevent="promoterForm(data.item)"
                      size="sm"
                      variant="success"
                      ><i class="fas fa-pen"></i
                    ></b-button>
                    <b-button
                      size="sm text-dangedr"
                      @click.prevent="confirmApproveDisapprove(2, data.item.id)"
                      variant="danger"
                      ><i class="fas fa-times"></i
                    ></b-button>
                  </div>
                  <!-- activate operator-->
                  <div v-else-if="data.item.permission.canApprove && data.item.status == 'Deactivated'">
                    <b-button
                      size="sm text-succdess"
                      @click.prevent="confirmApproveDisapprove(1, data.item.id)"
                      variant="success"
                      ><i class="fas fa-check"></i
                    ></b-button>
                  </div>
                </template>
                    <template #cell(widgets)="data">
                      <b-button v-if="data.item.status === 'Active'" size="sm" variant="info" @click="openEmbedWidgetModal(String(data.item.id))">Embed Widget</b-button>
                    </template>
                    <template v-slot:cell(promoter_slug)="data">
                      <i v-if="data.item.status === 'Active'" :class="{
                        'fa fa-clipboard': !!data.item.clipboard_tooltip,
                        'far fa-clipboard': !data.item.clipboard_tooltip
                      }" :id="'tt_' + data.item.id" @mouseleave="removeCopiedFromTooltip(data)"
                        @click="copyToClipboard(data, data.item.clipboard_tooltip)"></i>
                      <b-tooltip :title="data.item.clipboard_tooltip ? data.item.clipboard_tooltip : 'Click to copy'"
                        :target="'tt_' + data.item.id" placement="lefttop">
                      </b-tooltip>
                    </template>
                  </b-table>
              <EmbedWidgetModal v-if="selectedItemId" :selectedItemId="selectedItemId" :title="modalTitle" @close="selectedItemId = null" />
            </div>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      style="margin: auto"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>