<script>
export default {
  components: {
    
  },
  data() {
    return {
      current_language: this.$i18n.locale,
      text: null,
      flag: null,
      name: "",
    };
  },
  beforeMount() {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    this.name = "Moon Tower Tickets";
    this.name = user.name;
  },
  mounted() {},
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.$parent.toggleMenu();
    },

    /**
     * Toggle rightsidebar
     */
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    /**
     * Set languages
     */
    setLanguage(locale, country, flag) {
      this.$i18n.locale = locale;
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
    },
    async logoutUser() {
      await this.$store.dispatch("authentication/logout");
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <div class="logo logo-dark">
            <span class="logo-sm">
              <router-link to="/">
                <img src="@/assets/images/brands/logo-mini.png" alt height="22" />
              </router-link>
            </span>
            <span class="logo-lg">
              <router-link to="/">
                <img src="@/assets/images/logo-dark.png" alt height="20" />
              </router-link>
            </span>
          </div>

          <div class="logo logo-light">
            <span class="logo-sm">
              <router-link to="/">
                <img src="@/assets/images/brands/logo-mini.png" alt height="22" />
              </router-link>
            </span>
            <span class="logo-lg">
              <router-link to="/">
                <img src="@/assets/images/logo-light.png" alt height="20" />
              </router-link>
            </span>
          </div>
        </div>

        <button
          @click="toggleMenu"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
          id="vertical-menu-btn"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
      </div>

      <div class="d-flex">
        <b-dropdown
          class="d-inline-block"
          toggle-class="header-item"
          right
          variant="white"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar-9.jpg"
              alt="Header Avatar"
            />
            <span
              class="d-none d-xl-inline-block ms-1 fw-medium font-size-15"
              >{{ name }}</span
            >
            <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
          </template>

          <!-- item-->
          <router-link class="dropdown-item" to="/settings">
            <i
              class="
                uil uil-user-circle
                font-size-18
                align-middle
                text-muted
                me-1
              "
            ></i>
            <span class="align-middle">{{
              $t("navbar.dropdown.mSidebar.list.profile")
            }}</span>
          </router-link>
          <a
            class="dropdown-item"
            href="javascript::void(0)"
            @click.prevent="logoutUser"
          >
            <i
              class="
                uil uil-sign-out-alt
                font-size-18
                align-middle
                me-1
                text-muted
              "
            ></i>
            <span class="align-middle">{{
              $t("navbar.dropdown.mSidebar.list.logout")
            }}</span>
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>