import store from '@/state/store'

export default [{
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['authentication/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/pages/account/register'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['authentication/login']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../views/pages/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['authentication/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/reset-password/:otp',
    name: 'reset-password',
    component: () => import('../views/pages/account/reset-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['authentication/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/user-activation/:code',
    name: 'user-activation',
    component: () => import('../views/pages/users/activation'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['authentication/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('authentication/logout')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? {
          name: 'home'
        } : {
          ...routeFrom
        })
      },
    },
  }, {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/dashboard/index')
  },
  {
    path: '/products',
    name: 'Product List',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/products/index')
  },
  {
    path: '/products/create-product',
    name: 'Create Product',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/products/create-product')
  },
  {
    path: '/products/categories',
    name: 'Product Category',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/products/category')
  },
  {
    path: '/products/taxes',
    name: 'Product Tax',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/products/tax')
  },
  {
    path: '/users/operators',
    name: 'operators',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/users/operator')
  },
  {
    path: '/users/admins',
    name: 'admins',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/users/admin')
  },
  {
    path: '/users/customers',
    name: 'customers',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/users/customer')
  },
  {
    path: '/users/workers',
    name: 'workers',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/users/worker')
  },
  {
    path: '/products/:id/edit',
    name: 'Edit Product',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/products/edit-product')
  },
  {
    path: '/products/:id',
    name: 'View Product',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/products/edit-product')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/profile/settings')
  },
  {
    path: '/charts',
    name: 'Chart',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/chart/index')
  },
  {
    path: '/charts/:key',
    name: 'Chart Designer',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/chart/designer')
  },
  {
    path: '/taxes',
    name: 'Taxes',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/tax/index')
  },
  {
    path: '/orders/:id/:specialCategoryId',
    name: 'Order Details',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/orders/details')
  },
  {
    path: '/reports/before',
    name: 'Prior to Load Optimization',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/reports/before')
  },
  {
    path: '/reports/after',
    name: 'Post Load Optimization',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/reports/after')
  }

]