<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import EmbedWidgetInstructions from "@/components/widgets/embedWidgetInstructions";
import appConfig from "@/app.config";
import { required, sameAs, url } from "vuelidate/lib/validators";
import axios from "axios";
/**
 * Elements component
 */
export default {
  page: {
    title: "Setting",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, EmbedWidgetInstructions },
  data() {
    return {
      title: "Settings",
      pass_flag: false,
      passwordIcon: {
        password: "fa fa-eye-slash",
        confirmPassword: "fa fa-eye-slash",
      },
      passwordInputType: { password: "password", confirmPassword: "password" },
      user: {},
      id: null,
      email: null,
      name: null,
      role: null,
      contact_number: null,
      password: null,
      confirmPassword: null,
      facebook_link: null,
      instagram_link: null,
      linkedin_link: null,
      promoter_link: null,
      clipboard_tooltip: null,
      widget_clipboard_tooltip: null,
      widget_url: null,
      email_notification: true,
      email_notification_for_tickets: true,
    };
  },
  methods: {
    async submitPasswordForm() {
      this.pass_flag = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        try {
          let token = localStorage.getItem("token");
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          let admin_obj = {
            email: this.email,
          };

          if (this.password) {
            admin_obj.password = this.password;
          }
          if(this.role == 'operator'){
            admin_obj.email_notification = this.email_notification;
            admin_obj.email_notification_for_tickets = this.email_notification_for_tickets;
          }

          let response = await axios.patch("settings/password", admin_obj);

          if (response) {
            this.setCustomValidationMessage(
              response.data.message,
              "success",
              true
            );
            this.password = null;
            this.confirmPassword = null;
            this.$v.$reset();
            await this.$store.dispatch(
              "authentication/updateUserInfo",
              response
            );
            this.setUserData();
          } else {
            this.setCustomValidationMessage(
              localStorage.getItem("error_message"),
              "danger",
              true
            );
            return;
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    async submitLinksForm() {
      this.pass_flag = false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        try {
          let token = localStorage.getItem("token");
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          let link_obj = {
            facebook_link: this.facebook_link,
            instagram_link: this.instagram_link,
            linkedin_link: this.linkedin_link,
          };
          let response = await axios.patch("settings/links", link_obj);
          if (response) {
            this.setCustomValidationMessage(
              response.data.message,
              "success",
              true
            );
            this.$v.$reset();
            await this.$store.dispatch(
              "authentication/updateUserInfo",
              response
            );

            this.setUserData();
          } else {
            this.setCustomValidationMessage(
              localStorage.getItem("error_message"),
              "danger",
              true
            );
            return;
          }
        } catch (err) {
          console.log(err);
        }
      }
    },

    showPassword(flag) {
      let type = flag == 1 ? "password" : "confirmPassword";
      if (this.passwordInputType[type] == "password") {
        this.passwordInputType[type] = "text";
        this.passwordIcon[type] = "fa fa-eye";
      } else {
        this.passwordInputType[type] = "password";
        this.passwordIcon[type] = "fa fa-eye-slash";
      }
    },

    setUserData() {
      this.user = JSON.parse(localStorage.getItem("user"));
      let user = JSON.parse(JSON.stringify(this.user));
      this.email = user.email;
      this.name = user.name;
      this.role = user.role;
      this.id = user.id;
      this.facebook_link = user.facebook_link;
      this.instagram_link = user.instagram_link;
      this.linkedin_link = user.linkedin_link;
      this.contact_number = user.contact_number;
      this.promoter_link = process.env.VUE_APP_USER_URL + "/promoters/" + user.promoter_slug;
      this.widget_url = process.env.VUE_APP_USER_URL + "/widget/";
      this.email_notification = user.email_notification;
      this.email_notification_for_tickets = user.email_notification_for_tickets;
      // this.clipboard_tooltip = null;
    },

    copyToClipboard(link) {
      navigator.clipboard.writeText(link);
      this.clipboard_tooltip = 'Copied'
    },

    removeCopiedFromTooltip() {
      setTimeout(() => {
        this.clipboard_tooltip = null;
      }, 200);
    },
    copyWidgetToClipboard(link) {
      console.log(link);
      navigator.clipboard.writeText(link);
      this.widget_clipboard_tooltip = 'Copied'
    },

    removeWidgetCopiedFromTooltip() {
      setTimeout(() => {
        this.widget_clipboard_tooltip = null;
      }, 200);
    },

  },
  validations: {
    email: {
      required,
    },
    password: {
      regex: function () {
        let validatePassword = true;
        if (this.isEditing) {
          validatePassword = true;
        } else {
          if (this.password) {
            let regexPassword = new RegExp(
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/
            );
            let regexValidation = !!regexPassword.exec(this.password);
            let lengthOfPassword = this.password.length;
            if (regexValidation && lengthOfPassword >= 8) {
              validatePassword = true;
            } else {
              validatePassword = false;
            }
          }
        }
        return validatePassword;
      },
      spaceCheck: function () {
        if (this.password){
          return !this.password.includes(' ');
          }
          return true;
      }
    },
    confirmPassword: {
      sameAsPassword: sameAs("password"),
    },
    facebook_link: {
      url,
    },
    instagram_link: {
      url,
    },
    linkedin_link: {
      url,
    },
  },
  mounted() {
    this.setUserData();
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row p-2 m-2">
      <div class="col-md-8">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12 ms-lg-auto">
                  <div class="mt-1 mt-lg-1">
                    <h3 class="font-size-18 mb-4">Details</h3>
                    <form @submit.prevent="submitPasswordForm">
                      <div class="form-group row mb-4">
                        <label for="email" class="col-sm-2 col-form-label"
                          >Email</label
                        >
                        <div class="col-sm-10">
                          <b-form-input
                            type="email"
                            placeholder="Email"
                            class="form-control"
                            v-model="email"
                            :readonly="user.role != 'admin'"
                            id="email"
                            :class="{
                              'is-invalid': $v.email.$error && pass_flag,
                            }"
                          ></b-form-input>
                          <div
                            v-if="pass_flag && $v.email.$error"
                            class="invalid-feedback"
                          >
                            Email is required.
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-4">
                        <label for="password" class="col-sm-2 col-form-label"
                          >Password</label
                        >
                        <div class="col-sm input-group">
                          <b-form-input
                            :type="passwordInputType.password"
                            class="form-control"
                            id="password"
                            placeholder="Password"
                            v-model="password"
                            :class="{
                              'is-invalid': $v.password.$error && pass_flag,
                            }"
                          ></b-form-input>
                          <div class="input-group-text">
                            <a
                              style="color: #000"
                              href="javascript:void(0)"
                              @click="showPassword(1)"
                            >
                              <i :class="passwordIcon.password"></i>
                            </a>
                          </div>
                          <div
                            v-if="pass_flag && $v.password.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.password.spaceCheck">
                              Password should not contain any space.
                            </span>
                            <span v-else-if="!$v.password.regex">
                              Password must be at least 8 characters long and
                              contain at least one lowercase, one uppercase
                              character, one numeric and one special character.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-4">
                        <label
                          for="confirmPassword"
                          class="col-sm-2 col-form-label"
                          >Confirm Password</label
                        >
                        <div class="col-sm input-group">
                          <b-form-input
                            :type="passwordInputType.confirmPassword"
                            class="form-control"
                            id="confirmPassword"
                            placeholder="Confirm Password"
                            v-model="confirmPassword"
                            :class="{
                              'is-invalid':
                                $v.confirmPassword.$error && pass_flag,
                            }"
                          ></b-form-input>
                          <div class="input-group-text">
                            <a
                              style="color: #000"
                              href="javascript:void(0)"
                              @click="showPassword(2)"
                            >
                              <i :class="passwordIcon.confirmPassword"></i>
                            </a>
                          </div>
                          <div
                            v-if="pass_flag && $v.confirmPassword.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.confirmPassword.sameAsPassword"
                              >Password should be the same.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-12">
                          <div class="form-group row mb-2">
                            <div class="row">
                              <div class="col-md-12 col-sm-12">
                                <div class="input-group">
                                  <label class="col-form-label">Email Notification - Event is Created</label>
                                  <div class="input-group-text bg-transparent border-0"></div>
                                </div>
                              </div>
                              <div class="col-md-4 col-sm-12 col-lg-2">
                                <b-form-radio
                                  v-model="email_notification"
                                  class="mt-2"
                                  :value="true"
                                  plain
                                >
                                  Enabled
                                </b-form-radio>
                              </div>
                              <div class="col-md-4 col-sm-12 col-lg-2">
                                <b-form-radio
                                  v-model="email_notification"
                                  class="mt-2"
                                  :value="false"
                                  plain
                                >
                                  Disabled
                                </b-form-radio>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-12">
                          <div class="form-group row mb-2">
                            <div class="row">
                              <div class="col-md-12 col-sm-12">
                                <div class="input-group">
                                  <label class="col-form-label">Email Notification - Tickets are Bought</label>
                                  <div class="input-group-text bg-transparent border-0"></div>
                                </div>
                              </div>
                              <div class="col-md-4 col-sm-12 col-lg-2">
                                <b-form-radio
                                  v-model="email_notification_for_tickets"
                                  class="mt-2"
                                  :value="true"
                                  plain
                                >
                                  Enabled
                                </b-form-radio>
                              </div>
                              <div class="col-md-4 col-sm-12 col-lg-2">
                                <b-form-radio
                                  v-model="email_notification_for_tickets"
                                  class="mt-2"
                                  :value="false"
                                  plain
                                >
                                  Disabled
                                </b-form-radio>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="row justify-content-start">
                        <div class="col-sm-12">
                          <button type="submit" class="btn btn-primary w-md">
                            Update
                          </button>
                        </div>
                      </div>
                      <!-- <div class="col-sm-12 d-flex align-items-center">
                        <a
                          href=" https://analytics.google.com/"
                          class="btn btn-primary w-md mx-3"
                          target="_blank"
                          rel="noopener noreferrer"
                          v-if="role == 'super-admin'"
                        >
                        Google Analytics
                        </a>
                        <a
                          href="https://clarity.microsoft.com/"
                          class="btn btn-primary w-md mx-3"
                          target="_blank"
                          rel="noopener noreferrer"
                          v-if="role == 'super-admin'"
                        >
                        Microsoft Clarity
                        </a>
                      </div>
                    </div> -->
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="role == 'admin'" class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12 ms-lg-auto">
                  <div class="mt-1 mt-lg-1">
                    <h3 class="font-size-18 mb-4">Social Media Links</h3>

                    <form @submit.prevent="submitLinksForm">
                      <div class="form-group row mb-4">
                        <label for="facebook" class="col-sm-2 col-form-label">
                          Facebook
                        </label>
                        <div class="col-sm-10">
                          <div class="input-group">
                            <div class="input-group-text">
                              <i class="uil uil-facebook"></i>
                            </div>
                            <input
                              type="url"
                              class="form-control"
                              id="facebook"
                              placeholder="Facebook"
                              v-model="facebook_link"
                              :class="{
                                'is-invalid':
                                  $v.facebook_link.$error && !pass_flag,
                              }"
                            />
                            <div
                              v-if="$v.facebook_link.$error && !pass_flag"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.facebook_link.url"
                                >This value should be a valid url.</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-4">
                        <label for="instagram" class="col-sm-2 col-form-label"
                          >Instagram</label
                        >
                        <div class="col-sm-10">
                          <div class="input-group">
                            <div class="input-group-text">
                              <i class="uil uil-instagram"></i>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              v-model="instagram_link"
                              placeholder="Instagram"
                              id="instagram"
                              :class="{
                                'is-invalid':
                                  $v.instagram_link.$error && !pass_flag,
                              }"
                            />
                            <div
                              v-if="$v.instagram_link.$error && !pass_flag"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.instagram_link.url"
                                >This value should be a valid url.</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-4">
                        <label for="linkedin" class="col-sm-2 col-form-label"
                          >LinkedIn</label
                        >
                        <div class="col-sm-10">
                          <div class="input-group">
                            <div class="input-group-text">
                              <i class="uil uil-linkedin"></i>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              id="linkedin"
                              placeholder="LinkedIn"
                              v-model="linkedin_link"
                              :class="{
                                'is-invalid':
                                  $v.linkedin_link.$error && !pass_flag,
                              }"
                            />
                            <div
                              v-if="$v.linkedin_link.$error && !pass_flag"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.linkedin_link.url"
                                >This value should be a valid url.</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row justify-content-end">
                        <div class="col-sm-12">
                          <button type="submit" class="btn btn-primary w-md">
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center">
              <div class="clearfix mb-3"></div>
              <div>
                <img
                  src="@/assets/images/users/avatar-9.jpg"
                  alt
                  class="avatar-lg rounded-circle img-thumbnail"
                />
              </div>
              <h5 class="mt-3 mb-1">{{ name }}</h5>
              <p class="text-muted">{{ role }}</p>
            </div>

            <div class="text-muted">
              <div class="table-responsive mt-4 mb-0">
                <table class="table mb-0">
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <p class="mb-1">Name :</p>
                          <h5 class="font-size-16">{{ name }}</h5>
                        </div>
                      </td>
                      <td>
                        <div>
                          <p class="mb-1">Mobile :</p>
                          <h5 class="font-size-16">
                            <span v-if="contact_number">
                              {{ contact_number | VMask('(###)-###-####') }}
                            </span>
                            <span v-else>
                              -
                            </span>
                          </h5>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td v-if="user.email">
                        <div>
                          <p class="mb-1">E-mail:</p>
                          <h5 class="font-size-16">{{ user.email }}</h5>
                        </div>
                      </td>
                      <td v-if="user.company_name">
                        <div>
                          <p class="mb-1">Company :</p>
                          <h5 class="font-size-16">{{ user.company_name }}</h5>
                        </div>
                      </td>
                      <td v-if="role!='operator'">
                        <div>
                          <p class="mb-1">Widget Demo link :
                            <i :class="{
                            'fa fa-clipboard': !!widget_clipboard_tooltip,
                            'far fa-clipboard': !widget_clipboard_tooltip
                          }" :id="'t_' + user.id" @mouseleave="removeWidgetCopiedFromTooltip(user)"
                            @click="copyWidgetToClipboard(widget_url)"></i></p>
                          <h5 class="font-size-16">{{ this.widget_url}}</h5>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td v-if="user.facebook_link">
                        <div>
                          <p class="mb-1"><i class="uil uil-facebook"></i></p>
                          <h5 class="font-size-16">{{ user.facebook_link }}</h5>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td v-if="user.instagram_link">
                        <div>
                          <p class="mb-1"><i class="uil uil-instagram"></i></p>
                          <h5 class="font-size-16">
                            {{ user.instagram_link }}
                          </h5>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td v-if="user.linkedin_link">
                        <div>
                          <p class="mb-1"><i class="uil uil-linkedin"></i></p>
                          <h5 class="font-size-16">{{ user.linkedin_link }}</h5>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td v-if="user.promoter_slug">
                        <div>
                          <p class="mb-1">Promoter Slug : 
                          <i v-if="promoter_link" :class="{
                            'fa fa-clipboard': !!clipboard_tooltip,
                            'far fa-clipboard': !clipboard_tooltip
                          }" :id="'tt_' + user.id" @mouseleave="removeCopiedFromTooltip(user)"
                            @click="copyToClipboard(promoter_link)"></i></p>
                          <h5 class="font-size-16">{{ promoter_link }}</h5>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EmbedWidgetInstructions :id="id" v-if="role == 'operator'"/>
  </Layout>
</template>