<template>
    <table class="table table-bordered text-center">
      <thead>
        <tr>
          <th>Category</th>
          <th v-if="showPriceColumns">Regular Price</th>
          <th>Regular Qty</th>
          <th v-if="showPriceColumns">Promo Price</th>
          <th>Promo Qty</th>
          <th v-if="showPriceColumns">Earnings ($)</th>
          <th>Created By</th>
          <th>Created At</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td>{{ item.category }}</td>
          <td v-if="showPriceColumns">{{ item.regularPrice }}</td>
          <td>{{ item.regularQty }}</td>
          <td v-if="showPriceColumns">{{ item.salePrice }}</td>
          <td>{{ item.saleQty }}</td>
          <td v-if="showPriceColumns">{{ item.earnings }}</td>
          <td>{{ item.createdBy }}</td>
          <td>{{ item.createdAt }}</td>
        </tr>
      </tbody>
    </table>
  </template>
  
  <script>
  export default {
    props: {
      data: Array,
      showPriceColumns: Boolean
    }
  };
  </script>
  