<template>
  <b-modal v-model="showModal" :title="title" @hide="onModalClose" :hideFooter="hideFooter" :dialogClass="dialogClass">
    <EmbedWidgetInstructions :id="selectedItemId" />
  </b-modal>
</template>

<script>
import EmbedWidgetInstructions from '@/components/widgets/embedWidgetInstructions.vue';

export default {
  name: 'EmbedWidgetModal',
  components: {
    EmbedWidgetInstructions
  },
  props: {
    selectedItemId: {
      type: String,
      required: true,
      default: null
    },
    title: {
      type: String,
      default: 'Embed Widget Instructions'
    },
    hideFooter: {
      type: Boolean,
      default: true
    },
    dialogClass: {
      type: String,
      default: 'modal-lg'
    }
  },
  data() {
    return {
      showModal: false,
      // modalTitle: this.title,
      // selectedItemId: null,
    };
  },
  mounted() {
   this.showModal = true;
  },
  methods: {
    onModalClose() {
      console.log('Modal closed');
      this.showModal = false;
      this.$emit('close');
    }
  }
};
</script>
