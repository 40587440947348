<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import axios from "axios"; // Import axios

/**
 * Add-product component
 */
export default {
  page() {
    return {
      title: "Order details",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },
  components: {
    Layout,
  },
  data() {
    return {
      bookings: [],
      customer: {
        name: '',
        email: '',
        phone: '' // Updated from isGuest to phone
      },
      transaction: {
        payment_method: '',
        payment_id: '',
        payer_id: ''
      },
      bookingTotal:{
        refund: '$0.00',
        total: '$0.00',
        subtotal : '$0.00',
        tax: '$0.00'
      },
      reverseTickets:{
        qty: 0,
        refunded_amount: 0,
        seats: '',
        created_at: ''
      }
    };
  },
  methods: {
    goBack() {
      // Handle back button click
    },
    async cancelItem(itemIds, section, category, event_id) {
        try {
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        // Prepare the data to be sent in the request
        const data = {
            order_ids: itemIds,
            section: section,
            category: category,
            event_id: event_id,
        };

        // Perform the API call
        let response = await axios.post("/order/cancel", data);
        if (response) {
          this.setCustomValidationMessage(response.data.message, "success", true);
          this.$router.push("/");
        } else {
          this.setCustomValidationMessage(
            localStorage.getItem("error_message"),
            "danger",
            true
          );
          return;
        }

        } catch (error) {
        console.error("Error cancelling item:", error);
        }
    },
    async fetchOrderDetails(orderId, specialCategoryId) {
      try {
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        let response = await axios.get("order/" + orderId + '/' + specialCategoryId);
        const order = response.data.data;
        this.bookings = [order.bookings[0]]; 
        this.customer = order.customer;
        this.transaction = order.transaction;
        this.bookingTotal = order.bookingTotal;
        this.reverseTickets = order.reverseTickets;
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    }
  },
  created() {
    const orderId = this.$route.params.id; 
    const specialCategoryId = this.$route.params.specialCategoryId;
    this.fetchOrderDetails(orderId, specialCategoryId);
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <!-- <div class="container mt-4"> -->
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h3>Order Details</h3>
        <!-- <button @click="goBack" class="btn btn-secondary">Back to Dashboard</button> -->
      </div>
      <div class="row mb-4">
        <div class="col-md-9">
          <div v-for="booking in bookings" :key="booking.id" class="card mb-4">
            <div class="card-header">
              {{ booking.title }}
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between mb-3">
                <div>
                  <p><strong>Location : </strong> {{ booking.address }}</p>
                  <p><strong>Event Timing : </strong>{{ booking.date }}</p>
                </div>
                <img v-if="booking.image" :src="booking.image" alt="Event Image" class="img-fluid" style="max-width: 150px;">
                <div v-if="!booking.image" class="avatar-xs d-inline-block me-2">
                    <span class="avatar-title square bg-light text-dark fw-bold">{{
                        booking.title.charAt(0).toUpperCase()
                    }}</span>
                </div>
              </div>
              <div class="table-responsive" >
                <table class="table">
                  <thead >
                    <tr v-if="booking.items.length > 0">
                      <th>#</th>
                      <th>Cost</th>
                      <th>Qty</th>
                      <th>Price</th>
                      <th>Section-Row-Seat</th>
                      <th v-if="booking.type == 'paid'">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in booking.items" :key="item.id">
                      <td>{{ item.id }}</td>
                      <td>{{ item.cost }}</td>
                      <td>{{ item.qty }}</td>
                      <td>{{ item.price }}</td>
                      <td>{{ item.ticket_section }}</td>
                      <td >
                        <button @click="cancelItem(item.id, item.ticket_section, booking.category, booking.product_id)" class="btn btn-danger btn-sm">Cancel</button>
                      </td>
                    </tr>
                    <tr booking.items.length >
                      <td colspan="5"></td>
                      <td>
                        <button @click="cancelItem(booking.order_ids, '', booking.category, booking.product_id)" class="btn btn-danger btn-sm">Cancel All</button>
                      </td>
                    </tr>
                    <tr v-if="booking.type == 'paid'" >
                      <td colspan="4"></td>
                      <td>
                        <strong>Subtotal ( Base Amount * Qty )</strong><br>
                        <strong>Taxes and Processing fees</strong><br>
                        <strong>Total</strong><br><br>

                        <strong>Refunded Amount</strong><br>

                      </td>
                      <td>
                        <strong>{{ bookingTotal.subtotal }}</strong><br>
                        <strong>{{ bookingTotal.tax }}</strong><br>
                        <strong>{{ bookingTotal.total }}</strong><br><br>

                        <strong>{{ bookingTotal.refund }}</strong><br>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card">
                <div class="card-header">
                  Reversed Tickets History
                </div>
                <div class="card-body">
                  <div class="table-responsive" v-if="reverseTickets.length > 0">
                    <table class="table text-center">
                      <thead>
                        <tr>
                          <!-- <th>Reverse Tickets</th> -->
                          <th>#</th>
                          <th>Reversed Quantity</th>
                          <th>Refunded Amount</th>
                          <th>Seats</th>
                          <th>Refunded Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for=" item in reverseTickets" :key="item.id" >
                          <td>{{ item.order_id }}</td>
                          <td>{{ item.qty }}</td>
                          <td>{{ item.refunded_amount }}</td>
                          <td>{{ item.seats }}</td>
                          <td>{{ item.created_at }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              

            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row mb-4">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  Customer Details
                </div>
                <div class="card-body">
                  <p><strong>{{ customer.name }}</strong></p>
                  <p>Email: {{ customer.email }}</p>
                  <p>Phone: {{ customer.phone }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-12" v-if="bookings[0].type == 'paid'">
              <div class="card">
                <div class="card-header">
                  Transaction Details
                </div>
                <div class="card-body">
                  <p>Payment Method: {{ transaction.payment_method }}</p>
                  <p>Payment ID: {{ transaction.payment_id }}</p>
                  <p>Payer ID: {{ transaction.payer_id }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-between">
        <div>Subtotal: $3200.00</div>
        <div>Processing Fees: $20.00</div>
        <div class="font-weight-bold">Total: $3220.00</div>
      </div> -->
    <!-- </div> -->
  </Layout>
</template>

<!-- Removed custom styles -->
