<template>
    <div class="row p-2 m-2">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-3">Instructions for Embedding Events Widget:</h5>
            <ol>
              <li>Copy the following code: ( Based on way you render events) </li>
              <div class="card border border-primary mt-3">
              <div style="margin-left: 30px; margin-top: 10px; font-weight: bold;">
                To display events in a VERTICAL layout
              </div>
                <div class="card-body">
                  <p class="card-text" id="widgetCodevertical">
                     &lt;div class="iframe-container" style="width: 100%; height: 100%;" &gt;<br>
                       &lt;iframe src="{{userUrl}}/vertical-widget?promoterId={{id}}" style="width:100%; height:100%" frameborder="0"&gt; &lt;/iframe&gt;<br>
                     &lt;/div&gt;<br>

                    <!-- &lt;div id="event-widget-container" data-promoter-id="{{id}}" data-endpoint="{{apiendpoint}}" &gt;&lt;/div&gt; <br>
                    &lt;script src="{{adminUrl}}/promoter/productWidgetVertical.js"&gt;&lt;/script&gt; -->
                  </p>
                  <button class="btn btn-primary" @click="copyToClipboard('vertical')" :disabled="isCopiedVertical">
                    {{ isCopiedVertical ? 'Copied!' : 'Copy to Clipboard' }}
                  </button>
                </div>
              </div>   
              <div class="card border border-primary mt-3">
                <div style="margin-left: 30px; margin-top: 10px; font-weight: bold;">
                  To display events in a HORIZONTAL layout
                </div>
                <div class="card-body">
                  <p class="card-text" id="widgetCodehorizontal">
                    &lt;div class="iframe-container" style="width: 100%; height: 100%;" &gt;<br>
                       &lt;iframe src="{{userUrl}}/horizontal-widget?promoterId={{id}}" style="width:100%; height:100%" frameborder="0"&gt; &lt;/iframe&gt;<br>
                     &lt;/div&gt;<br>
                    <!-- &lt;div id="event-widget-container" data-promoter-id="{{id}}" data-endpoint="{{apiendpoint}}" &gt;&lt;/div&gt; <br>
                    &lt;script src="{{adminUrl}}/promoter/productWidgetHorizontal.js"&gt;&lt;/script&gt; -->
                  </p>
                  <button class="btn btn-primary" @click="copyToClipboard('horizontal')" :disabled="isCopiedHorizontal">
                    {{ isCopiedHorizontal ? 'Copied!' : 'Copy to Clipboard' }}
                  </button>
                </div>
              </div>
              <div class="card border border-primary mt-3">
                <div style="margin-left: 30px; margin-top: 10px; font-weight: bold;">
                  To display events in a CALENDAR layout
                </div>
                <div class="card-body">
                  <p class="card-text" id="widgetCodecalendar">
                    &lt;div class="iframe-container" style="width: 100%; height: 100%;" &gt;<br>
                       &lt;iframe src="{{userUrl}}/calendar-widget?promoterId={{id}}" style="width:100%; height:100%" frameborder="0"&gt; &lt;/iframe&gt;<br>
                     &lt;/div&gt;<br>
                    <!-- &lt;div id="popover" class="popover"&gt;<br>
                      &lt;div class="popover-close"&gt;x&lt;/div&gt;<br>
                      &lt;img class="popover-img" src="" alt="Event Image"&gt;<br>
                      &lt;div class="hr-line"&gt;<br>
                          &lt;div class="popover-header"&gt;&lt;/div&gt;<br>
                          &lt;div class="popover-body"&gt;&lt;/div&gt;<br>
                          &lt;div class="popover-price"&gt;&lt;/div&gt;<br>
                          &lt;a href="" target="_blank" class="popover-btn"&gt;Buy Tickets&lt;/a&gt;<br>
                      &lt;/div&gt;<br>
                    &lt;/div&gt;<br>
                    &lt;div id="event-widget-container" data-promoter-id="{{id}}" data-endpoint="{{apiendpoint}}" &gt;&lt;/div&gt; <br>
                    &lt;script src='https://cdn.jsdelivr.net/npm/fullcalendar@6.1.15/index.global.min.js'&gt;&lt;/script&gt;
                    &lt;script src="{{adminUrl}}/promoter/productWidgetCalendar.js"&gt;&lt;/script&gt; -->
                  </p>
                  <button class="btn btn-primary" @click="copyToClipboard('calendar')" :disabled="isCopiedCalendar">
                    {{ isCopiedCalendar ? 'Copied!' : 'Copy to Clipboard' }}
                  </button>
                </div>
              </div>          
              <li>Paste the copied code into the HTML source code of your webpage where you want the events to be displayed. You can paste it directly into the HTML editor of your website builder or content management system (CMS).</li>
              <li>Save the changes to your webpage.</li>
              <li>Once the code is added and saved, visit your webpage to view the events widget. The widget will fetch and display events associated with the promoter.</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'EmbedWidgetInstructions',
    props: {
      id: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isCopiedVertical: false,
        isCopiedHorizontal: false,
        isCopiedCalendar: false,
        adminUrl: process.env.VUE_APP_ADMIN_URL || "http://localhost:3000",
        apiendpoint: process.env.VUE_APP_AXIOS_BASEURL || "http://localhost:3500/api/",
        userUrl: process.env.VUE_APP_USER_URL || "http://localhost:8080"
      };
    },
    methods: {
      copyToClipboard(data) {
        const el = document.createElement('textarea');
        el.value = document.getElementById('widgetCode' + data).innerText;
        navigator.clipboard.writeText(el.value);
        if(data == 'vertical'){
          this.isCopiedVertical = true;
        } else if( data =='horizontal'){
          this.isCopiedHorizontal = true;
        } else if( data == 'calendar'){
          this.isCopiedCalendar = true;
        }
        setTimeout(() => {
          if(data == 'vertical'){
          this.isCopiedVertical = false;
        } else if( data =='horizontal'){
          this.isCopiedHorizontal = false;
        } else if( data == 'calendar'){
          this.isCopiedCalendar = false;
        }
        }, 10000); // Reset the button text after 10 seconds
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>
  