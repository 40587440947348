<template>
    <Layout>
        <PageHeader :title="title" />
    <div class="report-viewer">
      <div class="button-group">
        <button @click="loadReport('checkout/index.html')">Checkout</button>
        <button @click="loadReport('event_detail_page/index.html')">Event Detail Page</button>
        <button @click="loadReport('event_page/index.html')">Event Page</button>
        <button @click="loadReport('login_page/index.html')">Login Page</button>
      </div>
      <iframe :src="reportSrc" class="report-iframe"></iframe>
    </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  export default {
    name: "Prior to Load Optimization",
    data() {
      return {
        reportSrc: '/reports/checkout_before/checkout/index.html',
        title: "Prior to Load Optimization",
      };
    },
    methods: {
      loadReport(reportPath) {
        this.reportSrc = `/reports/checkout_before/${reportPath}`;
      },
    },
    components: {
      Layout,
      PageHeader,
    },
  };
  </script>
  
  <style scoped>
  .button-group {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .button-group button {
    margin: 0 5px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button-group button:hover {
    background-color: #0056b3;
  }
  
  .report-iframe {
    width: 100%;
    height: 80vh;
    border: none;
  }
  </style>
  