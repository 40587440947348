<script>
import SeatsioEmbeddable from './seatsioEmbeddable.vue'

export default {
    mixins: [SeatsioEmbeddable],
    name: 'SeatsioEventBooking',
    data() {
        return {
            isFullscreen: false,
        }
    },
    methods: {
        finaliseProps: function (props) {
          // eslint-disable-next-line no-unused-vars
            const { chartJsUrl, id, region, showFullscreenButton, chartKey, ...finalProps } = props
            return { chart: chartKey, ...finalProps }
        },
        createChart: (seatsio, config) => {
          return new seatsio.EventManager(config)
        },
        toggleFullScreen() {
            this.isFullscreen = !this.isFullscreen;
        },
    }
}
</script>

<template>
    <div class="row" :class="{
        'card-fullscreen': isFullscreen
    }">
        <div class="card">
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-4">
                        <h4>Manage Booking</h4>
                    </div>
                    <div class="col-md-8">
                        <div class="float-end">
                            <i :class="{
                                'fa fa-lg fa-expand': !isFullscreen,
                                'fa fa-lg fa-compress': isFullscreen
                            }" @click="toggleFullScreen()"></i>
                        </div>
                    </div>
                </div>
                <div :id="id" class="chart">
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.chart {
    height: 92vh;
}

.card-fullscreen {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
    z-index: 9999 !important;
}
</style>