<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { saveAs } from "file-saver";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import axios from "axios";
import { listingFields } from "./listingFields";
import listingPermissions from "./listing-permission.json";
import "vue-multiselect/dist/vue-multiselect.min.css";
import moment from 'moment';
import TicketsSoldHistoryModal from "@/components/widgets/ticketsSoldHistoryModal.vue";

const Excel = require("exceljs");

/**
 * Invoice-list component
 */
export default {
  page: {
    title: "Event List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, TicketsSoldHistoryModal },
  data() {
    return {
      tableData: [],
      isMounted: false,
      event_filter: "",
      event_options: ["All", "Published", "Drafted", "Past"],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      canCreate: false,
      haveAction: true,
      perPage: 50,
      imageBaseUrl: "",
      pageOptions: [30, 50, 100, 150],
      filter: '',
      filterOn: [],
      sortBy: "post_start_date",
      sortDirection: "desc",
      sortDesc: true,
      fields: listingFields,
      selectedItemId: null,
      selectedEventName: '',
      modalTitle: 'Tickets Sold',
      user: null,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    }
  },
  mounted() {
    // Set the initial number of items
    this.user = JSON.parse(localStorage.getItem("user"));

    this.setListingFields();
    this.getData();
  },
  methods: {

    copyToClipboard(data, link) {
      navigator.clipboard.writeText(link);
      data.clipboard_weburl_tooltip = null;
      data.clipboard_tooltip = 'Copied'

    },

    removeCopiedFromTooltip(data) {
      setTimeout(() => {
        data.clipboard_tooltip = null;
        data.clipboard_weburl_tooltip = null;
      }, 200);
    },

    copyToClipboardWebUrl(data, link) {
      navigator.clipboard.writeText(link);
      data.clipboard_weburl_tooltip = 'Copied URL'
      data.clipboard_tooltip = null;
    },

    removeCopiedWebUrlFromTooltip(data) {
      setTimeout(() => {
        data.clipboard_weburl_tooltip = null;
        data.clipboard_tooltip = null;
      }, 400);
    },

    sortByColumn(a, b, key) {
      if (key !== 'post_start_date') {
        // key is not the field that is a date.
        // Let b-table handle the sorting for other columns
        // returning null or false will tell b-table to fall back to it's
        // internal sort compare routine for fields keys other than myDateField
        return null; // or false
      }

      let aDate = moment(a[key], 'MM/DD/YYYY HH:mm');
      let bDate = moment(b[key], 'MM/DD/YYYY HH:mm');

      if (aDate.isValid() && bDate.isValid()) {
        if (aDate < bDate) {
          return -1;
        }
        else if (aDate > bDate) {
          return 1;
        }
        else {
          return 0;
        }
      }
      return null;
    },
    sortingOrder(column) {
      this.sortBy = column;
      if (this.sortBy === column) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortBy = column;
        this.sortDirection = "asc";
      }
      this.getData(this.event_filter);
    },
    /**
     * Search the table data with search input
     */
    filterEvent(option) {
      // if (!this.isMounted) return; // Prevent execution before mounting
      this.getData(option);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onSearch() {
      this.getData(this.event_filter);
    },
    setListingFields() {
      this.fields = JSON.parse(JSON.stringify(listingFields));
      let listingFieldPermission = listingPermissions.permissions[this.user.role];
      this.fields = this.fields.filter((item) => {
        let flag = false;
        if (item.id) {
          if (listingFieldPermission.includes(item.id)) {
            flag = true;
          }
        }
        return flag;
      });
    },
    checkActions() {
      let eventData = JSON.parse(JSON.stringify(this.tableData));
      this.setListingFields();
      this.fields = this.fields.filter((item) => {
        let flag = true;
        if (item.id == 10) {
          flag = false;
          for (let x of eventData) {
            if (x.permission.canEdit || x.permission.canDelete || x.permission.canView) {
              flag = true;
              return flag;
            }
          }
        }
        return flag;
      });
    },
    createProduct() {
      this.$router.push("/products/create-product");
    },
    editProduct(id) {
      this.$router.push("/products/" + id + "/edit");
    },
    viewProduct(id) {
      this.$router.push("/products/" + id);
    },
    openEmbedWidgetModal(id, event_name) {
      this.selectedItemId = id;
      this.selectedEventName = event_name;
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          this.deleteProduct(id);
        }
      });
    },
    confirmDuplicateProduct(id) {
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Duplicate Event",
          }).then((result) => {
            if (result.value) {
              this.duplicateProduct(id);
            }
          });
        },

    async ExportScandata(id) {
      try {
        const response = await axios.get(`/product/export-scandata/${id}`);

        if (response && response.data) {
          this.setCustomValidationMessage(response.data.message, "success", true);

          // Create workbook and worksheet
          const workbook = new Excel.Workbook();
          const worksheet = workbook.addWorksheet("Sheet1");

          // Define worksheet columns
          worksheet.columns = [
            { header: "ID", key: "id", width: 10 },
            { header: "Order ID", key: "order_id", width: 10 },
            { header: "Event Name", key: "product_name", width: 30 },
            { header: "User Name", key: "user_name", width: 30 },
            { header: "Quantity", key: "qty", width: 10 },
            { header: "Ticket Type", key: "ticket_type", width: 20 },
            { header: "Section-Row-Seat", key: "seatsno", width: 30 },
            { header: "Scanned by", key: "scan_by_user_name", width: 30 },
            { header: "Scanned Date", key: "scan_date", width: 20 },  // New column for date
            { header: "Scanned Time", key: "scan_time", width: 15 },  // New column for time in 12-hour format
          ];

          // Prepare data for the worksheet
          const excelData = response.data.data.map((element, index) => {
            const scanDate = moment(element.scan_at).format("MM/DD/YYYY"); // Date part
            const scanTime = moment(element.scan_at).format("hh:mm A");    // Time in 12-hour format with AM/PM

            return {
              id: index + 1,
              order_id: element.order_id,
              product_name: element.product_name,
              user_name: element.user_name,
              qty: element.qty,
              ticket_type: element.ticket_type,
              seatsno: element.seatsno,
              scan_by_user_name: element.scan_by_user_name || "-", // Default to "-" if null
              scan_date: scanDate,   // Scan date
              scan_time: scanTime,   // Scan time
            };
          });

          // Add data rows to the worksheet
          worksheet.addRows(excelData);
          worksheet.getRow(1).font = { bold: true };

          // Generate the Excel file buffer
          const buffer = await workbook.xlsx.writeBuffer();

          // Create a Blob and trigger file download
          const data = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          });

          // Construct filename with "ScanData" and product name
          const productName = response.data.data[0]?.product_name || "Product";
          const filename = `ScanData_${productName}_${new Date().toLocaleDateString()}.xlsx`;

          saveAs(data, filename);
        } else {
          // Handle unexpected response
          this.setCustomValidationMessage("Failed to export data.", "danger", true);
        }
      } catch (error) {
        console.error("Error exporting scan data:", error);
        this.setCustomValidationMessage("An error occurred. Please try again.", "danger", true);
      }
    },


    handlePageChange(page) {
      this.currentPage = page;
      this.getData(this.event_filter);
    },
    handlePageSizeChange(perPage) {
      this.perPage = perPage;
      this.getData(this.event_filter);
    },
    async deleteProduct(id) {
      try {
        let response = await axios.delete("/product/delete/" + id);
        if (response) {
          this.setCustomValidationMessage(
            response.data.message,
            "success",
            true
          );
          this.getData();
        } else {
          this.setCustomValidationMessage(
            localStorage.getItem("error_message"),
            "danger",
            true
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async duplicateProduct(id) {
      try{
        let response = await axios.post("/product/duplicate", {
          product_id: id
        });
        if(response) {
          this.setCustomValidationMessage(
            response.data.message,
            "success",
            true
          );
          let product_id = response.data.data.product_id;
          this.$router.push("/products/" + product_id + "/edit");
         } else {
          this.setCustomValidationMessage(
            localStorage.getItem("error_message"),
            "danger",
            true
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getData(filter = "all") {
      try {
        this.isBusy = true;
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        let response = await axios.get(`products/list?page=${this.currentPage}&limit=${this.perPage}&filter=${filter.toLowerCase()}&string=${this.filter}&sort_by=${this.sortBy}&sorting_order=${this.sortDirection}`);
        if (response) {
          this.isBusy = false;
          this.tableData = [];
          this.tableData = response.data.data;
          this.canCreate = response.data.canCreate;
          this.perPage = response.data.per_page || this.perPage;
          this.totalRows = response.data.total_count || this.totalRows;
          this.currentPage = response.data.current_page || this.currentPage;
          this.checkActions();
        } else {
          this.isBusy = false;
          console.log("error");
        }
      } catch (error) {
        this.isBusy = false;
        console.log(error);
        this.tableData = [];
      }
    },
    async downloadCSVData() {
      //export all product data as an excel file
      var workbook = new Excel.Workbook();
      var worksheet = workbook.addWorksheet("Sheet1");
      worksheet.columns = [
        { header: "Name", key: "formatted_title", width: 20, style: { alignment: { horizontal: 'left' } } },
        { header: "Description", key: "formatted_content", width: 20 },
        { header: "Artist Name", key: "artist_name", width: 20 },
        { header: "Artist Detail", key: "artist_detail", width: 20 },
        this.user.role != "operator" ? { header: "Gross Sale", key: "gross_sales", width: 20 } : null,
        { header: "Net Sale", key: "net_sales", width: 20 },
        { header: "Tickets Sold", key: "total_tickets", width: 20 },
        { header: "Creator", key: "operator", width: 20 },
        { header: "Team Members", key: "workers", width: 20 },
        { header: "Status", key: "status", width: 20 },
        { header: "Event start date", key: "post_start_date", width: 20 },
        { header: "Event start time", key: "post_start_time", width: 20 },
        { header: "Event door open time", key: "door_open_time", width: 20 },
        { header: "Event end date", key: "post_end_date", width: 20 },
        { header: "Event end time", key: "post_end_time", width: 20 },
        { header: "City", key: "mep_city", width: 20 },
        { header: "State", key: "mep_state", width: 20 },
        { header: "Country", key: "mep_country", width: 20 },
        { header: "Zipcode", key: "mep_postcode", width: 20 },
        { header: "Venue", key: "mep_location_venue", width: 20 },
        { header: "Street", key: "mep_street", width: 20 },
        { header: "Type", key: "type", width: 20 },
        { header: "Category", key: "category_primary", width: 20 },
        { header: "Promo start date", key: "sale_start_date", width: 20 },
        { header: "Promo end date", key: "sale_end_date", width: 20 },
        { header: "Special Price Name", key: "name", width: 20 },
        { header: "Price", key: "ticket_price_web", width: 20 },
        { header: "Promo Price", key: "ticket_sale_price", width: 20 },
        { header: "Min Quantity", key: "min_quantity", width: 20 },
        { header: "Max Quantity", key: "max_quantity", width: 20 },
        { header: "Tickets Left", key: "available_quantity", width: 20 },
        { header: "Account Name", key: "account_name", width: 20 },
        { header: "Account Number", key: "account_number", width: 20 },
        { header: "Bank Name", key: "bank_name", width: 20 },
        { header: "Routing Number", key: "routing_number", width: 20 },
      ].filter((item) => !!item);
      let excelData = [];
      let tableData = JSON.parse(JSON.stringify(this.tableData));
      tableData.forEach(element => {
        if (element.category_primary === 'general-admission') {
          let workers_name = element.workers.map(worker => worker.name);
          excelData.push({
            ...element,
            operator: element.operator.name,
            workers: workers_name.join(),
            formatted_content: this.convertHTMLToText(element.formatted_content),
            artist_detail: this.convertHTMLToText(element.artist_detail),
            post_start_date: moment(element.post_start_date, "MM/DD/YYYY HH:mm:ss").format("MM/DD/YYYY"),
            post_end_date: moment(element.post_end_date, "MM/DD/YYYY HH:mm:ss").format("MM/DD/YYYY"),
            post_start_time: moment(element.post_start_time, "HH:mm:ss").format("hh:mm A"),
            post_end_time: moment(element.post_end_time, "HH:mm:ss").format("hh:mm A"),
            status: (element.status) ? (element.status.charAt(0).toUpperCase() + element.status.slice(1)) : null,
            type: (element.type) ? (element.type.charAt(0).toUpperCase() + element.type.slice(1)) : null,
            available_quantity: element.available_quantity,
            category_primary: (element.category_primary) ? (element.category_primary == 'general-admission' ? 'Single Ticket' : 'Multiple Ticket') : null,
          });
        } else {
          element.special_categories.forEach((sc, i) => {
            if (i == 0) {
              let workers_name = element.workers.map(worker => worker.name);
              excelData.push({
                ...element,
                operator: element.operator.name,
                workers: workers_name.join(),
                formatted_content: this.convertHTMLToText(element.formatted_content),
                artist_detail: this.convertHTMLToText(element.artist_detail),
                post_start_date: moment(element.post_start_date, "MM/DD/YYYY HH:mm:ss").format("MM/DD/YYYY"),
                post_end_date: moment(element.post_end_date, "MM/DD/YYYY HH:mm:ss").format("MM/DD/YYYY"),
                post_start_time: moment(element.post_start_time, "HH:mm:ss").format("hh:mm A"),
                post_end_time: moment(element.post_end_time, "HH:mm:ss").format("hh:mm A"),
                status: (element.status) ? (element.status.charAt(0).toUpperCase() + element.status.slice(1)) : null,
                type: (element.type) ? (element.type.charAt(0).toUpperCase() + element.type.slice(1)) : null,
                category_primary: (element.category_primary) ? (element.category_primary == 'general-admission' ? 'Single Ticket' : 'Multiple Ticket') : null,
                name: sc.name,
                ticket_price_web: sc.regular_price_web,
                ticket_sale_price: sc.sale_price,
                min_quantity: sc.min_quantity,
                max_quantity: sc.max_quantity,
                quantity_step: sc.quantity_step,
                available_quantity: sc.stock_quantity,
              })
            } else {
              excelData.push({
                name: sc.name,
                ticket_price_web: sc.regular_price_web,
                ticket_sale_price: sc.sale_price,
                min_quantity: sc.min_quantity,
                max_quantity: sc.max_quantity,
                quantity_step: sc.quantity_step,
                available_quantity: sc.stock_quantity,
              })
            }
          });
        }
      });


      worksheet.addRows(excelData);
      worksheet.getRow(1).font = { bold: true };

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      var filename = "Event_List_" + new Date().toDateString() + ".xlsx";
      saveAs(data, filename);
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader />
    <div class="row mb-2">
      <div class="col-md-4">
        <h3>Events</h3>
      </div>
      <div class="col-md-8">
        <div class="float-end">
          <div>
            <button type="button" @click="downloadCSVData" class="btn btn-info mb-3">
              <i class="mdi mdi-file me-1"></i> Export
            </button>
            <button v-if="canCreate" type="button" @click="createProduct" class="btn btn-warning ms-1 mb-3">
              <i class="mdi mdi-plus me-1"></i> Add Event
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Search -->
      <div class="col-md-7">
        <div id="tickets-table_filter" class="dataTables_filter text-md-start">
          <div class="input-group">
            <div class="input-group-text"><i class="uil-search"></i></div>
            <b-form-input v-model="filter" placeholder="Search Event" type="search"
              class="form-control form-control-md px-1" @keyup.enter="onSearch"></b-form-input>
          </div>
        </div>
      </div>
      <!-- End search -->
      <div class="col-md-3">
        <div class="form-control-md">
          <multiselect v-model="event_filter" :preselectFirst="true" :options="event_options" @select="filterEvent">
          </multiselect>
        </div>
      </div>
      <div class="col-md-2">
        <div id="tickets-table_length" class="dataTables_length text-md-start">
          <label class="d-inline-flex align-items-center fw-normal mt-1">
            Show&nbsp;
            <b-form-select v-model="perPage" size="lg" :options="pageOptions" @input="handlePageSizeChange"></b-form-select>&nbsp;entries
          </label>
        </div>
      </div>
    </div>
    <!-- Table -->
    <div class="table-responsive mb-0">
      <table class="table table-centered datatable table-card-list">
        <thead class="bg-transparent">
          <tr class="bg-transparent">
            <th v-for="field in fields" :key="field.key" @click="sortingOrder(field.key)">
              {{ field.label }}

             <span v-if="field.sortable">
                <i v-if="sortBy === field.key" 
                  class="fas" 
                  :class="{ 'fa-sort-up': sortDirection === 'asc', 'fa-sort-down': sortDirection === 'desc' }">
                </i>
                <i v-else class="fas fa-sort"></i>
            </span>
              <i v-if="field.icon" :class="field.icon"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isBusy">
            <td colspan="15" class="text-center text-theme">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Loading...</strong>
            </td>
          </tr>
          <tr v-else v-for="data in tableData" :key="data.product_id">
            <!-- Featured Image -->
            <td>
              <img v-if="data.featured_image" :src="imageBaseUrl + data.featured_image" alt class="avatar-sm square" />
              <div v-else class="avatar-xs d-inline-block me-2">
                <span class="avatar-title square bg-light text-dark fw-bold">{{ data.formatted_title.charAt(0).toUpperCase() }}</span>
              </div>
            </td>
            
            <!-- Product Title -->
            <td>
              <router-link :to="`/products/${data.product_id}`" class="text-dark font-size-15">{{ data.formatted_title }}</router-link>
            </td>
    
            <!-- Post Start Date -->
            <td>
              <div class="text-dark fw-bold font-size-15">
                <b>{{ data.post_start_date | formatDate }}</b>
              </div>
            </td>
    
            <!-- Available Quantity -->
            <td>
              <div v-if="data.category_primary === 'general-admission'">
                <span v-if="data.available_quantity > 0" class="text-dark fw-bold font-size-15 badge bg-soft-success p-1">
                  InStock ({{ data.available_quantity }})
                </span>
                <span v-else class="text-dark fw-bold font-size-15 badge bg-soft-danger p-1">
                  OutOfStock (0)
                </span>
              </div>
              <div v-else>
                <span v-if="data.special_categories.reduce((a, b) => a + b.stock_quantity, 0)" class="text-dark fw-bold font-size-15 badge bg-soft-success p-1">
                  InStock ({{ data.special_categories.reduce((a, b) => a + b.stock_quantity, 0) }})
                </span>
                <span v-else class="text-dark fw-bold font-size-15 badge bg-soft-danger p-1">
                  OutOfStock (0)
                </span>
              </div>
            </td>
    
            <!-- Category -->
            <td>
              <div class="text-dark font-size-15">
                {{ data.category_primary | categoryFilter }}
              </div>
            </td>
    
            <!-- Ticket Price -->
            <td>
              <div class="text-dark font-size-15">
                {{ data.ticket_price_web | priceFilter(data.category_primary) }}
              </div>
            </td>
    
            <!-- Gross Sales -->
            <td v-if="user.role != 'operator'">
              <div class="text-dark font-size-15">
                {{ data.gross_sales | priceFilter }}
              </div>
            </td>
    
            <!-- Net Sales -->
            <td>
              <div class="text-dark font-size-15">
                {{ data.net_sales | priceFilter }}
              </div>
            </td>
    
            <!-- Total Tickets -->
            <td>
              <div class="text-center">
                <b-button v-if="data.total_tickets > 0" class="btn-rounded" size="sm" variant="info" @click="openEmbedWidgetModal(String(data.product_id), data.formatted_title)">
                  {{ data.total_tickets }}
                </b-button>
                <span v-else class="text-dark font-size-15 text-center">
                  {{ data.total_tickets }}
                </span>
              </div>
            </td>

            <!-- Creator -->
            <td v-if="user.role != 'operator'">
              <div class="text-dark font-size-15">
                {{ data.operator.name }}
              </div>
            </td>
    
            <!-- Team Members -->
            <td>
              <div class="text-dark font-size-15">
                <div v-if="data.workers.length > 0">
                  <span v-for="worker in data.workers.slice(0, 3)" :key="worker.id" class="text-dark badge fw-bold font-size-15 bg-soft-main-theme-color p-1 me-2">
                    {{ worker.name }}
                  </span>
                  <span v-if="data.workers.length > 3" class="text-dark fw-bold font-size-15" :title="data.workers.slice(3).map(worker => worker.name).join(', ')">
                    +{{ data.workers.length - 3 }}
                  </span>
                </div>
                <div v-else>
                  -
                </div>
              </div>
            </td>
    
            <!-- Status -->
            <td>
              <div class="text-dark badge fw-bold font-size-15 p-1" :class="{
                'bg-soft-success': data.status === 'published',
                'bg-soft-warning': data.status === 'drafted',
              }">
                {{ data.status | capitalFilter }}
              </div>
            </td>
    
            <!-- Actions -->
            <td>
              <b-dropdown v-if="data.permission.canEdit || data.permission.canDelete || data.permission.canView
              || data.permission.canDuplicate" variant="transparent" class="btn-group me-2 mb-2 mb-sm-0" right>
              <template slot="button-content">
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item v-if="data.permission.canView"
                @click="viewProduct(data.product_id)">View</b-dropdown-item>
              <b-dropdown-item v-if="data.permission.canEdit"
                @click="editProduct(data.product_id)">Edit</b-dropdown-item>
              <b-dropdown-item v-if="data.permission.canDelete"
                @click="confirmDelete(data.product_id)">Delete</b-dropdown-item>
              <b-dropdown-item v-if="data.permission.canDuplicate"
                @click="confirmDuplicateProduct(data.product_id)">Create Duplicate Event</b-dropdown-item>
              <b-dropdown-item v-if="data.permission.canExportScanData"
                @click="ExportScandata(data.product_id)">Export Scandata</b-dropdown-item>
            </b-dropdown>
            </td>
    
            <!-- Web URL -->
            <td>
              <i v-if="data.web_url" :class="{
                'fa fa-clipboard': !!data.clipboard_weburl_tooltip,
                'far fa-clipboard': !data.clipboard_weburl_tooltip
              }" :id="'tt_' + data.product_id" @mouseleave="removeCopiedWebUrlFromTooltip(data)" @click="copyToClipboardWebUrl(data, data.web_url)"></i>
              <b-tooltip :title="data.clipboard_weburl_tooltip ? data.clipboard_weburl_tooltip : 'Click to copy URL'" :target="'tt_' + data.product_id" placement="rightbottom"></b-tooltip>
            </td>
          </tr>
          <tr v-if="!tableData.length && !isBusy">
            <td colspan="15" class="text-center">No data found</td>
          </tr>
        </tbody>
      </table>
      <TicketsSoldHistoryModal v-if="selectedItemId" :eventName="selectedEventName" :selectedItemId="selectedItemId" :title="modalTitle" @close="selectedItemId = null" />
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination style="margin: auto" v-model="currentPage" :total-rows="rows"
              :per-page="perPage" @input="handlePageChange"></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style></style>