<style scoped>
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}
</style>
<script>
import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  page: {
    title: "Product Categories",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  validations: {
    name: {
      required,
    },
    slug: {
      required,
    },
    description: {},
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.categories.length;
    },
  },
  data() {
    return {
      categories: [],
      isAuthError: false,
      authError: "",
      showForm: false,
      currentPage: 1,
      id: null,
      name: "",
      slug: "",
      description: "",
      perPage: 10,
      categoryFormButtonName: "Create",
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      submitted: false,
      fields: [
        {
          key: "name",
          sortable: false,
        },
        {
          key: "description",
          sortable: false,
        },
        {
          key: "slug",
          sortable: false,
        },
        {
          key: "created_at",
          sortable: true,
        },

        {
          key: "actions",
          sortable: false,
        },
      ],
    };
  },

  mounted() {
    // Set the initial number of items
    this.getData();
  },
  methods: {
    confirmApproveDisapprove(approve) {
      let confirmButtonText = approve ? "Approve" : "Disapprove";
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: confirmButtonText,
      }).then((result) => {
        if (result.value) {
          this.approveDisapprove(!!approve);
        }
      });
    },

    async approveDisapprove(approve) {
      let successMessage = approve
        ? "Operator has being approved successfully."
        : "Operator has being disapproved successfully.";
      Swal.fire("Success!", successMessage, "success");
    },

    async getData() {
      this.categories = [];
      try {
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        let response = await axios.get("auth/categories");
        if (response) {
          this.categories = response.data.data;
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log("ERROR......", error);
        this.categories = [];
      }
    },

    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
    },

    categoryForm(data = null) {
      this.name = "";
      this.id = null;
      this.description = "";
      this.slug = "";
      if (data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.slug = data.slug;
      }

      if (this.id) {
        this.categoryFormButtonName = "Update";
      }
      this.showForm = !this.showForm;
    },

    confirmDelete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          this.deleteCategory(id);
        }
      });
    },

    async deleteCategory(id) {
      try {
        await axios.delete("/category/delete/" + id);
        Swal.fire("Deleted!", "Category has been deleted.", "success");
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },

    async submitForm() {
      this.isAuthError = false;
      this.authError = null;
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if (this.id) {
          let category_obj = {
            name: this.name,
            description: this.description,
            slug: this.slug,
            id: this.id,
          };
          let token = localStorage.getItem("token");
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          let response = await axios.post("category/update", category_obj);
          if (response) {
            this.setCustomValidationMessage(
              response.data.message,
              "success",
              true
            );
            this.getData();
          } else {
            this.setCustomValidationMessage(
              localStorage.getItem("error_message"),
              "danger",
              true
            );
            return;
          }
        } else {
          let category_obj = {
            name: this.name,
            description: this.description,
            slug: this.slug,
          };
          let token = localStorage.getItem("token");
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          let response = await axios.post("category/create", category_obj);
          if (response) {
            this.setCustomValidationMessage(
              response.data.message,
              "success",
              true
            );
            this.getData();
          } else {
            this.setCustomValidationMessage(
              localStorage.getItem("error_message"),
              "danger",
              true
            );
            return;
          }
        }
        this.showForm = false;
        this.name = "";
        this.description = "";
        this.slug = "";
        this.$v.$reset();
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="[]" />

    <!-- <transition name="slide-fade"> -->
    <div class="row mb-2">
      <div class="col-md-4">
        <h3>Event Categories</h3>
      </div>
      <div class="col-md-8">
        <div class="float-end">
          <div>
            <!-- <button type="button" @click="downloadCSVData" class="btn btn-info mb-3">
            <i class="mdi mdi-file me-1"></i> Export
          </button> -->
            <button
              type="button"
              @click.prevent="categoryForm(null)"
              class="btn btn-warning ms-1 mb-3"
            >
              <i class="mdi mdi-plus me-1"></i> Add Category
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- </transition> -->
    <transition name="slide-fade">
      <div v-if="showForm" class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <b-alert
                  :show="isAuthError"
                  variant="danger"
                  class="mt-3"
                  dismissible
                  >{{ authError }}</b-alert
                >

                <b-form @submit.prevent="submitForm">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="col-md-12">
                        <b-form-group
                          id="input-group-1"
                          class="mb-3"
                          label="Name"
                          label-for="input-1"
                        >
                          <b-form-input
                            id="input-1"
                            v-model="name"
                            type="text"
                            placeholder="Name"
                            :class="{
                              'is-invalid': submitted && $v.name.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="submitted && $v.name.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.name.required"
                              >Name is required.</span
                            >
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group
                          id="input-group-1"
                          class="mb-3"
                          label="Slug"
                          label-for="input-1"
                        >
                          <b-form-input
                            id="input-1"
                            v-model="slug"
                            type="text"
                            placeholder="Slug"
                            :class="{
                              'is-invalid': submitted && $v.slug.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="submitted && $v.slug.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.slug.required"
                              >Slug is required.</span
                            >
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="col-md-12">
                        <b-form-group
                          id="input-group-1"
                          class="mb-3"
                          label="Description"
                          label-for="input-1"
                        >
                          <textarea
                            :maxlength="225"
                            rows="5"
                            class="form-control"
                            id="input-1"
                            v-model="description"
                            type="text"
                            placeholder="Description"
                            :class="{
                              'is-invalid': submitted && $v.description.$error,
                            }"
                          ></textarea>
                          <div
                            v-if="submitted && $v.description.$error"
                            class="invalid-feedback"
                          ></div>
                        </b-form-group>
                      </div>
                    </div>
                  </div>

                  <div class="mt-3 text-start">
                    <b-button
                      type="submit"
                      class="me-2"
                      size="md"
                      variant="warning"
                      >{{ categoryFormButtonName }}</b-button
                    >

                    <b-button
                      type="reset"
                      class=""
                      @click.prevent="() => (showForm = false)"
                      size="md"
                      variant="secondary"
                      >Cancel</b-button
                    >
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div class="row">
      <div class="col-12">
        <div class="cards">
          <div class="cards-body">
            <div class="row">
              <div class="row">
                <!-- Search -->
                <div class="col-md-10">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-start"
                  >
                    <div class="input-group">
                      <div class="input-group-text">
                        <i class="uil-search"></i>
                      </div>
                      <b-form-input
                        v-model="filter"
                        placeholder="Search Category"
                        type="search"
                        class="form-control form-control-md px-1"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
                <!-- End search -->

                <div class="col-md-2">
                  <div
                    id="tickets-table_length"
                    class="dataTables_length text-md-start"
                  >
                    <label
                      class="d-inline-flex align-items-center fw-normal mt-1"
                    >
                      Show&nbsp;&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="lg"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;&nbsp;entries
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                table-class="table table-centered datatable table-card-list"
                thead-tr-class="bg-transparent"
                :items="categories"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(description)="data">
                  <div>
                    <span>{{
                      data.item.description ? data.item.description : "-"
                    }}</span>
                  </div>
                </template>

                <template #cell(actions)="data">
                  <div>
                    <!-- edit -->
                    <b-button
                      class="me-2"
                      @click="categoryForm(data.item)"
                      size="sm"
                      variant="success"
                      ><i class="fas fa-pen"></i
                    ></b-button>
                    <!-- delete -->
                    <b-button
                      size="sm"
                      @click.prevent="confirmDelete(data.item.id)"
                      variant="danger"
                      ><i class="fas fa-trash"></i
                    ></b-button>
                  </div>
                </template>
              </b-table>
            </div>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      style="margin: auto"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>