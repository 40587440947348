<script>
import { required, sameAs } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import store from '@/state/store';

/**
 * Login component
 */
export default {
  page: {
    title: "Reset Password",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      otp: "",
      password: "",
      confirm_password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      showSuccessMessageType: "",
      successMessage: null,
      showSuccessMessage: null,
      passwordIcon: "fa fa-eye-slash",
      passwordInputType: "password",
      confirmPasswordIcon: "fa fa-eye-slash",
      confirmPasswordInputType: "password"
    };
  },
  validations: {
    password: {
      required,
      regex: function() {
        let regexPassword = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/);
        let regexValidation = !!regexPassword.exec(this.password);
        let lengthOfPassword = this.password.length;
        return regexValidation && lengthOfPassword >= 8;
      },
      spaceCheck: function () {
        return !this.password.includes(' ');
      }
    },
    confirm_password: {
      required,
      sameAs: sameAs('password')
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    document.body.classList.add("authentication-bg");
    let params = this.$route.params;
    this.otp = params.otp;
    this.verifyUser();
  },
  methods: {

     showPassword() {
      if (this.passwordInputType == "password") {
        this.passwordInputType = "text";
        this.passwordIcon = "fa fa-eye";
      }
      else {
        this.passwordInputType = "password";
        this.passwordIcon = "fa fa-eye-slash";

      }
    },

    showConfirmPassword() {
      if (this.confirmPasswordInputType == "password") {
        this.confirmPasswordInputType = "text";
        this.confirmPasswordIcon = "fa fa-eye";
      }
      else {
        this.confirmPasswordInputType = "password";
        this.confirmPasswordIcon = "fa fa-eye-slash";

      }
    },



    async verifyUser() {
      this.isAuthError = false;
      this.authError = null;

      const { otp } = this;
      if (otp) {
        await this.$store.dispatch("authentication/verifyForResetPassword", {
          otp
        });

        if (store.getters['authentication/isValidationError']) {
          this.isAuthError = true;
          this.authError = store.getters['authentication/validationMessage'];
        }
      }
    },

    async submitForm() {
      this.isAuthError = false;
      this.authError = null;
      this.submitted = true;
      this.showSuccessMessage = null;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const { password, otp } = this;
        if (otp && password) {
          await this.$store.dispatch("authentication/resetPassword", {
            password,
            otp
          });

          if (store.getters['authentication/isValidationError']) {
            this.isAuthError = true;
            this.authError = store.getters['authentication/validationMessage'];
            this.showSuccessMessageType = "danger";
            this.showSuccessMessage = store.getters['authentication/validationMessage'];
          } else {

            if (store.getters['authentication/successMessage']) {
              this.showSuccessMessageType = "success";
              this.showSuccessMessage = store.getters['authentication/successMessage'];
            }

          }
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!-- <img src="/images/moontowerlogo.png" alt="Moon Tower Tickets" width="400" /> -->
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <router-link to="/" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/brands/moontowerlogo.png"
                  alt
                  height="70"
                  class="logo logo-dark"
                />

              </router-link>
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                </div>
                    <div class="mb-5 text-center">
                      <h3>Reset Password</h3>
                    </div>
                <div v-if="!showSuccessMessage" class="">
                  <b-alert
                    :show="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert>

                  <b-form @submit.prevent="submitForm">

                    <div class="row">
                      <div class="col-md-12">
                        <b-form-group id="input-group-2" class="mb-3">
                          <label for="input-2">New Password</label>

                          <div class="input-group">

                            <b-form-input
                              id="input-2"
                              v-model="password"
                              :type="passwordInputType"
                              placeholder="Password"
                              :class="{
                                'is-invalid': submitted && $v.password.$error,
                              }"
                            ></b-form-input>

                            <div class="input-group-text">
                                <a style="color: #000" href="javascript:void(0)" @click="showPassword">
                                    <i :class="passwordIcon"></i>
                                  </a>
                            </div>

                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >
                              New Password is required.
                            </div>
                            <div
                              v-else-if="submitted && !$v.password.spaceCheck"
                              class="invalid-feedback"
                            >
                              Password should not contain any space.
                            </div>
                            <div
                              v-else-if="submitted && !$v.password.regex"
                              class="invalid-feedback"
                            >
                              Password must be at least 8 characters long and contain at least one lowercase, one uppercase character and one special character.
                            </div>
                          </div>

                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group id="input-group-2" class="mb-3">
                          <label for="input-2">Confirm Password</label>

                           <div class="input-group">

                            <b-form-input
                              id="input-2"
                              v-model="confirm_password"
                              :type="confirmPasswordInputType"
                              placeholder="Password"
                              :class="{
                                'is-invalid': submitted && $v.confirm_password.$error,
                              }"
                            ></b-form-input>

                            <div class="input-group-text">
                                <a style="color: #000" href="javascript:void(0)" @click="showConfirmPassword">
                                    <i :class="confirmPasswordIcon"></i>
                                  </a>
                            </div>

                              <div
                                v-if="submitted && !$v.confirm_password.required"
                                class="invalid-feedback"
                              >
                                Confirm Password is required.
                              </div>

                              <div
                                v-else-if="submitted && !$v.confirm_password.sameAs"
                                class="invalid-feedback"
                              >
                                New Password and confirm password does not match.
                              </div>
                          </div>

                        </b-form-group>
                      </div>
                    </div>

                    <div class="mt-3 text-end">
                      <b-button type="submit" class="w-md" size="lg" variant="purple"
                        >Reset Password</b-button
                      >
                    </div>

                      <div class="float-end mt-3">
                        <router-link
                          to="/login"
                          class="text-muted"
                          >Go to login </router-link
                        >
                      </div>
                    <div class="mt-4 text-center">

                    </div>
                  </b-form>
                </div>
                <b-alert
                    :show="!!showSuccessMessage"
                    :variant="showSuccessMessageType"
                    class="mt-5"
                >{{ showSuccessMessage }}</b-alert
                >
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <div class="mt-5 text-center">

            </div>
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>