<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import simplebar from "simplebar-vue";

import { menuItems } from "./menu";

import sideMenuPermissions from "../locales/sidebar-menu-permission.json";

/**
 * Sidebar component
 */
export default {
  data() {
    return {
      menuItems: menuItems,
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  components: {
    simplebar,
  },
  mounted: function () {
    this.type = "compact";
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    this._activateMenuDropdown();

    this.$router.afterEach(() => {
      this._activateMenuDropdown();
    });

    
  },
  beforeMount: function () {
    this.setSideBarMenu();
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar");
              document.body.removeAttribute("data-sidebar-size");
              break;
            case "light":
              document.body.removeAttribute("data-sidebar");
              document.body.removeAttribute("data-sidebar-size");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "compact":
              document.body.setAttribute("data-sidebar-size", "medium");
              document.body.setAttribute("data-sidebar", "light");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-topbar", "dark");
              break;
            case "icon":
              document.body.setAttribute("data-keep-enlarged", "true");
              document.body.classList.add("vertical-collpsed");
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar", "dark");
              break;
            case "colored":
              document.body.setAttribute("data-sidebar", "colored");
              document.body.removeAttribute("data-keep-enlarged");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-sidebar-size");
              break;
            default:
              document.body.setAttribute("data-sidebar", "light");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal) {
        if (newVal === "boxed") {
              document.body.setAttribute("data-layout-size", "boxed");
        } else if (newVal === "fluid") {
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
        } 
        
        else {
              document.body.setAttribute("data-layout-mode", "fluid");
        }
      },
    },
    $route: {
      handler: "onRouteChange",
      immediate: true,
      deep: true,
    },
  },
  methods: {
    setSideBarMenu() {
      const user = JSON.parse(localStorage.getItem("user"));
      const permissions = sideMenuPermissions.permissions[user.role];
      this.menuItems = menuItems.filter((item) => {
        let allowed = false;
        if (permissions.includes(item.id)) {
          allowed = true;
        }
        if (item.subItems) {
          item.subItems = item.subItems.filter((subItem) =>
            permissions.includes(subItem.id)
          );
        }
        return allowed;
      });
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    hasItems(item) {
      return item.subItems?.length > 0;
    },
    _removeAllClass(className) {
      const elements = document.getElementsByClassName(className);
      while (elements[0]) {
        elements[0].classList.remove(className);
      }
    },
    _activateMenuDropdown() {
      this._removeAllClass("mm-active");
      this._removeAllClass("mm-show");

      var links = document.getElementsByClassName("side-nav-link-ref");
      var matchingMenuItem = null;
      const paths = [];

      for (var i = 0; i < links.length; i++) {
        paths.push(links[i]["pathname"]);
      }
      var itemIndex = paths.indexOf(window.location.pathname);
      if (itemIndex === -1) {
        const strIndex = window.location.pathname.lastIndexOf("/");
        const item = window.location.pathname.substr(0, strIndex).toString();
        matchingMenuItem = links[paths.indexOf(item)];
      } else {
        matchingMenuItem = links[itemIndex];
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        var parent = matchingMenuItem.parentElement;

        /**
         * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
         * We should come up with non hard coded approach
         */
        if (parent) {
          parent.classList.add("mm-active");
          const parent2 = parent.parentElement.closest("ul");
          if (parent2 && parent2.id !== "side-menu") {
            parent2.classList.add("mm-show");

            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.add("mm-active");
              var childAnchor = parent3.querySelector(".has-arrow");
              var childDropdown = parent3.querySelector(".has-dropdown");
              if (childAnchor) childAnchor.classList.add("mm-active");
              if (childDropdown) childDropdown.classList.add("mm-active");

              const parent4 = parent3.parentElement;
              if (parent4 && parent4.id !== "side-menu") {
                parent4.classList.add("mm-show");
                const parent5 = parent4.parentElement;
                if (parent5 && parent5.id !== "side-menu") {
                  parent5.classList.add("mm-active");
                  const childanchor = parent5.querySelector(".is-parent");
                  if (childanchor && parent5.id !== "side-menu") {
                    childanchor.classList.add("mm-active");
                  }
                }
              }
            }
          }
        }
      }
    },
    onRouteChange() {
      setTimeout(() => {
        const activeElement = document.querySelector(".mm-active");
        if (activeElement) {
          const position = activeElement.offsetTop;
          if (position > 500) {
            this.$refs.currentMenu.SimpleBar.getScrollElement().scrollTop =
              position + 300;
          }
        }
      }, 300);
    },
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <!-- LOGO -->
    <div class="navbar-brand-box">
      <div class="logo logo-dark">
        <span class="logo-sm">
          <router-link to="/">
            <img src="@/assets/images/brands/logo-mini.png" alt height="35" />
          </router-link>
        </span>
        <span class="logo-lg">
          <router-link to="/">
            <img src="@/assets/images/brands/logo-dark-crop.png" alt height="24"/>
          </router-link>
        </span>
      </div>

      <div class="logo logo-light">
        <span class="logo-sm">
          <router-link to="/">
            <img src="@/assets/images/brands/logo-mini.png" alt height="22" />
          </router-link>
        </span>
        <span class="logo-lg">
          <router-link to="/">
            <img src="@/assets/images/logo-light.png" alt height="20" />
          </router-link>
        </span>
      </div>
    </div>

    <button
      type="button"
      @click="toggleMenu"
      class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
    >
      <i class="fa fa-fw fa-bars"></i>
    </button>

    <simplebar class="sidebar-menu-scroll" ref="currentMenu">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu">
          <li v-for="item in menuItems" :key="item.id">
            <div v-if="item.isTitle" class="menu-title">
              {{ $t(item.label) }}
            </div>
            <div v-else>
              <!-- Internal Navigation -->
              <router-link
                v-if="!item.subItems && !item.target"
                :to="item.link"
                class="is-parent main-side-menu side-nav-link-ref"
              >
                <i :class="`${item.icon}`" v-if="item.icon"></i>
                <span>{{ $t(item.label) }}</span>
              </router-link>
              <!-- External Link -->
              <a
                v-if="!item.subItems && item.target"
                :href="item.link"
                :target="item.target"
                :rel="item.rel || null"
                class="is-parent main-side-menu side-nav-link-ref"
              >
                <i :class="`${item.icon}`" v-if="item.icon"></i>
                <span>{{ $t(item.label) }}</span>
              </a>
              <!-- Submenu -->
              <a
                v-if="item.subItems"
                href="javascript:void(0);"
                class="is-parent main-side-menu"
                :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
              >
                <i :class="`${item.icon}`" v-if="item.icon"></i>
                <span
                  :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
                  v-if="item.badge"
                >{{ $t(item.badge.text) }}</span>
                <span>{{ $t(item.label) }}</span>
              </a>
              <ul v-if="item.subItems" class="sub-menu" aria-expanded="false">
                <li v-for="subitem in item.subItems" :key="subitem.id">
                  <router-link
                    v-if="!subitem.target"
                    :to="subitem.link"
                    class="side-nav-link-ref"
                  >
                    {{ $t(subitem.label) }}
                  </router-link>
                  <a
                    v-if="subitem.target"
                    :href="subitem.link"
                    :target="subitem.target"
                    :rel="subitem.rel || null"
                    class="side-nav-link-ref"
                  >
                    {{ $t(subitem.label) }}
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <!-- Sidebar -->
    </simplebar>
  </div>
  <!-- Left Sidebar End -->
</template>