import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
export const state = user
    ? { status: { loggeduser: true }, user, validationError: null, successMessage: null }
    : { status: {}, user: null, validationError: null, successMessage: null };

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.user
    },

    isValidationError(state) {
        return !!state.validationError
    },

    validationMessage(state) {
        return state.validationError
    },

    successMessage(state) {
        return state.successMessage
    }
}
export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    async login({ dispatch, commit }, { email, password }) {

        try {
            let loginData = await axios.post('auth/login', {'email': email, 'password': password, 'from': true});
            if (loginData) {
                localStorage.removeItem("error_message");
                commit('loginSuccess', loginData);
            } else {
                commit('validationError', localStorage.getItem('error_message'));
            }

        } catch (error) {
            console.log("Login Error: ", error.message);
        }

    },

    updateUserInfo({ commit }, responseData) {
        commit('loginSuccess', responseData);
    },

    async register({ commit }, { first_name, last_name, email, password, contact_number, company_name }) {

        try {
            let registerData = await axios.post('auth/signup', {
                'first_name': first_name,
                "last_name": last_name,
                'email': email,
                'password': password,
                'role': "operator",
                'company_name': company_name,
                'contact_number': contact_number
            });
            if (registerData) {
                localStorage.removeItem("error_message");
                commit('clearValidationError');
            } else {
                commit('validationError', localStorage.getItem('error_message'));

            }

        } catch (error) {
            console.log("Login Error: ", error.message);
        }

    },

    //send otp for reset password
    async sendVerificationLink({ commit }, { email }) {
        localStorage.removeItem("error_message");
        let response = await axios.post('auth/forgot-password', {'email': email});

        if (!response) {
            commit('validationError', localStorage.getItem('error_message'));
        } else {
            commit('clearValidationError');
            localStorage.removeItem("error_message");
            commit('successMessage', response.data.message);
        }

    },

    //verify otp for reset password
    async verifyOtp({ commit }, { email, otp }) {
        localStorage.removeItem("error_message");
        let response = await axios.post('auth/verify-otp', {'email': email, 'otp': otp});

        if (!response) {
            commit('validationError', localStorage.getItem('error_message'));
        } else {
            commit('clearValidationError');
            localStorage.removeItem("error_message");
        }

    },

    async verifyForResetPassword({ commit }, { otp }) {
        localStorage.removeItem("error_message");
        let response = await axios.post('auth/verify-reset-password', {'otp': otp});
        if (!response) {
            commit('validationError', localStorage.getItem('error_message'));
        } else {
            commit('clearValidationError');
            localStorage.removeItem("error_message");
        }

    },

    // reset password
    async resetPassword({ commit }, { password, otp }) {
        localStorage.removeItem("error_message");
        let response = await axios.post('auth/reset-password', {'password': password, 'otp': otp});

        if (!response) {
            commit('validationError', localStorage.getItem('error_message'));
        } else {
            commit('clearValidationError');
            localStorage.removeItem("error_message");
            commit('successMessage', response.data.message);
        }
    },

    // Logout the user
    async logout({ commit }) {
         try {

            let token = localStorage.getItem('token');
            axios.defaults.headers.common['Authorization'] = 'Bearer '+ token;
            await axios.get('logout');
            localStorage.removeItem('user');
            localStorage.removeItem("token");
        } catch (error) {
            console.log("Error : ", error.message);
            localStorage.removeItem('user');
            localStorage.removeItem("token");
        }
        commit('logout');
    },

    // Logout the user locally
    async LogOutLocally({ commit }) {
         try {
            localStorage.removeItem('user');
            localStorage.removeItem("token");
        } catch (error) {
            console.log("Error : ", error.message);
            localStorage.removeItem('user');
            localStorage.removeItem("token");
        }
        commit('logout');
    },
};

export const mutations = {
    loginRequest(state, data) {
        state.status = { loggingIn: true };
        state.user = data.data.data.user;
    },
    loginSuccess(state, data) {
        state.status = { loggeduser: true };
        state.user = data.data.data.user;
        state.validationError = null;
        localStorage.setItem('user', JSON.stringify(data.data.data.user));
        localStorage.setItem('token', data.data.data.accessToken);
    },
    successMessage(state, message) {
        state.successMessage = message;
    },
    validationError(state, message) {
        state.status = {};
        state.user = null;
        state.validationError = message;
    },
    clearValidationError(state) {
        state.validationError = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
        state.validationError = null;
        state.successMessage = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};
