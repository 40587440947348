<script>

import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import axios from "axios";

/**
 * Add-product component
 */
export default {
    page() {
        return {
            title: "Design Chart",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        }
    },
    components: {
        Layout,
    },
    data() {
        return {
            user: { role: null },
            title: "Design Chart",
            key: '',
            secretKey: process.env.VUE_APP_SEATSIO_SECRET_KEY,
            pageloader: false,
            event: ''
        };
    },

    watch: {
        pageloader() {
            if (this.pageloader) {
                this.$store.dispatch("chartloader/showChartLoader");
            } else {
                this.$store.dispatch("chartloader/hideChartLoader");
            }
        }
    },

    methods: {
        // eslint-disable-next-line no-unused-vars
        designerRendered(designer) {
            this.pageloader = false;
        },

        // eslint-disable-next-line no-unused-vars
        chartRendered(chart) {
            this.pageloader = false;
        },
        async chartUpdated(key) {
            await axios.post("charts/update/details", {
                key
            });
        },

        async renderChart() {
            this.pageloader = true;
            this.key = this.$route.params.key;
        },
    },

    mounted() {
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        this.user = JSON.parse(localStorage.getItem("user"));
        this.renderChart();
    },

    middleware: "authentication",
};
</script>

<template>
    <Layout>
        <div class="col-lg-12">
            <seatsio-chart-designer v-if="key" :onChartUpdated="chartUpdated" :onDesignerRendered="designerRendered" id="chart-create" :chartKey="key"
                :secretKey="secretKey" />
            <seatsio-event-manager v-else-if="event" id="chart-create" :event="event"
                :secretKey="secretKey" :onChartRendered="chartRendered"/>
            <div v-else class="text-center text-theme m-auto loader">
                <div class="spinner">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Loading...</strong>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style>
.loader {
    position: relative;
    height: 200px;
}

.loader .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
}
</style>