<script>
import { layoutComputed, notificationComputed, chartLoaderComputed } from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";

/**
 * Main Layout
 */
export default {
  components: {
    Vertical,
    Horizontal,
  },
  data() {
    return {};
  },
  computed: {
    ...chartLoaderComputed,
    ...layoutComputed,
    ...notificationComputed,
  },
  watch: {
    notificationNotify: function () {
      if (this.notificationNotify) {
        if (this.notificationType === "success") {
          this.$toastr.s(this.notificationMessage, "Success");
        } else if (this.notificationType === 'info') {
          this.$toastr.i(this.notificationMessage);
        } else {
          this.$toastr.e(this.notificationMessage, "Alert");
        }
      }
    },
  },
  mounted() {
    document.body.classList.remove("authentication-bg");
  },
  methods: {},
};
</script>

<template>
  <div>
    <div class="chart-page-loader text-center" v-if="chartLoader">
      <div>
        <h1 class="ms-2 text-white"> Chart loading...</h1>
        <p class="fs-4">This may take a few seconds, please don't close this page.</p>
        <b-spinner small variant="secondary" class="m-2" type="grow" label="Loading..."></b-spinner>
        <b-spinner small variant="secondary" class="m-2" type="grow" label="Loading..."></b-spinner>
        <b-spinner small variant="secondary" class="m-2" type="grow" label="Loading..."></b-spinner>
      </div>
    </div>
    <Vertical v-if="layoutType === 'vertical'" :layout="layoutType">
      <slot />
    </Vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>
  </div>
</template>
<style scoped>
.chart-page-loader {
  background: rgb(25, 25, 25);
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
  z-index: 9999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>