<script>
import appConfig from "@/app.config";
import axios from "axios";
// import store from '@/state/store';

/**
 * Login component
 */
export default {
  page: {
    title: "User Activation",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      name: "",
      authError: null,
      isAuthError: false,
      successMessage: null,
      code: null,
    };
  },

  computed: {

  },
  beforeMount() {
    let params = this.$route.params;
    this.code = params.code;
    this.activateUser();
  },
  mounted() {

  },
  methods: {
    async activateUser() {
      this.name = "";
      this.isAuthError = false;
      this.authError = null;
      let response = await axios.post("/auth/verify-account/", { code: this.code });
      if (response) {
        this.name = response.data.data.user.name;
        this.successMessage = response.data.message;
      } else {
        this.isAuthError = true;
        this.authError = localStorage.getItem("error_message");
      }
    }
  },
};
</script>

<template>
  <div>

    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!-- <img src="/images/moontowerlogo.png" alt="Moon Tower Tickets" width="400" /> -->
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <a href="https://moontowertickets.com" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/brands/moontowerlogo.png"
                  alt
                  height="70"
                  class="logo logo-dark"
                />

            </a>
            <div class="card">
              <div class="card-body p-4">

                <div class="p-2">

                  <b-alert
                    :show="isAuthError"
                    variant="danger"
                    class="mt-3"
                    >{{ authError }}</b-alert>
                  <b-alert
                    :show="!!successMessage"
                    variant="success"
                    class="mt-3"
                    >
                    <div>
                      Hello {{name}},
                    </div>
                    <div>
                      {{ successMessage }}
                    </div>

                    </b-alert
                  >
                </div>
                <!-- end card-body -->
                <div class="text-center">
                  <a href="https://moontowertickets.com/login" class="btn btn-primary">Login</a>
                </div>
              </div>
              <!-- end card -->
            </div>
            <div class="mt-5 text-center">

            </div>
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>

body {
background-image: linear-gradient(
142deg
,rgba(138,37,252,0.61) 0%,#26033f 100%),url('/images/moontowerbg.jpg')!important;
  height: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

</style>
