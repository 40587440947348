<script>
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import store from "@/state/store";

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      passwordIcon: "fa fa-eye-slash",
      passwordInputType: "password",
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    document.body.classList.add("authentication-bg");
  },
  methods: {
    showPassword() {
      if (this.passwordInputType == "password") {
        this.passwordInputType = "text";
        this.passwordIcon = "fa fa-eye";
      } else {
        this.passwordInputType = "password";
        this.passwordIcon = "fa fa-eye-slash";
      }
    },

    async submitForm() {
      this.isAuthError = false;
      this.authError = null;
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const { email, password } = this;
        if (email && password) {
          await this.$store.dispatch("authentication/login", {
            email,
            password,
          });

          if (store.getters["authentication/isValidationError"]) {
            this.isAuthError = true;
            this.authError = store.getters["authentication/validationMessage"];
          } else {
            this.$router.push(
              this.$route.query.redirectFrom || {
                path: "/",
              }
            );
          }
        }
      }
    },
  },
};
</script>

<template>
  <div class="auth">
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!-- <img src="/images/moontowerlogo.png" alt="Moon Tower Tickets" width="400" /> -->
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <router-link to="/" class="mb-5 d-block auth-logo">
              <img
                src="@/assets/images/brands/moontowerlogo.png"
                alt
                height="70"
                class="logo logo-dark"
              />
            </router-link>
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h3>Admin and Promoter Login</h3>
                </div>
                <div class="p-2 mt-4">
                  <b-alert
                    :show="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >

                  <b-form @submit.prevent="submitForm">
                    <b-form-group
                      id="input-group-1"
                      class="mb-3"
                      label="Email"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="email"
                        type="text"
                        placeholder="Email"
                        :class="{ 'is-invalid': submitted && $v.email.$error }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.email.required"
                          >Email is required.</span
                        >
                        <span v-if="!$v.email.email"
                          >Please enter valid email.</span
                        >
                      </div>
                    </b-form-group>

                    <b-form-group id="input-group-2" class="mb-3">
                      <label for="input-2">Password</label>

                      <div class="input-group">
                        <b-form-input
                          id="input-2"
                          v-model="password"
                          :type="passwordInputType"
                          placeholder="Password"
                          :class="{
                            'is-invalid': submitted && $v.password.$error,
                          }"
                        ></b-form-input>
                        <div class="input-group-text">
                          <a
                            style="color: #000"
                            href="javascript:void(0)"
                            @click="showPassword"
                          >
                            <i :class="passwordIcon"></i>
                          </a>
                        </div>
                        <div
                          v-if="submitted && !$v.password.required"
                          class="invalid-feedback"
                        >
                          Password is required.
                        </div>
                      </div>
                    </b-form-group>
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="mt-3 text-center">
                      <b-button
                        type="submit"
                        class="w-md fw-bold"
                        size="md"
                        variant="purple"
                        >Login</b-button
                      >
                    </div>
                    <div class="text-end mt-3">
                      <router-link to="/forgot-password" class="text-muted fw-bold"
                        >Forgot password?</router-link
                      >
                    </div>

                    </div>
                    

                    <div class="text-center mt-4">
                      <router-link to="/register" class="text-warning"
                        ><b-button
                        type="submit"
                        class="w-xl"
                        size="md"
                        variant="warning"
                        >Create an event</b-button
                      >
                      </router-link>
                    </div>
                    <!-- <div class="mt-4 text-center"></div> -->
                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <div class="mt-5 text-center"></div>
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" scopped>
</style>
