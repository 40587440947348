// for event listing table

export const listingFields = [
  {
    id: 1,
    key: "featured_image",
    label: "",
  },
  {
    id: 2,
    key: "formatted_title",
    label: "Name",
    sortable: true,
  },
  {
    id: 3,
    key: "post_start_date",
    label: "Date",
    sortable: true,
  },
  {
    id: 4,
    key: "available_quantity",
    label: "Tickets Left",
  },
  {
    id: 12,
    key: "category_primary",
    label: "Category",
  },
  {
    id: 5,
    key: "ticket_price_web",
    label: "Price",
    // sortable: true,
  },
  {
    id: 6,
    key: "gross_sales",
    label: "Gross Sales",
  },
  {
    id: 13,
    key: "net_sales",
    label: "Net Sales",
  },
  {
    id: 7,
    key: "total_tickets",
    label: "Tickets Sold",
  },
  {
    id: 8,
    key: "operator.name",
    label: "Creator",
  },
  {
    id: 11,
    key: "workers",
    label: "Team Members",
  },
  {
    id: 9,
    key: "status",
    label: "Status",
  },
  {
    id: 10,
    label: "Actions",
    key: "action",
  },
//  {
//    id: 11,
//    key: "copy_to_clipboard",
//    icon: 'fa fa-link',
//    label: "",
//  },
  {
    id: 15,
    key: "web_url",
    label: "Web URL",
  }
];

//for tax listing table

export const taxListingFields = [
  {
    id: 1,
    key: "name",
    sortable: true,
  },
  {
    id: 2,
    key: "fees",
    label: "Tax %",
    sortable: true,
  },
  {
    id: 3,
    key: "is_active",
    sortable: false,
  },
  {
    id: 4,
    key: "actions",
    sortable: false,
  },
];
