import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import router from "./router";

import vco from "v-click-outside";
import VueApexCharts from "vue-apexcharts";
import VueSweetalert2 from "vue-sweetalert2";
import VueSlideBar from "vue-slide-bar";
import Vuelidate from "vuelidate";
import i18n from "./i18n";
import store from "@/state/store";
import axios from "axios";
import VueToastr from "vue-toastr";

axios.defaults.withCredentials = false;
axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASEURL;

axios.defaults.headers.common["Content-Type"] = "application/json";

axios.interceptors.response.use(
  function(response) {
    localStorage.removeItem("error_message");
    return response;
  },
  function(error) {
    let originalRequest = error.config;
    if (error.response.status == 401 && !originalRequest._retry) {
      originalRequest._retry = false;
      store.dispatch("authentication/LogOutLocally");
      return router.push("/login");
    } else {
      if (error) {
        localStorage.setItem("error_message", error.response.data.message);
        const originalRequest = error.config;
        if (error.response.success === false && !originalRequest._retry) {
          originalRequest._retry = false;
        }
      }
    }
  }
);

// general function
Vue.mixin({
  methods: {
    setCustomValidationMessage(message, type, notify) {
      setTimeout(() => {
        store.dispatch("notification/clear");
      }, 2000);

      store.dispatch("notification/validationMessage", {
        message,
        type,
        notify,
      });
    },

    androidLink() {
      return process.env.VUE_APP_ANDROID_SCAN_APP_URL;
    },

    iosLink() {
      return process.env.VUE_APP_IOS_SCAN_APP_URL;
    },

    convertHTMLToText(value) {
      const div = document.createElement("div");
      div.innerHTML = value;
      const text = div.textContent || div.innerText || "";
      return text;
    },
  },
});

import App from "./App.vue";
// As a plugin
import VueMask from "v-mask";
import { VueMaskFilter } from "v-mask";

Vue.config.productionTip = false;

import * as VueGoogleMaps from "vue2-google-maps";
import Lightbox from "vue-easy-lightbox";
var moment = require("moment");

Vue.use(Lightbox);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: "places",
  },
  installComponents: true,
});

import "@/assets/scss/app.scss";

Vue.component("VueSlideBar", VueSlideBar);
Vue.use(BootstrapVue);
Vue.use(vco);
Vue.component("apexchart", VueApexCharts);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);

Vue.use(require("vue-chartist"));
Vue.use(VueMask);

Vue.filter("VMask", VueMaskFilter);

Vue.filter("formatDate", (value) => {
  if (!value) return "";
  return moment(value, "MM/DD/YYYY HH:mm:ss").format("MM/DD/YYYY");
});

Vue.filter("chartVenueType", (value) => {
  return value == "MIXED" ? "Without sections" : "With sections and floors";
});

Vue.filter("descriptionFilter", (value) => {
  if (!value) return "-";
  if (RTCSessionDescription.length > 15) {
    value.substr(0, 15) + "...";
  } else {
    return value;
  }
});

Vue.filter("dateRangeFormat", (date) => {
  if (!date) {
    return date;
  }
  return new Intl.DateTimeFormat("en-US").format(date);
});

Vue.filter("priceFilter", (value, category = null) => {
  if (!category || category == "general-admission") {
    if (!value) return "$0.00";
    return "$" + parseFloat(value).toFixed(2);
  } else {
    return "-";
  }
});

Vue.filter("percentageFilter", (value) => {
  if (!value) return "0.00%";
  return parseFloat(value).toFixed(2) + "%";
});

Vue.filter("capitalFilter", (value) => {
  if (!value) return "";
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("categoryFilter", (value) => {
  if (!value) return "";
  value =
    value == "general-admission"
      ? "Single Ticket"
      : value == "seatsio"
      ? "Seating Chart"
      : "Multiple Ticket";

  return value;
});

Vue.filter("formatMonthOnly", (value) => {
  if (!value) return "";
  return moment(value, "MM-DD-YYYY hh:mm").format("MMM");
});

Vue.filter("taxInfo", (value, category) => {
  if (!value) return "";
  return value + " per " + category;
});

Vue.filter("timeZoneInfo", (timezone_name, timezone_abbreviation) => {
  if (!timezone_name) return "";
  return timezone_name + " (" + timezone_abbreviation + ")";
});

Vue.use(VueToastr);

import seatsioChartDesigner from "./components/widgets/seatsioChartDesigner.vue";

Vue.component("seatsio-chart-designer", seatsioChartDesigner);

import seatsioEventManager from "./components/widgets/seatsioEventManager.vue";

Vue.component("seatsio-event-manager", seatsioEventManager);

import seatsioEventBooking from "./components/widgets/seatsioEventBooking.vue";

Vue.component("seatsio-event-booking", seatsioEventBooking);

import seatsioEventStatic from "./components/widgets/seatsioEventStatic.vue";

Vue.component("seatsio-event-static", seatsioEventStatic);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
