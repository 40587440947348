<script>
import vue2Dropzone from "vue2-dropzone";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment-timezone";
// import Swal from "sweetalert2";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, maxLength } from "vuelidate/lib/validators";
import axios from "axios";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

import workerInfo from "@/components/widgets/workerInfo";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Create Event",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    vueDropzone: vue2Dropzone,
    ckeditor: CKEditor.component,
    Layout,
    Multiselect,
    PageHeader,
    DatePicker,
    workerInfo,
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    let token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  },
  data() {
    return {
      user: { role: null },
      title: "Add Event",
      search_worker: '',
      //bonus_amount: 0,
      workers: [],
      items: [],
      timezones: [],
      ageGroups: [],
      ageGroup: null,
      taxesGroup: null,
      timeZone: null,
      showForm: {
        form1: true,
        form2: false,
        form3: false,
      },
      dropdownIcon: {
        form1: "mdi-chevron-up",
        form2: "mdi-chevron-down",
        form3: "mdi-chevron-down"
      },
      editField: {
        venue: true,
        street: true,
        city: true,
        state: true,
        postcode: true,
        country: true,
        latitude: true,
        longitude: true,
      },
      dropzoneOptions: {
        url: process.env.VUE_APP_AXIOS_BASEURL + 'auth/upload-event-image',//"https://httpbin.org/post",
        acceptedFiles: ".jpeg,.jpg,.png",
        thumbnailWidth: 360,
        thumbnailHeight: 480,
        addRemoveLinks: true,
        includeStyling: true,
        maxFilesize: 5,
        maxFiles: 1,
      },
      adDropzoneOptions: {
        url: process.env.VUE_APP_AXIOS_BASEURL + 'auth/upload-event-image',//"https://httpbin.org/post",
        acceptedFiles: ".jpeg,.jpg,.png",
        thumbnailWidth: 360,
        thumbnailHeight: 480,
        addRemoveLinks: true,
        includeStyling: true,
        maxFilesize: 5,
        maxFiles: 1
      },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
        ],
      },
      file: null,
      adFile: null,
      name: null,
      description: null,
      artist_name: null,
      artist_detail: null,
      status: null,
      image: null,
      adImage: null,
      venue: null,
      street: null,
      city: null,
      state: null,
      postcode: null,
      country: null,
      latitude: null,
      longitude: null,
      start_date: null,
      start_time: null,
      sale_start_date: null,
      sale_end_date: null,
      end_date: null,
      end_time: null,
      regular_price: null,
      sale_price: null,
      taxes: [],
      tax_options: [],
      category: "general-admission",
      type: 'paid',
      quantity_step: 1,
      stock_quantity: null,
      min_quantity: 1,
      max_quantity: null,
      currentPlace: null,
      numberOfPrices: 1,
      specialPrices: [],
      account_name: null,
      bank_name: null,
      routing_number: null,
      account_number: null,
      door_open_time: null,
      charts: [],
      chart_options: [],
      chart: null,
      canCreateChart: false,
      chart_thumbnail: null,
      isConfirmBtnDisabled: false,
      chartKey: '',
      secretKey: process.env.VUE_APP_SEATSIO_SECRET_KEY,
      priceBlock: false,
      pageloader: false,
      priceBlockLoader: false,
      isEventManager: false,
      isEventBooking: false,
      isEventStatic: false,
      eventKey: '',
    };
  },
  watch: {
    chart() {
      if (this.chart) {
        this.chart_thumbnail = this.charts[this.chart.key];
      } else {
        this.chart_thumbnail = null;
      }
    },

    category() {
        console.log("this.category",this.category);
      if (this.category == 'special') {
        this.priceBlock = true;
      } else {
        this.priceBlock = false;
      }
    },
    pageloader() {
      if (this.pageloader) {
        this.$store.dispatch("chartloader/showChartLoader");
      } else {
        this.$store.dispatch("chartloader/hideChartLoader");
      }
    }
  },
  validations: {
    name: {
      required,
    },
    currentPlace: {
      required,
    },
    timeZone: {
      required,
    },
    ageGroup: {
      required,
    },
    taxesGroup: {
      required: function (value) {
        if(this.user.role == 'super-admin' && this.type != 'free'){
          if(value){
            return true;
          }
          return false;
        }
        return true;
      }
    },
    venue: {
      required,
    },
    street: {
      required,
    },
    city: {
      required,
    },
    state: {
      required,
    },
    postcode: {
      required,
    },
    country: {
      required,
    },
    latitude: {
      required,
    },
    longitude: {
      required,
    },
    start_date: {
      required,
    },
    start_time: {
      required,
    },
    end_date: {
      required,
      sameOrAfter: function () {
        return moment(this.end_date,'MM/DD/YYYY') >= moment(this.start_date,'MM/DD/YYYY');
      },
    },
    end_time: {
      required,
      sameOrAfterTime: function () {
        if (this.start_date == this.end_date) {
          return this.start_time < this.end_time;
        } else {
          return true;
        }
      },
      pastTime: function () {
        let timezone = this.timeZone;
        if (!timezone) {
          return false;
        }
        timezone = this.timeZone.timezone_name;
        if (this.end_date && this.end_time) {
          let event_end = moment(this.end_date + " " + this.end_time, 'MM/DD/YYYY HH:mm').tz(timezone);
          let now = moment().tz(timezone).format('MM/DD/YYYY HH:mm');
          return !moment(event_end).tz(timezone).isSameOrBefore(now);
        }
        return true;
      }
    },
    door_open_time: {
      required,
    },
    regular_price: {
      required : function () {
        if (this.category != 'general-admission') {
          return true;
        }
        if (this.type != 'free'){
          if (this.regular_price){
            return !!(this.regular_price.toString().trim());
          }
          return false;
        } else {
          return true;
        }
      },
      numeric: function () {
        if (this.category != 'general-admission') {
          return true;
        }
        if (this.type != 'free'){
          return !isNaN(this.regular_price) && this.regular_price > 0 && !this.regular_price.toString().includes(' ');
        }
        return true;
      },
      maxLength: maxLength(5),
    },
    sale_price: {
      required: function () {
        if (this.category != 'general-admission') {
          return true;
        }
        if (this.type != 'free'){
          if (this.sale_start_date || this.sale_end_date){
            if (this.sale_price) {
              return !!(this.sale_price.toString().trim());
            }
            return false;
          }
          return true;
        }
        return true;
      },
      numeric: function () {
        if (this.category != 'general-admission') {
          return true;
        }
        if (this.sale_price) {
          console.log("aa",this.sale_price);
          // return true;
          return !isNaN(this.sale_price) && this.sale_price >= 0 && !this.sale_price.toString().includes(' ');
        } else {
          return true;
        }
      },
      maxLength: maxLength(5),
    },
    // bonus_amount: {
    //   required: function () {
    //     if (this.category != 'general-admission') {
    //       return true;
    //     }
    //     if (this.type != "free") {
    //       if (this.bonus_amount) {
    //         return !!this.bonus_amount.toString().trim();
    //       }
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   },
    //   numeric: function () {
    //     if (this.category != 'general-admission') {
    //       return true;
    //     }
    //     if (this.type != "free") {
    //       return (
    //         !isNaN(this.bonus_amount) &&
    //         this.bonus_amount > 0 &&
    //         !this.bonus_amount.toString().includes(" ")
    //       );
    //     }
    //     return true;
    //   },
    //   maxLength: maxLength(5),
    // },
    sale_start_date: {
      required: function() {
        if (this.type != 'free'){
          if (this.sale_end_date || this.sale_price) {
            if (this.sale_start_date) {
              return true;
            }
            return false;
          }
          return true;
        }
        return true;
      }
    },
    sale_end_date: {
      required: function() {
        if (this.type != 'free'){
            if (this.sale_start_date || this.sale_price) {
              if (this.sale_end_date) {
                return true;
              }
              return false;
            }
            return true;
        }
        return true;
      },
      sameOrAfter: function () {
        let timezone = this.timeZone;
        if (!timezone) {
          return false;
        }
        timezone = this.timeZone.timezone_name;
        if (this.sale_start_date) {
          return moment(this.sale_end_date,'MM/DD/YYYY').tz(timezone) >= moment(this.sale_start_date,'MM/DD/YYYY').tz(timezone);
        } else {
          return true;
        }
      },
    },
    category: {
      required,
    },
    type: {
      required,
    },
    quantity_step: {
      required: function(value) {
        if (this.category != 'general-admission') {
          return true;
        }

        return !!value;
      },
      numeric: function () {
        if (this.category != 'general-admission') {
          return true;
        }
        return (/^[0-9]{0,1}[1-9]\d*$/.test(this.quantity_step));
      },
      maxLength: maxLength(5),
    },
    stock_quantity: {
      required: function (value) {
        if (this.category != 'general-admission') {
          return true;
        }
        if (value == '0') {
          return true;
        }
        return !!value;
      },
      numeric: function (value) {
        if (this.category != 'general-admission') {
          return true;
        }
        console.log(value)
        if (value == '0') {
          return true;
        }
        return (/^[0-9]{0,1}[1-9]\d*$/.test(this.stock_quantity));
      },
      maxLength: maxLength(5),
    },
    min_quantity: {
      required: function (value) {
        if (this.category != 'general-admission') {
          return true;
        }
        return !!value;
      },
      numeric: function () {
        if (this.category != 'general-admission') {
          return true;
        }
        return (/^[0-9]{0,1}[1-9]\d*$/.test(this.min_quantity));
      },
      maxLength: maxLength(5),
    },
    max_quantity: {
      required: function (value) {
        if (this.category != 'general-admission') {
          return true;
        }
        return !!value;
      },
      numeric: function () {
        if (this.category != 'general-admission') {
          return true;
        }
        return (/^[0-9]{0,1}[1-9]\d*$/.test(this.max_quantity));
      },
      checkMin: function() {
        if (this.category != 'general-admission') {
          return true;
        }
        if (this.min_quantity) {
          return parseInt(this.max_quantity) >= parseInt(this.min_quantity);
        } else {
          return true;
        }
      },
      maxLength: maxLength(5),
    },
    specialPrices: {
        $each: {
            name: {
              required: function (value) {
                if (this.category == 'general-admission') {
                  return true;
                }
                return !!value;
              },
            },
            regular_price: {
              required(value) {
                if (this.category === 'general-admission') {
                  return true;
                }

                if (this.type === 'free') {
                  return true;
                }

                if (!value) {
                  return false;
                }

                const isValidNumber = !isNaN(value) && value >= 0 && !value.toString().includes(' ');

                if (this.category === 'special' || this.category === 'seatsio') {
                  return isValidNumber;
                }

                return this.type !== 'free' && isValidNumber;
              },
              numeric(value) {
                if (this.category === 'general-admission') {
                  return true;
                }
                if (this.type === 'free') {
                  return true;
                }
                if (!value) {
                  return false;
                }

                const isValidNumber = !isNaN(value) && value >= 0 && !value.toString().includes(' ');

                if (this.category === 'special' || this.category === 'seatsio') {
                  return isValidNumber;
                }

                return this.type !== 'free' && isValidNumber;
              },
              maxLength: maxLength(5),
            },
            sale_price: {
              required: function (value) {
                if (this.category == 'general-admission') {
                  return true;
                }
                if (this.type != 'free'){
                  if (this.sale_start_date || this.sale_end_date){
                    if (value) {
                      return !!(value.toString().trim());
                    }
                    return false;
                  }
                  return true;
                }
                return true;
              },
              numeric: function (value) {
                if (this.category == 'general-admission') {
                  return true;
                }
                if (value) {
                  if (this.type != 'free' && this.category !='special' && this.category !='seatsio'){
                    return !isNaN(value) && value >= 0 && !value.toString().includes(' ');
                  }
                  if (this.type != 'free' && this.category =='special'){
                    return !isNaN(value) && value >= 0 && !value.toString().includes(' ');
                  }
                  if (this.type != 'free' && this.category =='seatsio'){
                    return !isNaN(value) && value >= 0 && !value.toString().includes(' ');
                  }
                } else {
                  return true;
                }
              },
              maxLength: maxLength(5),
            },
            quantity_step: {
              required: function(value) {
                if (this.category == 'general-admission') {
                  return true;
                }
                return !!value;
              },
              numeric: function (value) {
                if (this.category == 'general-admission') {
                  return true;
                }
                return (/^[0-9]{0,1}[1-9]\d*$/.test(value));
              },
              maxLength: maxLength(5),
            },
            stock_quantity: {
              required: function(value) {
                if (this.category == 'general-admission') {
                  return true;
                }
                if (value == '0') {
                  return true
                }
                return !!value;
              },
              numeric: function (value) {
                if (this.category == 'general-admission') {
                  return true;
                }
                if (value == '0') {
                  return true
                }
                return (/^[0-9]{0,1}[1-9]\d*$/.test(value));
              },
              maxLength: maxLength(5),
            },
            min_quantity: {
              required: function(value) {
                if (this.category == 'general-admission') {
                  return true;
                }
                return !!value;
              },
              numeric: function (value) {
                if (this.category == 'general-admission') {
                  return true;
                }
                return (/^[0-9]{0,1}[1-9]\d*$/.test(value));
              },
              maxLength: maxLength(5),
            },
            max_quantity: {
              required: function(value) {
                if (this.category == 'general-admission') {
                  return true;
                }
                return !!value;
              },
              numeric: function (value) {
                if (this.category == 'general-admission') {
                  return true;
                }
                return (/^[0-9]{0,1}[1-9]\d*$/.test(value));
              },
              checkMin: (function(value, prop) {
                if (this.category == 'general-admission') {
                  return true;
                }
                if (prop.min_quantity) {
                  return parseInt(value) >= parseInt(prop.min_quantity);
                } else {
                  return true;
                }
              }),
              maxLength: maxLength(5),
            },
        }
    },
    account_name: {},
    bank_name: {},
    routing_number: {
      numeric: function () {
        if (!this.routing_number) {
          return true;
        }
        return (/^\d+$/.test(this.routing_number));
      },
    },
    account_number: {
      numeric: function () {
        if (!this.account_number) {
          return true;
        }
        return (/^\d+$/.test(this.account_number));
      },
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    designerRendered(designer) {
      this.pageloader = false;
    },
    // eslint-disable-next-line no-unused-vars
    chartRendered(chart) {
            this.pageloader = false;
    },
    async confirmChart() {
      this.pageloader = true;
      // on confirm call clone api and render the chart
      this.isConfirmBtnDisabled = true;
      this.chartKey = null;
      if (this.chart.key != -1) {
        // api for cloning the chart
        const response = await axios.post('chart/clone', {
          key: this.chart.key
        });

        if (response) {
          const chartKey = response.data.data;
          this.isConfirmBtnDisabled = false;
          this.chartKey = chartKey;
        } else {
          this.pageloader = false;
        }
      } else {
        this.isConfirmBtnDisabled = false;
        this.chartKey = this.chart.key;
      }
    },

    async syncChartChanges() {
      this.priceBlockLoader = true;
      // on sync chart get prices for the chart rendered
      const response = await axios.post('chart/prices', {
          key: this.chartKey,
        });
      this.priceBlockLoader = false;
      if (response) {
        const data = response.data.data;
        this.specialPrices = data.map((item) => {
          return {
            category_key: item.category_key,
            name: item.name,
            regular_price: item.regular_price,
            min_quantity: 1,
            quantity_step: 1,
            max_quantity: item.max_quantity,
            stock_quantity: item.stock_quantity,
            sale_price: item.sale_price,
            seats: item.seats,
            initial_quantity: item.initial_quantity,
          }
        });

        this.priceBlock = true;
      } else {
        this.setCustomValidationMessage(
            localStorage.getItem("error_message"),
            "danger",
            true
        );

        return;
      }
    },

    addSpecialPriceForm() {
      this.numberOfPrices += 1;
      this.specialPrices = [...Array(this.numberOfPrices).keys()].map(i => this.specialPrices[i] || {
        category_key: null,
        name: null,
        regular_price: null,
        min_quantity: 1,
        quantity_step: 1,
        max_quantity: null,
        stock_quantity: null,
        sale_price: null
      });
    },

    removeSpecialPriceForm(index) {
      this.numberOfPrices -= 1;
      this.specialPrices.splice(index, 1);
    },

    // async addBonus() {
    //   Swal.fire({
    //     title: "Are you sure?",
    //     text: "You want to add bonus!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#34c38f",
    //     cancelButtonColor: "#f46a6a",
    //     confirmButtonText: "Yes",
    //   }).then((result) => {
    //     if (result.value) {
    //       this.bonus_amount = 1.5;
    //       Swal.fire({
    //         icon: 'success',
    //         title: 'Bonus Added!',
    //         showConfirmButton: false,
    //         timer: 1500
    //       });
    //     }
    //   });
    // },

    // removeBonus() {
    //   Swal.fire({
    //     title: "Are you sure?",
    //     text: "You want to remove bonus!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#34c38f",
    //     cancelButtonColor: "#f46a6a",
    //     confirmButtonText: "Yes",
    //   }).then((result) => {
    //     if (result.value) {
    //       this.bonus_amount = 0;
    //       Swal.fire({
    //         icon: 'success',
    //         title: 'Bonus Removed!',
    //         showConfirmButton: false,
    //         timer: 1500
    //       });
    //     }
    //   });
    // },

    chartCreated(key) {
      this.chartKey = key;
    },

    async addWorker() {
      let token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      if (!this.search_worker) {
        this.setCustomValidationMessage(
            "Team Member field is required.",
            "danger",
            true
        );
        return;
      }

      if (this.workers.find(worker => worker.email == this.search_worker || worker.contact_number == this.search_worker)) {
        this.setCustomValidationMessage(
            "Team Member is already in the list.",
            "danger",
            true
        );
        return;
      }


      if (!this.timeZone || !this.start_date || !this.start_time || !this.end_date || !this.end_time) {
        this.setCustomValidationMessage(
            "Time zone, Start Date and Time, End Date and Time is required.",
            "danger",
            true
        );
        return;
      }
      let response = await axios.post("worker/fetch", {
        search: this.search_worker,
        event_start_date: moment(this.start_date,"MM/DD/YYYY").format("YYYY-MM-DD"),
        event_end_date: moment(this.end_date,"MM/DD/YYYY").format("YYYY-MM-DD"),
        event_start_time: this.start_time,
        event_end_time: this.end_time,
        timezone_name: this.timeZone.timezone_name,
      });

      if (response) {
        this.search_worker = null;
        this.workers.push(response.data.data);
      } else {
        this.setCustomValidationMessage(
            localStorage.getItem("error_message"),
            "danger",
            true
        );
        return;
      }
    },

    removeWorker(id) {
      this.workers = this.workers.filter((worker) => worker.id != id);
    },
    logValidationErrors() {
      Object.keys(this.$v).forEach(field => {
        const fieldValidation = this.$v[field];
        if (fieldValidation && fieldValidation.$invalid) {
          // Log only fields that are invalid
          console.log(`Field: ${field}`);
          console.log(`  Required: ${fieldValidation.required ? fieldValidation.required : 'Not required'}`);
          console.log(`  Invalid: ${fieldValidation.$invalid}`);
          console.log(`  Errors:`, fieldValidation.$error);
        }
      });
      Object.keys(this.$v.specialPrices.$each).forEach((index) => {
        const fieldValidations = this.$v.specialPrices.$each[index];

        Object.keys(fieldValidations).forEach((field) => {
          const fieldValidation = fieldValidations[field];
          // Log the errors for each validation rule
          if (fieldValidation.$invalid) {
            console.log(`Field if: ${field}`);
          console.log(`  Required: ${fieldValidation.required ? fieldValidation.required : 'Not required'}`);
          console.log(`  Invalid: ${fieldValidation.$invalid}`);
          console.log(`  Errors:`, fieldValidation.$error);
          }
        });
      });
    },
    async onSubmit(status) {
      if (this.category === 'special' && this.specialPrices.length == 0) {
        this.setCustomValidationMessage(
              "Add at least 1 price level.",
              "danger",
              true
            )
        return;
      }

      if (this.category === 'seatsio' && !this.priceBlock) {
        this.setCustomValidationMessage(
              "Please enter/sync prices for the categories first.",
              "danger",
              true
            )
        return;
      }
      let token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('validation error');
        this.logValidationErrors();
        this.showForm.form1 = true;
        this.showForm.form2 = true;
        this.showForm.form3 = true;
        return;
      } else {
        this.status = status;

        if (this.adFile) {
          let formData = new FormData();
          formData.append("file", this.adFile);
          let response = await axios.post("upload-file", formData, {
            params: {
              imageType: "advertisements",
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response) {
            this.adImage = response.data.data.path;
          } else {
            this.setCustomValidationMessage(
              localStorage.getItem("error_message"),
              "danger",
              true
            );
            return;
          }
        }

        if (this.file) {
          let formData = new FormData();
          formData.append("file", this.file);
          let response = await axios.post("upload-file", formData, {
            params: {
              imageType: "files",
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response) {
            this.image = response.data.data.path;
          } else {
            this.setCustomValidationMessage(
              localStorage.getItem("error_message"),
              "danger",
              true
            );
            return;
          }
        }

        this.submitProductForm();
      }
    },
    async submitProductForm() {
      const specialPrices = this.category !== 'general-admission' ?  this.specialPrices.map((item) => {
        return {
          ...item,
          regular_price: (this.type == 'paid') ? item.regular_price : 0,
          sale_price: (this.type == 'paid') ? ((item.sale_price != null && item.sale_price.toString().trim().length) ? item.sale_price  : 0) : 0
        }
      }) : [];
      let token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      let timeZoneName = this.timeZone.timezone_name;
      let timeZoneAbbreviation = this.timeZone.timezone_abbreviation;
      let tax_id = this.user.role == 'super-admin' && this.type !='free' ? this.taxesGroup.id : this.tax_options[0].id;
      let product_obj = {
        name: this.name,
        description: this.description ? this.description : null,
        artist_name: this.artist_name ? this.artist_name : null,
        artist_detail: this.artist_detail ? this.artist_detail : null,
        status: this.status,
        image: this.image,
        advertisement_image: this.adImage,
        venue: this.venue,
        street: this.street,
        city: this.city,
        state: this.state,
        postcode: this.postcode,
        country: this.country,
        latitude: this.latitude,
        longitude: this.longitude,
        start_date: moment(this.start_date,"MM/DD/YYYY").format("YYYY-MM-DD"),
        start_time: this.start_time,
        sale_start_date: (this.type == 'paid') ? (this.sale_start_date ? moment(this.sale_start_date,"MM/DD/YYYY").format("YYYY-MM-DD") : null) : null,
        sale_end_date: (this.type == 'paid') ? (this.sale_end_date ? moment(this.sale_end_date,"MM/DD/YYYY").format("YYYY-MM-DD") : null) : null,
        end_date: moment(this.end_date,"MM/DD/YYYY").format("YYYY-MM-DD"),
        end_time: this.end_time,
        age_group: this.ageGroup,
        regular_price: this.category === 'general-admission' ? ((this.type == 'paid') ? this.regular_price : 0) : 0,
        sale_price: this.category === 'general-admission' ? ((this.type == 'paid') ? ((this.sale_price != null && this.sale_price.toString().trim().length) ? this.sale_price  : 0) : 0) : 0,
        taxes: (this.type == 'paid') ? [tax_id] : [],
        category: this.category,
        type: this.type,
        quantity_step: this.category === 'general-admission' ? this.quantity_step : 0,
        stock_quantity: this.category === 'general-admission' ? this.stock_quantity : 0,
        min_quantity: this.category === 'general-admission' ? this.min_quantity : 0,
        max_quantity: this.category === 'general-admission' ? this.max_quantity : 0,
        workers: this.workers,
        timezone_abbreviation: timeZoneAbbreviation,
        timezone_name: timeZoneName,
        bonus_amount: this.type == "free" ? 0.00 : (this.type == "paid" && this.category == "general-admission" || this.category == "special") ? 1.50 : this.type == "paid" && this.category == "seatsio" ? 1.80 : 0.00,
        // bonus_amount: this.type == 'paid' ? this.bonus_amount : 0,
        special_categories: specialPrices,
        door_open_time: this.door_open_time,
        account_name: this.account_name,
        account_number: this.account_number,
        routing_number: this.routing_number,
        bank_name: this.bank_name,
        chart_key: this.chartKey ? this.chartKey : undefined,
      };

      let response = await axios.post("product/create", product_obj);
      if (response) {
        this.setCustomValidationMessage(response.data.message, "success", true);
        this.$router.push("/products");
      } else {
        this.setCustomValidationMessage(
          localStorage.getItem("error_message"),
          "danger",
          true
        );
        return;
      }
    },
    onCancel() {
      this.$router.go(-1);
    },
    uploadSuccess(file) {
      this.file = file;
      this.$refs.myVueDropzone.disable();
    },
    disableDropzone(file) {
      this.$refs.myVueDropzone.removeFile(file);
    },
    uploadAdSuccess(file) {
      this.adFile = file;
      this.$refs.adVueDropzone.disable();
    },
    disableAdDropzone(file) {
      this.$refs.adVueDropzone.removeFile(file);
    },
    removedFile() {
      if (this.$refs.myVueDropzone.getAcceptedFiles().length == 0){
        this.file = null;
        this.$refs.myVueDropzone.enable();
      }
    },
    removedAdFile() {
      if (this.$refs.adVueDropzone.getAcceptedFiles().length == 0){
        this.adFile = null;
        this.$refs.adVueDropzone.enable();
      }
    },
    disabledSaleEndDate(date) {
      if (this.sale_start_date) {
        return (
          date < new Date(this.sale_start_date).setHours(0, 0, 0, 0)
        );
      }
    },
    disabledDate(date) {

      let timezone = this.timeZone;
      if (!timezone) {
        return true;
      }
      timezone = this.timeZone.timezone_name;
      date = moment(date).tz(timezone, true);
      let currentDate = moment().tz(timezone).startOf("day");
      return date.isBefore(currentDate);
    },
    disabledEndDate(date) {
      if (this.start_date) {
        let timezone = this.timeZone;
        if (!timezone) {
          return true;
        }
        timezone = this.timeZone.timezone_name;
        date = moment(date).tz(timezone, true);
        let currentDate = moment(this.start_date, "MM/DD/YYYY").tz(timezone, true).startOf("day");
        return date.isBefore(currentDate);
        // return date < new Date(this.start_date).setHours(0, 0, 0, 0);
      } else {
        let timezone = this.timeZone;
        if (!timezone) {
          return true;
        }
        timezone = this.timeZone.timezone_name;
        date = moment(date).tz(timezone, true);
        let currentDate = moment().tz(timezone).startOf("day");
        return date.isBefore(currentDate);
        // return date < new Date(new Date().setHours(0, 0, 0, 0));
      }
    },
    async getData() {
      this.tax_options = [];
      try {
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        let response_tax = await axios.get("admins/taxes");
        if (response_tax) {
          this.tax_options = response_tax.data.data;
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log("ERROR......", error);
        this.tax_options = [];
      }
      this.timezones = [];
      this.ageGroups = [];
      try {
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        let response = await axios.get("zones");

        let response_age_group = await axios.get("auth/ages");
        if (response_age_group) {
          this.ageGroups = response_age_group.data.data;
        }
        if (response) {
          this.timezones = response.data.data;
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log("ERROR......", error);
        this.timezones = [];
        this.ageGroups = [];
      }

      // this.taxes = this.tax_options.filter((tax) => tax.name === this.selectedTax)
      this.taxes = this.tax_options;

      this.specialPrices = [...Array(this.numberOfPrices).keys()].map(i => this.specialPrices[i] ||  {
        name: null,
        regular_price: null,
        min_quantity: 1,
        quantity_step: 1,
        max_quantity: null,
        stock_quantity: null,
        sale_price: null
      });


      this.charts = [];
      this.chart_options = [];
      this.canCreateChart =false;
      try {
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        let response_chart = await axios.get("product-charts");

        if (response_chart) {
          this.charts = response_chart.data.data;
          this.charts = this.charts.reduce((a, b) => {
            return {
              ...a,
              [b.key]: b.thumbnail_url
            }
          }, {});
          this.chart_options = response_chart.data.data.map((item) => {
            return {
              id: item.id,
              key: item.key,
              name: item.name,
            }
          });
          this.chart_options.unshift({
            id: -1,
            key: -1,
            name: 'Create New Chart'
          });
          this.canCreateChart = response_chart.data.canCreate;
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log("ERROR......", error);
        this.charts = [];
        this.chart_options = [];
        this.canCreateChart =false;
      }
    },

    setPlace(place) {
      if (place.address_components && place.address_components.length > 0) {

        this.currentPlace = place
        let country_obj = this.currentPlace.address_components.filter( address => (address.types.includes('country')));
        this.country = country_obj.length ? country_obj[0].long_name : null;
        let state_obj = this.currentPlace.address_components.filter( address => (address.types.includes('administrative_area_level_1')));
        this.state = state_obj.length ? state_obj[0].long_name : null;
        let city_obj = this.currentPlace.address_components.filter( address => (address.types.includes('locality')));
        this.city = city_obj.length ? city_obj[0].long_name : null;
        let postcode_obj = this.currentPlace.address_components.filter( address => (address.types.includes('postal_code')));
        this.postcode = postcode_obj.length ? postcode_obj[0].long_name : null;
        this.venue = this.currentPlace.name;
        let street_obj = this.currentPlace.formatted_address.split(',');
        this.street = street_obj.slice(0,street_obj.length - 2).toString();
        this.latitude = this.currentPlace.geometry.location.lat();
        this.longitude = this.currentPlace.geometry.location.lng();

        this.editField.country = this.country ? true : false;
        this.editField.state = this.state ? true : false;
        this.editField.city = this.city ? true : false;
        this.editField.postcode = this.postcode ? true : false;
        this.editField.venue = this.venue ? true : false;
        this.editField.street = this.street ? true : false;
        this.editField.latitude = this.latitude ? true : false;
        this.editField.longitude = this.longitude ? true : false;
      } else {
        this.setCustomValidationMessage("Please select a valid location", "danger", true);
        return;
      }
    },
  },
  beforeMount() {
    this.getData();
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div id="addproduct-accordion" class="custom-accordion">
          <!-- Basic info -->
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              @click="function() {
                showForm.form1 = !showForm.form1
                dropdownIcon.form1 = showForm.form1 ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }"
              aria-expanded="true"
              aria-controls="addproduct-billinginfo-collapse"
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="
                          avatar-title
                          rounded-circle
                          bg-soft-primary
                          text-dark
                        "
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Basic Info</h5>
                    <p class="text-muted text-truncate mb-0">
                      Add important information about the event
                    </p>
                  </div>
                  <i
                    class="mdi font-size-24"
                    :class="dropdownIcon.form1"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#addproduct-accordion"
              id="accordion-1"
              :visible="showForm.form1"
              accordion="my-accordion-1"
            >
              <div class="p-4 border-top">
                <form>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="name">Event Name</label>
                        <input
                          id="name"
                          placeholder="Event Name"
                          v-model="name"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': $v.name.$error,
                          }"
                        />
                        <div v-if="!$v.name.required" class="invalid-feedback">
                          Event name is required
                        </div>
                      </div>

                      <div class="mb-3">
                        <label for="description">Event Description</label>
                        <ckeditor
                          v-model="description"
                          id="description"
                          :editor="editor"
                          :config="editorConfig"
                        ></ckeditor>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="artistname">Artist Name</label>
                        <input
                          id="artistname"
                          placeholder="Artist Name"
                          v-model="artist_name"
                          type="text"
                          class="form-control"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="artistdetail">Artist Details</label>
                        <ckeditor
                          v-model="artist_detail"
                          id="artistdetail"
                          :editor="editor"
                          :config="editorConfig"
                        ></ckeditor>
                      </div>
                    </div>
                  </div>
                  <!-- Event Image -->
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="p-6 border">
                        <label for="myVueDropzone">Event Image</label>
                        <vue-dropzone
                          id="dropzone"
                          ref="myVueDropzone"
                          @vdropzone-max-files-exceeded="disableDropzone"
                          @vdropzone-success="uploadSuccess"
                          @vdropzone-removed-file="removedFile"
                          :use-custom-slot="true"
                          :options="dropzoneOptions"
                        >
                          <div class="dropzone-custom-content">
                            <i
                              class="display-3 text-muted bx bxs-cloud-upload"
                            ></i>
                            <h4>Drop cover image here or click to upload.</h4>
                          </div>
                        </vue-dropzone>
                      </div>
                    </div>
                  </div>
                  <!-- End Event Image -->
                  <!-- Advertisement Image -->
                  <div class="row" v-if="user.role === 'super-admin'">
                    <div class="col-lg-12 mt-3">
                      <div class="p-6 border">
                        <label for="adVueDropzone">Advertisement Image</label>
                        <vue-dropzone
                          id="adDropzone"
                          ref="adVueDropzone"
                          @vdropzone-max-files-exceeded="disableAdDropzone"
                          @vdropzone-success="uploadAdSuccess"
                          @vdropzone-removed-file="removedAdFile"
                          :use-custom-slot="true"
                          :options="adDropzoneOptions"
                        >
                          <div class="dropzone-custom-content">
                            <i
                              class="display-3 text-muted bx bxs-cloud-upload"
                            ></i>
                            <h4>Drop advertisement image here or click to upload.</h4>
                          </div>
                        </vue-dropzone>
                      </div>
                    </div>
                  </div>
                  <hr class="my-4" style="border: 1px solid black" />
                  <div class="row mb-3">
                    <!-- <div class="col-lg-2 mt-2">
                    </div> -->
                    <div class="col-lg-6">
                      <label for="location"> Search Venue/ Location</label>
                      <GmapAutocomplete
                        @place_changed='setPlace'
                        id="location"
                        placeholder="Search Venue/ Location"
                        class="form-control"
                        :class="{
                          'is-invalid': $v.currentPlace.$error,
                        }"
                      />
                      <div v-if="!$v.currentPlace.required" class="invalid-feedback">
                          Venue is required
                        </div>
                    </div>
                    <div class="col-lg-6">
                      <!-- Timezone dropdown -->
                        <b-form-group
                          id="input-group-1"
                          class="mb-3"
                          label="Time Zone"
                          label-for="typeselect"
                        >
                          <multiselect
                            placeholder="Time Zone"
                            id="typeselect"
                            v-model="timeZone"
                            :options="timezones"
                            :class="{
                              'is-invalid': $v.timeZone.$error,
                            }"

                          >
                            <template slot="singleLabel" slot-scope="props">
                              {{ props.option.timezone_abbreviation | timeZoneInfo(props.option.timezone_name) }}
                            </template>
                            <template slot="option" slot-scope="props">
                              {{ props.option.timezone_abbreviation | timeZoneInfo(props.option.timezone_name) }}
                            </template>
                          </multiselect>
                          <div v-if="!$v.timeZone.required" class="invalid-feedback">
                            Time Zone is required
                          </div>

                        </b-form-group>
                    </div>
                  </div>
                  <hr class="my-3" style="border: 1px solid gray" />
                  <div class="row" v-if="currentPlace">
                    <div class="col-lg-4">
                      <div class="mb-3">
                        <label for="venue">Venue/ Location</label>
                        <input
                          id="venue"
                          placeholder="Venue"
                          v-model="venue"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': $v.venue.$error,
                          }"
                        />
                        <div v-if="!$v.venue.required" class="invalid-feedback">
                          Venue is required
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3">
                        <label for="street">Street</label>
                        <input
                          id="street"
                          placeholder="Street"
                          v-model="street"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': $v.street.$error,
                          }"
                        />
                        <div
                          v-if="!$v.street.required"
                          class="invalid-feedback"
                        >
                          Street is required
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3">
                        <label for="city">City</label>
                        <input
                          id="city"
                          placeholder="City"
                          v-model="city"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': $v.city.$error,
                          }"
                        />
                        <div v-if="!$v.city.required" class="invalid-feedback">
                          City is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" v-if="currentPlace">
                    <div class="col-lg-4">
                      <div class="mb-3">
                        <label for="state">State</label>
                        <input
                          id="state"
                          placeholder="State"
                          v-model="state"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': $v.state.$error,
                          }"
                        />
                        <div v-if="!$v.state.required" class="invalid-feedback">
                          State is required
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3">
                        <label for="postcode">Zipcode</label>
                        <input
                          id="postcode"
                          placeholder="Zipcode"
                          v-model="postcode"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': $v.postcode.$error,
                          }"
                        />
                        <div
                          v-if="!$v.postcode.required"
                          class="invalid-feedback"
                        >
                          Zipcode is required
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3">
                        <label for="country">Country</label>
                        <input
                          id="country"
                          placeholder="Country"
                          v-model="country"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': $v.country.$error,
                          }"
                        />
                        <div
                          v-if="!$v.country.required"
                          class="invalid-feedback"
                        >
                          Country is required
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="currentPlace">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label for="latitude">Latitude</label>
                          <input
                            id="latitude"
                            v-model="latitude"
                            placeholder="Latitude"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': $v.latitude.$error,
                            }"
                            :readonly="editField.latitude"
                          />
                          <div
                            v-if="!$v.latitude.required"
                            class="invalid-feedback"
                          >
                            Latitude is required
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label for="longitude">Longitude</label>
                          <input
                            id="longitude"
                            v-model="longitude"
                            type="text"
                            placeholder="Longitude"
                            class="form-control"
                            :class="{
                              'is-invalid': $v.longitude.$error,
                            }"
                            :readonly="editField.longitude"
                          />
                          <div
                            v-if="!$v.longitude.required"
                            class="invalid-feedback"
                          >
                            Longitude is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="my-3" style="border: 1px solid black" />
                  <div class="row">
                    <div class="col-md-4">
                      <div class="mb-3">
                        <label for="start_date">Event Start Date</label>
                        <date-picker
                          v-model="start_date"
                          placeholder="MM/DD/YYYY"
                          :disabled-date="disabledDate"
                          id="start_date"
                          format="MM/DD/YYYY"
                          value-type="format"
                          :first-day-of-week="1"
                          :class="{ 'is-invalid': $v.start_date.$error }"
                        ></date-picker>
                        <div
                          v-if="!$v.start_date.required"
                          class="invalid-feedback"
                        >
                          Event start date is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3">
                        <label for="start_time">Event Start Time</label>
                        <date-picker
                          :popup-style="{ left: '41%' }"
                          v-model="start_time"
                          id="start_time"
                          format="hh:mm A"
                          value-type="HH:mm"
                          type="time"
                          placeholder="HH:mm"
                          :class="{ 'is-invalid': $v.start_time.$error }"
                        ></date-picker>
                        <div
                          v-if="!$v.start_time.required"
                          class="invalid-feedback"
                        >
                          Event start time is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3">
                        <label for="start_time">Event Door Opening Time</label>
                        <date-picker
                          :popup-style="{ left: '70%' }"
                          v-model="door_open_time"
                          id="door_open_time"
                          format="hh:mm A"
                          value-type="HH:mm"
                          type="time"
                          placeholder="HH:mm"
                          :class="{ 'is-invalid': $v.door_open_time.$error }"
                        ></date-picker>
                        <div
                          v-if="!$v.door_open_time.required"
                          class="invalid-feedback"
                        >
                          Event door opening time is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="end_date">Event End Date</label>
                        <date-picker
                          v-model="end_date"
                          :default-value="new Date()"
                          placeholder="MM/DD/YYYY"
                          :disabled="!start_date"
                          :disabled-date="disabledEndDate"
                          id="end_date"
                          format="MM/DD/YYYY"
                          value-type="format"
                          :first-day-of-week="1"
                          :class="{ 'is-invalid': $v.end_date.$error }"
                        ></date-picker>
                        <div v-if="$v.end_date.$error" class="invalid-feedback">
                          <span v-if="!$v.end_date.required"
                            >Event end date is required</span
                          >
                          <span v-else-if="!$v.end_date.sameOrAfter"
                            >Give valid event end date</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="end_time">Event End Time</label>
                        <date-picker
                          :popup-style="{ left: '55%' }"
                          :disabled="!start_time"
                          v-model="end_time"
                          id="end_time"
                          format="hh:mm A"
                          value-type="HH:mm"
                          type="time"
                          placeholder="HH:mm"
                          class="text-start"
                          :class="{ 'is-invalid': $v.end_time.$error }"
                        ></date-picker>
                        <div v-if="$v.end_time.$error" class="invalid-feedback">
                          <span v-if="!$v.end_time.required">
                            Event end time is required
                          </span>
                          <span v-else-if="!$v.end_time.sameOrAfterTime"
                            >Give valid event end time
                          </span>
                          <span v-else-if="!$v.end_time.pastTime"
                            >Give valid event dates or time
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </b-collapse>
          </div>

          <!-- Event Details -->
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              @click="function() {
                showForm.form2 = !showForm.form2
                dropdownIcon.form2 = showForm.form2 ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }"
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="
                          avatar-title
                          rounded-circle
                          bg-soft-primary
                          text-dark
                        "
                      >
                        02
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Event Details</h5>
                    <p class="text-muted text-truncate mb-0">
                      Add information about event tickets & pricing
                    </p>
                  </div>
                  <i
                    class="mdi font-size-24"
                    :class="dropdownIcon.form2"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-2"
              accordion="my-accordion-2"
              :visible="showForm.form2"
              data-parent="#addproduct-accordion"
            >
              <div class="p-4 border-top">
                <form>
                  <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                          <div class="">
                            <div class="input-group">
                              <label
                                  class="col-form-label"
                                  for="worker"
                              >Team Members</label
                              >
                              <div class="input-group-text bg-transparent border-0">

                              </div>
                            </div>
                          </div>
                          <div class="col-md-5">
                            <input
                                id="worker"
                                placeholder="Search Team Member (Email)"
                                type="text"
                                class="form-control"
                                v-model="search_worker"
                            />
                          </div>
                          <div class="col-md-7">
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="addWorker"
                            >
                              Search
                            </button>
                          </div>
                          <div class="mt-2">
                              <span class="info-label">
                                <i class="uil-info-circle"></i> Assign person to check-in guests on the day of the event.
                              </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-2 d-lg-none d-block">
                      <div class="row">
                        <div class="col-md-3" v-for="worker in workers" :key="worker.id">
                          <worker-info
                              :email="worker.email"
                              :name="worker.name"
                              :contact_number="worker.contact_number"
                              :id="worker.id"
                              :canDelete="true"
                              @remove="removeWorker(worker.id)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                          <div class="">
                            <div class="input-group">
                              <label
                                  class="col-form-label"
                                  for="worker"
                              >Download Scan App</label
                              >
                              <div class="input-group-text bg-transparent border-0">

                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <a target="_blank" class="mr-4" :href="androidLink()">
                              <img src="@/assets/images/android.png" height="40" alt="Android">
                            </a>
                            <a target="_blank" class=" ms-4" :href="iosLink()">
                              <img src="@/assets/images/ios.png" height="40" alt="IOS">
                            </a>
                          </div>
                          <div class="mt-2">
                              <span class="info-label">
                                <i class="uil-info-circle"></i> Download and register the team members.
                              </span>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-12 mt-2 d-lg-block d-none">
                      <div class="row">
                        <div class="col-md-3" v-for="worker in workers" :key="worker.id">
                          <worker-info
                              :email="worker.email"
                              :name="worker.name"
                              :contact_number="worker.contact_number"
                              :id="worker.id"
                              :canDelete="true"
                              @remove="removeWorker(worker.id)"
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <hr class="m-3" style="border: 1px solid black" />
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group row mb-2">
                        <div class="row">
                          <div class="col-md-4">
                              <div class="input-group">
                                <label class="col-form-label">Ticket Type</label>
                                <div class="input-group-text bg-transparent border-0">

                                </div>
                              </div>
                            </div>
                          <div class="col-md-2">
                            <b-form-radio
                              v-model="type"
                              class="mt-2"
                              value="free"
                              plain
                              :class="{
                                'is-invalid': $v.type.$error,
                              }"
                              >Free</b-form-radio
                            >
                            <div
                              class="invalid-feedback"
                              v-if="!$v.type.required"
                            >
                              Ticket Type is required.
                            </div>
                          </div>
                          <div class="col-md-2">
                            <b-form-radio
                              v-model="type"
                              class="mt-2"
                              value="paid"
                              plain
                              :class="{
                                'is-invalid': $v.type.$error,
                              }"
                              >Paid</b-form-radio
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group row mb-3">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="input-group">
                              <label class="col-form-label" for="category"
                                >Category</label
                              >
                              <div class="input-group-text bg-transparent border-0">

                              </div>
                            </div>
                          </div>
                          <div class="col-md-8">
                            <div class="form-control-md row">
                              <div class="col-md-4">
                                <b-form-radio
                                  v-model="category"
                                  class="mt-2"
                                  value="general-admission"
                                  :class="{
                                    'is-invalid': $v.category.$error,
                                  }"
                                  plain
                                  >Single Ticket</b-form-radio
                                >
                                <div
                                  class="invalid-feedback"
                                  v-if="!$v.category.required"
                                >
                                  Category is required
                                </div>
                              </div>
                              <div class="col-md-4">
                                <b-form-radio
                                  v-model="category"
                                  class="mt-2"
                                  value="special"
                                  :class="{
                                    'is-invalid': $v.category.$error,
                                  }"
                                  plain
                                  >Multiple Ticket</b-form-radio
                                >
                              </div>
                              <div class="col-md-4">
                                <b-form-radio
                                  v-model="category"
                                  class="mt-2"
                                  value="seatsio"
                                  :class="{
                                    'is-invalid': $v.category.$error,
                                  }"
                                  plain
                                  >Seating Chart</b-form-radio
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-12">
                      <div class="row" v-if="type != 'free'">
                        <div class="col-xl-6">
                          <div class="form-group row mb-3">
                            <div class="row">
                              <div class="col-md-4">
                                  <div class="input-group">
                                    <label for="sale_start" class="col-form-label"
                                      >Promo Start Date</label
                                    >
                                    <div class="input-group-text bg-transparent border-0">

                                    </div>
                                  </div>
                                </div>
                              <div class="col-md-8">
                                <date-picker
                                  v-model="sale_start_date"
                                  placeholder="MM/DD/YYYY"
                                  :default-value="new Date()"
                                  id="sale_start"
                                  format="MM/DD/YYYY"
                                  value-type="format"
                                  :first-day-of-week="1"
                                  :class="{
                                    'is-invalid': $v.sale_start_date.$error,
                                  }"
                                ></date-picker>
                                <div v-if="$v.sale_start_date.$error" class="invalid-feedback">
                                  Promo start date is required
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-6">
                          <div class="form-group row mb-3">
                            <div class="row">
                              <div class="col-md-4">
                                  <div class="input-group">
                                    <label for="sale_end" class="col-form-label"
                                      >Promo End Date</label
                                    >
                                    <div class="input-group-text bg-transparent border-0">

                                    </div>
                                  </div>
                                </div>
                              <div class="col-md-8">
                                <date-picker
                                  v-model="sale_end_date"
                                  placeholder="MM/DD/YYYY"
                                  :default-value="new Date()"
                                  id="sale_end"
                                  :disabled="!sale_start_date"
                                  :disabled-date="disabledSaleEndDate"
                                  format="MM/DD/YYYY"
                                  value-type="format"
                                  :first-day-of-week="1"
                                  :class="{
                                    'is-invalid': $v.sale_end_date.$error,
                                  }"
                                ></date-picker>
                                <div v-if="$v.sale_end_date.$error" class="invalid-feedback">
                                  <span v-if="!$v.sale_end_date.required">
                                    Promo end date is required
                                  </span>
                                  <span v-else-if="!$v.sale_end_date.sameOrAfter">
                                  Give valid promo end date
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6">
                      <div class="form-group row mb-3">
                        <div class="row">
                              <div class="col-md-4">
                                <div class="input-group">
                                  <label class="col-form-label" for="age_group"
                                    >Age Group
                                  </label>
                                  <div class="input-group-text bg-transparent border-0">

                                  </div>
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class="form-control-md">
                                  <multiselect
                                    placeholder="Age Group"
                                    id="typeselect"
                                    v-model="ageGroup"
                                    :options="ageGroups"
                                    :class="{
                                      'is-invalid': $v.ageGroup.$error,
                                    }"

                                  >
                                    <template slot="singleLabel" slot-scope="props">
                                      {{ props.option }}
                                    </template>
                                    <template slot="option" slot-scope="props">
                                      {{ props.option }}
                                    </template>
                                  </multiselect>
                                  <div v-if="!$v.ageGroup.required" class="invalid-feedback">
                                    Age group is required
                                  </div>

                                </div>
                              </div>
                            </div>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group row mb-3">
                        <div class="row" v-if="user.role === 'super-admin' && type != 'free'">
                              <div class="col-md-4">
                                <div class="input-group">
                                  <label class="col-form-label" for="taxes_group"
                                    >Tax Applied
                                  </label>
                                  <div class="input-group-text bg-transparent border-0">
                                    <i class="uil-info-circle" v-b-tooltip.topleft.hover.o-300 title="This tax is applicable for order in a percentages."></i>
                                </div>
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class="form-control-md">
                                  <multiselect
                                    placeholder="Tax Selection"
                                    id="typeselect"
                                    v-model="taxesGroup"
                                    :options="taxes"
                                    :class="{
                                      'is-invalid': $v.taxesGroup.$error,
                                    }"

                                  >
                                  <template slot="singleLabel" slot-scope="props">
                                    {{ props.option.name  }}
                                  </template>
                                  <template slot="option" slot-scope="props">
                                    {{ props.option.name }}
                                  </template>
                                  </multiselect>
                                  <div v-if="!$v.taxesGroup.required" class="invalid-feedback">
                                    Tax is required.
                                  </div>

                                </div>
                              </div>
                            </div>
                      </div>
                    </div>
                    <!-- <div v-if="type != 'free'" class="col-xl-6">
                      <div class="form-group row mb-3">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="input-group">
                              <label class="col-form-label" for="bonus_amount"
                                >Bonus Amount($)</label
                              >
                              <div
                                class="
                                  input-group-text
                                  bg-transparent
                                  border-0
                                "
                              ></div>
                            </div>
                          </div>

                          <div class="col-md-8">
                            <input
                              id="bonus_amount"
                              placeholder="Bonus Amount($)"
                              type="text"
                              v-model="bonus_amount"
                              :disabled="viewPage"
                              class="form-control"
                              :class="{
                                'is-invalid': $v.bonus_amount.$error,
                              }"
                            />
                            <div
                              v-if="$v.bonus_amount.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.bonus_amount.required"
                                >Bonus Amount is required</span
                              >
                              <span v-else-if="!$v.bonus_amount.numeric"
                                >This value should be digits</span
                              >
                              <span v-else-if="!$v.bonus_amount.maxLength"
                                >Maximum length is 5 digits</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div class="col-xl-6">
                      <div class="form-group row mb-3" v-if="type == 'paid'">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="input-group">
                              <label class="col-form-label" for="bonus_amount"
                                >
                              </label>
                              <div class="input-group-text bg-transparent border-0">

                              </div>
                            </div>
                          </div>
                          <div class="col-md-8">
                            <div class="form-control-md">
                              <div id="bonus_amount" v-if="!bonus_amount">
                                <button class="btn btn-success w-50" type="button" @click="addBonus">Increase the ticket sales by $1.00</button>
                              </div>
                              <div v-else class="col-md-6">
                                <div class="me-2 ps-3
                                                badge
                                                font-size-15
                                                text-dark
                                                bg-soft-main-theme-color">
                                  <span>Bonus Amount: $1.00</span>
                                  <b-button @click="removeBonus" variant="transparent"><i class="fa fa-times"></i></b-button>
                                </div>
                              </div>
                            </div>
                            <div class="mt-2">
                              <span class="info-label">
                                <i class="uil-info-circle"></i> Increase the sales by $1.00 by adding the Bonus
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->

                  </div>
                  <div class="row" v-if="category == 'general-admission'">
                    <div v-if="type != 'free'" class="col-xl-6">
                      <div class="form-group row mb-3">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="input-group">
                              <label
                                class="col-form-label"
                                for="regular_price"
                                >Regular Price($)</label
                              >
                              <div class="input-group-text bg-transparent border-0">

                              </div>
                            </div>
                          </div>
                          <div class="col-md-8">
                            <input
                              id="regular_price"
                              placeholder="Regular Price($)"
                              type="text"
                              class="form-control"
                              v-model="regular_price"
                              :class="{
                                'is-invalid': $v.regular_price.$error,
                              }"
                            />
                            <div
                              v-if="$v.regular_price.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.regular_price.required">
                                Regular price is required</span
                              >
                              <span v-else-if="!$v.regular_price.numeric"
                                >This value should be digits</span
                              >
                              <span v-else-if="!$v.regular_price.maxLength"
                                >Maximum length is 5 digits</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="type != 'free'" class="col-xl-6">
                      <div class="form-group row mb-3">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="input-group">
                              <label
                                class="col-form-label"
                                for="sale_price"
                                >Promo Price($)</label
                              >
                              <div class="input-group-text bg-transparent border-0">

                              </div>
                            </div>
                          </div>
                          <div class="col-md-8">
                            <input
                              id="sale_price"
                              placeholder="Promo Price($)"
                              type="text"
                              v-model="sale_price"
                              class="form-control"
                              :class="{
                                'is-invalid': $v.sale_price.$error,
                              }"
                            />
                            <div
                              v-if="$v.sale_price.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.sale_price.required"
                                >Promo price is required</span
                              >
                              <span v-else-if="!$v.sale_price.numeric"
                                >This value should be digits</span
                              >
                              <span v-else-if="!$v.sale_price.maxLength"
                                >Maximum length is 5 digits</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6" v-if="false">
                    <!-- Tax -->
                      <div class="col-12">
                        <div v-if="type != 'free'" class="form-group row mb-3">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="input-group">
                                <label class="col-form-label" for="tax"
                                  >Tax
                                </label>
                                <div class="input-group-text bg-transparent border-0">

                                </div>
                              </div>
                            </div>
                            <div class="col-md-8">
                              <div class="form-control-md">
                                <span v-for="tax in taxes" :key="tax.id">
                                  <span
                                    class="
                                      me-2
                                      badge
                                      font-size-15
                                      text-dark
                                      bg-soft-main-theme-color
                                    "
                                  >
                                    {{ tax_options.filter((tax) => tax.name === selectedTax).map(tax => tax.name).join(', ') }}
                                  </span>
                                </span>
                                <multiselect
                                  v-model="taxes"
                                  placeholder="Tax"
                                  :disabled="true"
                                  v-if="false"
                                  :options="tax_options"
                                  :closeOnSelect="false"
                                  label="name"
                                  valueProp="id"
                                  track-by="id"
                                  multiple
                                >
                                </multiselect>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <!-- end col -->
                    </div>
                    <div class="col-xl-6">
                      <div class="col-12">
                        <div class="form-group row mb-3">
                          <div class="row" v-if="false">
                            <div class="col-md-4">
                              <div class="input-group">
                                <label
                                    class="col-form-label"
                                    for="min_quantity"
                                    >Min Tickets per Transaction </label>
                                  <div class="input-group-text bg-transparent border-0">
                                      <i class="uil-info-circle" v-b-tooltip.topleft.hover.o-300 title="Enter minimum quantity of ticket customers can purchase for this event in single go."></i>
                                  </div>
                              </div>
                            </div>
                            <div class="col-md-8">
                              <input
                                id="min_quantity"
                                placeholder="Min Tickets per Transaction"
                                type="text"
                                v-model="min_quantity"
                                class="form-control"
                                :class="{
                                  'is-invalid': $v.min_quantity.$error,
                                }"
                              />
                              <div
                                v-if="$v.min_quantity.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.min_quantity.required"
                                  >Min quantity is required</span
                                >
                                <span v-else-if="!$v.min_quantity.numeric"
                                  >This value should be digits</span
                                >
                                <span v-else-if="!$v.min_quantity.maxLength"
                                  >Maximum length is 5 digits</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row mb-3">
                          <div class="row" v-if="false">
                            <div class="col-md-4">
                              <div class="input-group">
                                <label
                                  class="col-form-label"
                                  for="quantity_step"
                                  >Quantity Step
                                  </label
                                >
                                <div class="input-group-text bg-transparent border-0">
                                    <i class="uil-info-circle" v-b-tooltip.topleft.hover.o-300 title="The interval by which customers can purchase the tickets."></i>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-8">
                              <input
                                id="quantity_step"
                                placeholder="Quantity Step"
                                type="text"
                                class="form-control"
                                v-model="quantity_step"
                                :class="{
                                  'is-invalid': $v.quantity_step.$error,
                                }"
                              />
                              <div
                                v-if="$v.quantity_step.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.quantity_step.required"
                                  >Quantity step is required</span
                                >
                                <span v-else-if="!$v.quantity_step.numeric"
                                  >This value should be digits</span
                                >
                                <span v-else-if="!$v.quantity_step.maxLength"
                                  >Maximum length is 5 digits</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="col-12">
                        <div class="form-group row mb-3">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="input-group">
                                <label
                                  class="col-form-label"
                                  for="max_quantity"
                                  >Max Tickets per Transaction
                                  </label>
                                <div class="input-group-text bg-transparent border-0">
                                    <i class="uil-info-circle" v-b-tooltip.topleft.hover.o-300 title="Enter maximum quantity of ticket customers can purchase for this event in single go."></i>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-8">
                              <input
                                id="max_quantity"
                                placeholder="Max Quantity"
                                type="text"
                                v-model="max_quantity"
                                class="form-control"
                                :class="{
                                  'is-invalid': $v.max_quantity.$error,
                                }"
                              />
                              <div
                                v-if="$v.max_quantity.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.max_quantity.required"
                                  >Max quantity is required</span
                                >
                                <span v-else-if="!$v.max_quantity.numeric"
                                  >This value should be digits</span
                                >
                                <span v-else-if="!$v.max_quantity.checkMin">
                                  Give valid max quantity
                                </span>
                                <span v-else-if="!$v.max_quantity.maxLength"
                                  >Maximum length is 5 digits</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row mb-3">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="input-group">
                                <label
                                  class="col-form-label pe-0"
                                  for="stock_quantity"
                                  >
                                  Total Tickets</label>
                                <div class="input-group-text bg-transparent border-0">
                                    <i class="uil-info-circle" v-b-tooltip.topleft.hover.o-300 title="Total number of tickets."></i>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-8">
                              <input
                                id="stock_quantity"
                                placeholder="Total Tickets"
                                type="text"
                                v-model="stock_quantity"
                                class="form-control"
                                :class="{
                                  'is-invalid': $v.stock_quantity.$error,
                                }"
                              />
                              <div
                                v-if="$v.stock_quantity.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.stock_quantity.required"
                                  >Field is required</span
                                >
                                <span v-else-if="!$v.stock_quantity.numeric"
                                  >This value should be digits</span
                                >
                                <span v-else-if="!$v.stock_quantity.maxLength"
                                  >Maximum length is 5 digits</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                  <div class="multiple_block" v-else>
                    <div class="row chart_block mb-4" v-if="category == 'seatsio' && !priceBlock">
                      <div class="row" v-if="!chartKey">
                        <div class="chart_list col-md-6">
                          <h4>Charts</h4>
                          <multiselect
                            class="mb-2"
                            v-model="chart"
                            :preselectFirst="false"
                            :allowEmpty="true"
                            :canDeselect="false"
                            :options="chart_options"
                            label="name"
                            valueProp="key"
                            track-by="key"
                          >
                          </multiselect>
                          <div class="mt-2 mb-2">
                              <span class="info-label">
                                <i class="uil-info-circle"></i> Selecting the "Create New Chart" option in the charts will generate a temporary chart. If you wish to create a template, use the "Add Chart" option from side menu instead.
                              </span>
                            </div>
                          <b-button v-if="chart && !chartKey" variant="primary" type="button" :disabled="isConfirmBtnDisabled" @click="confirmChart">Confirm</b-button>
                        </div>
                        <div class="col-md-6" v-if="chart_thumbnail">
                          <h4>Preview</h4>
                          <div class="text-center chart_thumbnail">
                            <img :src="chart_thumbnail" alt="chart_thumbnail" height="200" />
                          </div>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-12">
                            <b-button class="float-end" variant="primary" type="button" @click="() => { this.chartKey = null; this.priceBlock= false}"><i class="fa fa-edit"></i> Change Chart</b-button>
                        </div>
                      </div>
                      <div class="chart_designer" v-if="chartKey">
                        <seatsio-chart-designer
                          v-if="(chartKey && !isEventManager && !isEventBooking && !isEventStatic)"
                          :openLatestDrawing="true"
                          id="chart-design"
                          :chartKey="chartKey !== -1 ? chartKey : undefined"
                          :onChartCreated="chartCreated"
                          :onDesignerRendered="designerRendered"
                          :features="{ disabled: ['publishing'] }"
                          :secretKey="secretKey"
                          :isEventManager="isEventManager"
                          :custom="(chartKey && !isEventManager)"
                        />
                        <seatsio-event-manager v-else-if="isEventManager" id="event-manager" 
                              :secretKey="secretKey"
                              :event="eventKey"
                              :onChartRendered="chartRendered"
                              mode="manageForSaleConfig" />
                        <seatsio-event-booking v-else-if="isEventBooking" id="event-booking" 
                              :secretKey="secretKey"
                              :event="eventKey"
                              :onChartRendered="chartRendered"
                              mode="manageObjectStatuses" />
                        <seatsio-event-static v-else-if="isEventStatic" id="event-static" 
                              :secretKey="secretKey"
                              :event="eventKey"
                              :onChartRendered="chartRendered"
                              mode="static" />

                      
                        <div v-else class="text-center text-theme m-auto loader">
                            <div class="spinner">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong> Loading...</strong>
                            </div>
                        </div>
                        <b-overlay
                          class="d-inline-block"
                          :show="priceBlockLoader"
                          spinner-small
                          rounded opacity="0.6">
                          <b-button v-if="chartKey && chartKey != -1" :disabled="priceBlockLoader" variant="primary" type="button" @click="syncChartChanges">Continue with Ticket Tiers</b-button>
                        </b-overlay>
                      </div>
                    </div>
                    <div class="row" v-else>
                      <div class="col-md-7">
                      </div>

                     
                      <div class="col-md-4" v-if="category == 'seatsio' && eventKey">
                        <b-button class="float-end" variant="primary" type="button" @click="() => { this.priceBlock= false; this.pageloader = true; this.specialPrices = []; this.isEventManager= true; this.isEventBooking=false; this.isEventStatic=false}"><i class="fas fa-money-bill-wave"></i> Manage for sale</b-button>
                        <b-button class="float-end ms-2" variant="primary" type="button" @click="() => { this.priceBlock= false; this.pageloader = true; this.specialPrices = []; this.isEventManager= false; this.isEventBooking=false; this.isEventStatic=true}"><i class="fa fa-eye"></i> Display Status</b-button>
                        <b-button class="float-end" variant="primary" type="button" @click="() => { this.priceBlock= false; this.pageloader = true; this.specialPrices = []; this.isEventManager= false; this.isEventBooking=true; this.isEventStatic=false}"><i class="fas fa-bookmark"></i> Manage booking</b-button>
                      </div>
                      <div class="col-md-4" v-else>
                      </div>
                       <div class="col-md-1" v-if="category == 'seatsio'">
                        <b-button class="float-right" variant="success" type="button" @click="() => { this.priceBlock= false; this.pageloader = true; this.specialPrices = []; this.isEventManager=false; this.isEventBooking=false, this.isEventStatic=false}"><i class="fa fa-edit"></i> Edit Chart</b-button>
                      </div>
                    </div>
                    <div class="row pricing_block" v-if="priceBlock">
                      <div class="col-md-6 mt-3">
                        <h4>Ticket Tiers</h4>
                      </div>
                      <div class="col-md-6 mb-2">
                        <b-button v-if="category == 'special'" class="float-end" variant="primary" @click="addSpecialPriceForm" type="button">Add</b-button>
                      </div>
                      <div style="border: 1px solid #c9c6c5; border-radius: 10px;">
                        <div v-for="(specialPrice, i) in specialPrices" :key="i" :set="v = $v.specialPrices.$each[i]" class="mt-2 mb-2 p-2">
                        <div class="row">
                          <div class="col-md-6">
                            <h5>
                              <b>
                                Ticket Tier {{(i+1)}}
                              </b>
                            </h5>
                          </div>
                          <div class="col-md-6">
                            <b-button v-if="category == 'special'" class="float-end text-danger" style="font-size: 20px;" variant="transparent" @click="removeSpecialPriceForm(i)" type="button"><i class="fa fa-times"></i></b-button>
                          </div>
                        </div>
                          <div class="row">
                            <div class="col-xl-6">
                              <div class="form-group row mb-3">
                                <div class="row">
                                  <!-- Name-->
                                  <div class="col-md-4">
                                    <div class="input-group">
                                      <label
                                        class="col-form-label"
                                        for="name"
                                        >Name</label
                                      >

                                    </div>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      placeholder="Name"
                                      type="text"
                                      class="form-control"
                                      :disabled="category != 'special'"
                                      v-model="specialPrice.name"
                                      :class="{
                                        'is-invalid': v.name.$error,
                                      }"
                                    />
                                    <div
                                      v-if="v.name.$error"
                                      class="invalid-feedback"
                                    >
                                      <span v-if="!v.name.required">
                                        Name is required</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xs-6"></div>
                            <div v-if="type != 'free'" class="col-xl-6">
                              <div class="form-group row mb-3">
                                <div class="row">
                                  <!-- Regular Price -->
                                  <div class="col-md-4">
                                    <div class="input-group">
                                      <label
                                        class="col-form-label"
                                        for="regular_price"
                                        >Regular Price($)</label
                                      >
                                      <div class="input-group-text bg-transparent border-0">

                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      placeholder="Regular Price($)"
                                      type="text"
                                      class="form-control"
                                      v-model="specialPrice.regular_price"
                                      :class="{
                                        'is-invalid': v.regular_price.$error,
                                      }"
                                    />
                                    <div
                                      v-if="v.regular_price.$error"
                                      class="invalid-feedback"
                                    >
                                      <span v-if="!v.regular_price.required">
                                        Regular price is required</span
                                      >
                                      <span v-else-if="!v.regular_price.numeric"
                                        >This value should be digits</span
                                      >
                                      <span v-else-if="!v.regular_price.maxLength"
                                        >Maximum length is 5 digits</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="type != 'free'" class="col-xl-6">
                                <!-- Sale Price -->
                              <div class="form-group row mb-3">
                                <div class="row">
                                  <div class="col-md-4">
                                    <div class="input-group">
                                      <label
                                        class="col-form-label"
                                        for="sale_price"
                                        >Promo Price($)</label
                                      >
                                      <div class="input-group-text bg-transparent border-0">

                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      id="sale_price"
                                      placeholder="Promo Price($)"
                                      type="text"
                                      v-model="specialPrice.sale_price"
                                      class="form-control"
                                      :class="{
                                        'is-invalid': v.sale_price.$error,
                                      }"
                                    />
                                    <div
                                      v-if="v.sale_price.$error"
                                      class="invalid-feedback"
                                    >
                                      <span v-if="!v.sale_price.required"
                                        >Promo price is required</span
                                      >
                                      <span v-else-if="!v.sale_price.numeric"
                                        >This value should be digits</span
                                      >
                                      <span v-else-if="!v.sale_price.maxLength"
                                        >Maximum length is 5 digits</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                              <div class="col-xl-6">
                                <div class="col-12">
                                  <div class="form-group row mb-3">
                                    <div class="row" v-if="false">
                                      <div class="col-md-4">
                                        <div class="input-group">
                                          <label
                                              class="col-form-label"
                                              for="min_quantity"
                                              >Min Tickets per Transaction </label>
                                          <div class="input-group-text bg-transparent border-0">
                                              <i class="uil-info-circle" v-b-tooltip.topleft.hover.o-300 title="Enter minimum quantity of ticket customers can purchase for this event in single go."></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-8">
                                        <input
                                          id="min_quantity"
                                          placeholder="Min Tickets per Transaction"
                                          type="text"
                                          v-model="specialPrice.min_quantity"
                                          class="form-control"
                                          :class="{
                                            'is-invalid': v.min_quantity.$error,
                                          }"
                                        />
                                        <div
                                          v-if="v.min_quantity.$error"
                                          class="invalid-feedback"
                                        >
                                          <span v-if="!v.min_quantity.required"
                                            >Min quantity is required</span
                                          >
                                          <span v-else-if="!v.min_quantity.numeric"
                                            >This value should be digits</span
                                          >
                                          <span v-else-if="!v.min_quantity.maxLength"
                                            >Maximum length is 5 digits</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row mb-3">
                                    <div class="row" v-if="false">
                                      <div class="col-md-4">
                                        <div class="input-group">
                                          <label
                                            class="col-form-label"
                                            for="quantity_step"
                                            >Quantity Step
                                            </label
                                          >
                                          <div class="input-group-text bg-transparent border-0">
                                              <i class="uil-info-circle" v-b-tooltip.topleft.hover.o-300 title="The interval by which customers can purchase the tickets."></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-8">
                                        <input
                                          id="quantity_step"
                                          placeholder="Quantity Step"
                                          type="text"
                                          class="form-control"
                                          v-model="specialPrice.quantity_step"
                                          :class="{
                                            'is-invalid': v.quantity_step.$error,
                                          }"
                                        />
                                        <div
                                          v-if="v.quantity_step.$error"
                                          class="invalid-feedback"
                                        >
                                          <span v-if="!v.quantity_step.required"
                                            >Quantity step is required</span
                                          >
                                          <span v-else-if="!v.quantity_step.numeric"
                                            >This value should be digits</span
                                          >
                                          <span v-else-if="!v.quantity_step.maxLength"
                                            >Maximum length is 5 digits</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-6">
                                <div class="col-12">
                                  <div class="form-group row mb-3">
                                    <div class="row">
                                      <div class="col-md-4">
                                        <div class="input-group">
                                          <label
                                            class="col-form-label"
                                            for="max_quantity"
                                            >Max Tickets per Transaction
                                            </label>
                                          <div class="input-group-text bg-transparent border-0">
                                              <i class="uil-info-circle" v-b-tooltip.topleft.hover.o-300 title="Enter maximum quantity of ticket customers can purchase for this event in single go."></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-8">
                                        <input
                                          id="max_quantity"
                                          placeholder="Max Tickets per Transaction"
                                          type="text"
                                          v-model="specialPrice.max_quantity"
                                          class="form-control"
                                          :class="{
                                            'is-invalid': v.max_quantity.$error,
                                          }"
                                        />
                                        <div
                                          v-if="v.max_quantity.$error"
                                          class="invalid-feedback"
                                        >
                                          <span v-if="!v.max_quantity.required"
                                            >Max quantity is required</span
                                          >
                                          <span v-else-if="!v.max_quantity.numeric"
                                            >This value should be digits</span
                                          >
                                          <span v-else-if="!v.max_quantity.checkMin">
                                            Give valid max quantity
                                          </span>
                                          <span v-else-if="!v.max_quantity.maxLength"
                                            >Maximum length is 5 digits</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row mb-3">
                                    <div class="row">
                                      <div class="col-md-4">
                                        <div class="input-group">
                                          <label
                                            class="col-form-label pe-0"
                                            for="stock_quantity"
                                            >
                                            Total Tickets</label>
                                          <div class="input-group-text bg-transparent border-0">
                                              <i class="uil-info-circle" v-b-tooltip.topleft.hover.o-300 title="Total number of tickets."></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-8">
                                        <input
                                          id="stock_quantity"
                                          placeholder="Total Tickets"
                                          type="text"
                                          v-model="specialPrice.stock_quantity"
                                          class="form-control"
                                          :disabled="category != 'special'"
                                          :class="{
                                            'is-invalid': v.stock_quantity.$error,
                                          }"
                                        />
                                        <div
                                          v-if="v.stock_quantity.$error"
                                          class="invalid-feedback"
                                        >
                                          <span v-if="!v.stock_quantity.required"
                                            >Field is required</span
                                          >
                                          <span v-else-if="!v.stock_quantity.numeric"
                                            >This value should be digits</span
                                          >
                                          <span v-else-if="!v.stock_quantity.maxLength"
                                            >Maximum length is 5 digits</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                          </div>
                          <hr v-if="specialPrices.length -1 !== i" class="my-3" style="border: 1px solid gray" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </b-collapse>
          </div>

          <!-- Bank Details -->
           <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              @click="function() {
                showForm.form3 = !showForm.form3
                dropdownIcon.form3 = showForm.form3 ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }"
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="
                          avatar-title
                          rounded-circle
                          bg-soft-primary
                          text-dark
                        "
                      >
                        03
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Bank Details</h5>
                    <p class="text-muted text-truncate mb-0">
                      Add banking information for event payout.
                    </p>
                  </div>
                  <i
                    class="mdi font-size-24"
                    :class="dropdownIcon.form3"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-3"
              accordion="my-accordion-3"
              :visible="showForm.form3"
              data-parent="#addproduct-accordion"
            >
              <div class="p-4 border-top">
                <form>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group row mb-3">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="input-group">
                              <label
                                class="col-form-label"
                                for="account_name"
                                >Account Name</label
                              >
                              <div class="input-group-text bg-transparent border-0">

                              </div>
                            </div>
                          </div>
                          <div class="col-md-8">
                            <input
                              id="account_name"
                              placeholder="Account Name"
                              type="text"
                              class="form-control"
                              v-model="account_name"
                              :class="{
                                'is-invalid': $v.account_name.$error,
                              }"
                            />
                            <div
                              v-if="$v.account_name.$error"
                              class="invalid-feedback"
                            >
                              <span>
                                Enter valid account name
                                </span>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group row mb-3">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="input-group">
                              <label
                                class="col-form-label"
                                for="bank_name"
                                >Bank Name</label
                              >
                              <div class="input-group-text bg-transparent border-0">

                              </div>
                            </div>
                          </div>
                          <div class="col-md-8">
                            <input
                              id="bank_name"
                              placeholder="Bank Name"
                              type="text"
                              v-model="bank_name"
                              class="form-control"
                              :class="{
                                'is-invalid': $v.bank_name.$error,
                              }"
                            />
                            <div
                              v-if="$v.bank_name.$error"
                              class="invalid-feedback"
                            >
                              <span
                                >Enter valid bank name</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="col-12">
                        <div class="form-group row mb-3">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="input-group">
                                <label
                                    class="col-form-label"
                                    for="routing_number"
                                    >Routing Number</label>
                                  <div class="input-group-text bg-transparent border-0">
                                  </div>
                              </div>
                            </div>
                            <div class="col-md-8">
                              <input
                                id="routing_number"
                                placeholder="Routing Number"
                                type="text"
                                v-model="routing_number"
                                class="form-control"
                                :class="{
                                  'is-invalid': $v.routing_number.$error,
                                }"
                              />
                              <div
                                v-if="$v.routing_number.$error"
                                class="invalid-feedback"
                              >
                                <span
                                  >Enter valid routing number</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="col-12">
                        <div class="form-group row mb-3">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="input-group">
                                <label
                                  class="col-form-label"
                                  for="account_number"
                                  >Account Number
                                  </label
                                >
                                <div class="input-group-text bg-transparent border-0">
                                </div>
                              </div>
                            </div>
                            <div class="col-md-8">
                              <input
                                id="account_number"
                                placeholder="Account Number"
                                type="text"
                                class="form-control"
                                v-model="account_number"
                                :class="{
                                  'is-invalid': $v.account_number.$error,
                                }"
                              />
                              <div
                                v-if="$v.account_number.$error"
                                class="invalid-feedback"
                              >
                                <span
                                  >Enter valid account number</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </form>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <a @click="onCancel" class="btn btn-danger">
          <i class="uil uil-times me-1"></i> Cancel
        </a>
        <a @click.prevent="onSubmit('drafted')" class="btn btn-info ms-1">
          <i class="uil uil-file-blank-1"></i> Save As Draft
        </a>
        <a @click.prevent="onSubmit('published')" class="btn btn-success ms-1">
          <i class="uil uil-file-alt me-1"></i> Publish
        </a>
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>

<style>
.ck-editor__editable {
  min-height: 150px !important;
  max-height: 150px;
  line-height: 1rem;
}
.vue-dropzone .dz-preview .dz-image {
  height: 10rem !important;
  width: 100% !important;
}
.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
  background-color: #2fdaec !important;
}

.vue-dropzone {
  display: flex;
  justify-content: center;
  padding: 5px;
}

.multiselect__placeholder {
  color: #72777c !important;
}

.chart_thumbnail {
  border: 0.5px #ced4da solid;
  border-radius: 10px;
}
</style>
