<style scoped>
.slide-fade-enter-active {
    transition: all 0.8s ease;
}

.slide-fade-leave-active {
    transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(20px);
    opacity: 0;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import {
    required,
    maxLength,
} from "vuelidate/lib/validators";
import axios from "axios";

export default {
    page: {
        title: "Charts",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },

    validations: {
        name: {
            required,
            maxLength: maxLength(50),
            textOnly: function () {
                let textRegex = new RegExp(/^[a-zA-Z]*$/);
                let textValidation = !!textRegex.exec(this.first_name);
                return textValidation;
            }
        },
        venueType: {
            required: function (value) {
                if (this.key) {
                    return true;
                }

                return !!value;
            },
        },
    },

    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.charts.length;
        },
    },
    data() {
        return {
            charts: [],
            title: "",
            isBusy: false,
            name: null,
            venueType: "",
            canCreate: false,
            submitted: false,
            isAuthError: false,
            authError: "",
            showForm: false,
            isEditing: false,
            key: null,
            chartFormButtonName: "Create",
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "key",
            sortDesc: true,
            fields: [
                {
                    key: "name",
                    label: "Name",
                    sortable: true,
                },
                {
                    key: "thumbnail_url",
                    sortable: false,
                },
                {
                    key: "venue_type",
                    sortable: true,
                },
                {
                    key: "date",
                    sortable: true,
                },
                {
                    key: "actions",
                    sortable: false,
                },
            ],
        };
    },

    mounted() {
        this.getData();
    },
    methods: {

        confirmDelete(key) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Delete",
            }).then((result) => {
                if (result.value) {
                    this.deleteChart(key);
                }
            });
        },

        async deleteChart(key) {
            try {
                let successMessage = "Deleted successfully.";
                let token = localStorage.getItem("token");
                axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                let response = await axios.delete("charts/" + key);
                if (response) {
                    successMessage = response.data.message;
                    Swal.fire("Success!", successMessage, "success");
                }
                this.getData();
            } catch (error) {
                console.log(error);
            }
        },

        async getData() {
            try {
                this.isBusy = true;
                let token = localStorage.getItem("token");
                axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                let response = await axios.get("charts");
                if (response) {
                    this.isBusy = false;
                    this.charts = [];
                    this.charts = response.data.data;
                    this.canCreate = response.data.canCreate;
                } else {
                    this.isBusy = false;
                    this.canCreate = false;
                    this.charts = [];
                    console.log("error");
                }
            } catch (error) {
                this.canCreate = false;
                this.charts = [];
            }
        },

        onFiltered() {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.currentPage = 1;
        },
        chartForm(data = null) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.name = null;
            this.isEditing = false;
            this.key = null;
            this.venueType = "";
            if (data) {
                this.key = data.key;
                this.name = data.name;
                this.venueType = data.venue_type;
                this.isEditing = true;
            }

            if (this.key) {
                this.chartFormButtonName = "Update";
            } else {
                this.chartFormButtonName = "Create";
            }
            this.showForm = !this.showForm;
        },

        async submitForm() {
            try {
                this.isAuthError = false;
                this.authError = null;
                this.submitted = true;
                // stop here if form is invalid
                this.$v.$touch();

                if (this.$v.$invalid) {
                    return;
                } else {

                    this.showForm = false;

                    let token = localStorage.getItem("token");
                    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                    if (this.key) {
                        let { key, name } = this;

                        let response = await axios.patch("chart/update", {
                            key: key,
                            name: name,
                        });

                        if (response) {
                            this.setCustomValidationMessage(
                                response.data.message,
                                "success",
                                true
                            );
                            this.getData();
                        } else {
                            this.setCustomValidationMessage(
                                localStorage.getItem("error_message"),
                                "danger",
                                true
                            );
                            return;
                        }
                    } else {
                        let { name, venueType } =
                            this;

                        let response = await axios.post("chart/create", {
                            name, venueType
                        });

                        if (response) {
                            this.setCustomValidationMessage(
                                response.data.message,
                                "success",
                                true
                            );
                            this.getData();
                        } else {
                            this.setCustomValidationMessage(
                                localStorage.getItem("error_message"),
                                "danger",
                                true
                            );
                            return;
                        }
                    }
                    this.showForm = false;
                    this.name = "";
                    this.venueType = "";
                    this.description = "";
                    this.slug = "";
                    this.$v.$reset();
                }
            } catch (error) {
                console.log("Error : ", error.message);
                this.showForm = false;
                this.name = "";
                this.$v.$reset();
            }
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="[]" />
        <div class="row mb-2">
            <div class="col-md-4">
                <h3>Charts</h3>
            </div>
            <div class="col-md-8">
                <div class="float-end">
                    <div>
                        <button type="button" v-if="canCreate" @click.prevent="chartForm(null)"
                            class="btn btn-warning ms-1 mb-3">
                            <i class="mdi mdi-plus me-1"></i> Add Chart
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <div v-if="showForm" class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <b-alert :show="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError
                                }}</b-alert>

                                <b-form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group id="input-group-1" class="mb-3" label="Name" label-for="input-1">
                                                <b-form-input id="input-1" v-model="name" type="text" placeholder="Name"
                                                    :class="{
                                                        'is-invalid': submitted && $v.name.$error,
                                                    }"></b-form-input>
                                                <div v-if="submitted && $v.name.$error" class="invalid-feedback">
                                                    <span v-if="!$v.name.required">Name is required.</span>
                                                    <span v-else-if="!$v.name.maxLength">
                                                        Maximum length is 15 characters long.
                                                    </span>
                                                    <span v-else-if="!$v.name.textOnly">
                                                        Give valid name.
                                                    </span>
                                                </div>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6"></div>
                                        <div class="col-md-6 ">
                                            <b-form-group id="input-group-2" class="mb-3" label="Venue Type"
                                                label-for="input-2">
                                                <div class="contents">
                                                    <div class="options">
                                                        <label class="type-of-chart"
                                                            v-show="!key || (key && venueType === 'MIXED')" :class="{
                                                                option_disabled: this.key
                                                            }">
                                                            <input v-model="venueType" :disabled="key" value="MIXED"
                                                                class="radio" type="radio">
                                                            <div class="option">
                                                                <img width="300" class="preview-image preview-sheet"
                                                                    src="https://cdn-na.seatsio.net/static/version/v48/chart-designer-v2/venueTypes/simple-chart@2x.jpg" />
                                                                <div class="title">Without sections</div>
                                                                <div class="title-description">Best suited for venues
                                                                    accommodating
                                                                    less
                                                                    than 1,000 people.</div>
                                                            </div>
                                                        </label>
                                                        <label class="type-of-chart"
                                                            v-show="!key || (key && venueType === 'ROWS_WITH_SECTIONS')"
                                                            :class="{
                                                                option_disabled: this.key
                                                            }">
                                                            <input v-model="venueType" :disabled="key"
                                                                value="ROWS_WITH_SECTIONS" class="radio" type="radio">
                                                            <div class="option">
                                                                <div class="dual-preview preview-sheet">
                                                                    <img width="300" class="preview-image a"
                                                                        src="https://cdn-na.seatsio.net/static/version/v48/chart-designer-v2/venueTypes/sections-chart-a@2x.jpg">
                                                                    <img width="300" class="preview-image b"
                                                                        src="https://cdn-na.seatsio.net/static/version/v48/chart-designer-v2/venueTypes/sections-chart-b@2x.jpg">
                                                                </div>
                                                                <div class="title">With sections and floors</div>
                                                                <div class="title-description">For theatres, stadiums,
                                                                    concert
                                                                    halls
                                                                    and
                                                                    other venues with sectors.</div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="mt-2" v-if="!key">
                                                    <span class="info-label">
                                                        <i class="uil-info-circle"></i> This can only be set while creating
                                                        the chart
                                                    </span>
                                                </div>
                                            </b-form-group>
                                        </div>
                                    </div>

                                    <div class="mt-3 text-start">
                                        <b-button type="button" @click.prevent="submitForm" class="me-2" size="md"
                                            variant="warning">{{
                                                chartFormButtonName }}</b-button>

                                        <b-button type="reset" class="" @click.prevent="() => (showForm = false)" size="md"
                                            variant="secondary">Cancel</b-button>
                                    </div>
                                </b-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div class="row">
            <div class="col-12">
                <div class="">
                    <div class="">
                        <div class="row">
                            <div class="row">
                                <!-- Search -->
                                <div class="col-md-10">
                                    <div id="chart-table_filter" class="dataTables_filter text-md-start">
                                        <div class="input-group">
                                            <div class="input-group-text">
                                                <i class="uil-search"></i>
                                            </div>
                                            <b-form-input v-model="filter" placeholder="Search Chart" type="search"
                                                class="form-control form-control-md px-1"></b-form-input>
                                        </div>
                                    </div>
                                </div>
                                <!-- End search -->

                                <div class="col-md-2">
                                    <div id="tickets-table_length" class="dataTables_length text-md-start">
                                        <label class="d-inline-flex align-items-center fw-normal mt-1">
                                            Show&nbsp;&nbsp;
                                            <b-form-select v-model="perPage" size="lg"
                                                :options="pageOptions"></b-form-select>&nbsp;&nbsp;entries
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table show-empty empty-text="No data found" :busy="isBusy"
                                table-class="table table-centered datatable table-card-list" thead-tr-class="bg-transparent"
                                :items="charts" :fields="fields" responsive="sm" sort-icon-left :per-page="perPage"
                                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                                <template #table-busy>
                                    <div class="text-center text-theme m-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong> Loading...</strong>
                                    </div>
                                </template>
                                <template #cell(name)="data">
                                    <span>{{ data.item.name }}</span>
                                </template>

                                <template #cell(thumbnail_url)="data">
                                    <span v-if="data.item.thumbnail_url">
                                        <img :src="data.item.thumbnail_url" alt="chart" height="150" />
                                    </span>
                                    <span v-else>
                                        -
                                    </span>
                                </template>

                                <template #cell(venue_type)="data">
                                    <span>{{ data.item.venue_type | chartVenueType }}</span>
                                </template>

                                <template #cell(date)="data">
                                    <span>{{ data.item.date | formatDate }}</span>
                                </template>

                                <template #cell(actions)="data">
                                    <div>
                                        <b-tooltip title="Open Designer" :target="'designer_' + data.item.key"
                                            placement="top">
                                        </b-tooltip>
                                        <b-tooltip title="Edit Chart Name" :target="'edit_' + data.item.key"
                                            placement="top">
                                        </b-tooltip>
                                        <b-tooltip title="Delete Chart" :target="'delete_' + data.item.key"
                                            placement="top">
                                        </b-tooltip>
                                        <!-- chart designer -->
                                        <router-link v-if="data.item.permission.canEdit" :id="'designer_' + data.item.key"
                                            :to="{
                                                name: 'Chart Designer', params: { key: data.item.key }
                                            }" class="me-2 btn btn-sm btn-primary"><i
                                                class="fas fa-paint-brush"></i></router-link>
                                        <!-- chart edit -->
                                        <b-button v-if="data.item.permission.canEdit" :id="'edit_' + data.item.key"
                                            class="me-2" @click.prevent="chartForm(data.item)" size="sm"
                                            variant="success"><i class="fas fa-pen"></i></b-button>
                                        <!-- chart delete -->
                                        <b-button v-if="data.item.permission.canDelete" :id="'delete_' + data.item.key"
                                            size="sm" @click.prevent="confirmDelete(data.item.key)" variant="danger"><i
                                                class="fas fa-trash"></i></b-button>
                                    </div>
                                </template>
                            </b-table>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers">
                                    <ul class="pagination pagination-rounded">
                                        <!-- pagination -->
                                        <b-pagination style="margin: auto" v-model="currentPage" :total-rows="rows"
                                            :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style></style>