<template>
  <div class="row p-2 m-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="card-title text-black mb-3">{{ eventName }}</h5>
            <b class="text-success">Total Earnings: ${{ totalEarnings }}</b>
          </div>
          <div v-if="loading" class="text-center">Loading...</div>
          <div v-else>
            <div class="d-flex justify-content-between align-items-center pt-4">
              <h6>Current Category with Price</h6>
              <b style="color: #8a25fc">Earnings: ${{ currentEarnings }}</b>
            </div>
            <CategoryTable :data="currentData" :showPriceColumns="showPriceColumns" />

            <div v-if="filteredPreviousData.length > 0" class="d-flex justify-content-between align-items-center pt-4">
              <h6>Previous Category with Price</h6>
              <b style="color: #8a25fc">Earnings: ${{ previousEarnings }}</b>
            </div>
            <CategoryTable v-if="filteredPreviousData.length > 0" :data="filteredPreviousData" :showPriceColumns="true" />
            <p class="text-muted">
              Note - Historic data will only be visible if the sold quantity for that category or price is greater than 0.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CategoryTable from './CategoryTable.vue';

export default {
  name: 'TicketsSoldHistory',
  components: {
    CategoryTable
  },
  props: {
    id: {
      type: String,
      required: true
    },
    eventName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentData: [],
      previousData: [],
      loading: false,
      currentEarnings: 0,
      previousEarnings: 0,
      isMultipleFree: false,
      totalEarnings: 0
    };
  },
  computed: {
    filteredPreviousData() {
      return this.previousData.filter(item => item.regularQty > 0 || item.saleQty > 0);
    },
    showPriceColumns() {
      return !this.isMultipleFree;
    }
  },
  methods: {
    fetchData() {
      this.loading = true;
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      axios.get(`product/${this.id}/tickets-history`)
        .then(response => {
          const data = response.data.data;
          this.currentData = data.current;
          this.previousData = data.previous;
          this.currentEarnings = data.currentEarnings;
          this.previousEarnings = data.previousEarnings;
          this.totalEarnings = this.currentEarnings + this.previousEarnings;
          this.isMultipleFree = data.isMultipleFree;
          this.loading = false;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          this.loading = false;
        });
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
