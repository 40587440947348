export const menuItems = [
  {
    id: 1,
    label: "menuitems.dashboard.text",
    icon: "uil-home-alt",
    badge: {
      variant: "primary",
      text: "menuitems.dashboard.badge",
    },
    link: "/",
  },
  {
    id: 2,
    label: "menuitems.products.text",
    icon: "uil-store",
    subItems: [
      {
        id: 3,
        label: "menuitems.products.products.text",
        link: "/products",
        parentId: 2,
      },
      {
        id: 4,
        label: "menuitems.products.category.text",
        link: "/products/categories",
        parentId: 2,
      },
      {
        id: 5,
        label: "menuitems.products.tax.text",
        link: "/products/taxes",
        parentId: 2,
      },
    ],
  },
  {
    id: 6,
    label: "menuitems.users.text",
    icon: "uil-user",
    subItems: [
      {
        id: 8,
        label: "menuitems.users.admin.text",
        link: "/users/admins",
        parentId: 6,
      },
      {
        id: 7,
        label: "menuitems.users.index.text",
        link: "/users/operators",
        parentId: 6,
      },
      {
        id: 10,
        label: "menuitems.users.worker.text",
        link: "/users/workers",
        parentId: 6,
      },
      {
        id: 9,
        label: "menuitems.users.customer.text",
        link: "/users/customers",
        parentId: 6,
      },
    ],
  },
  {
    id: 11,
    label: "menuitems.chart.text",
    icon: "uil-chart",
    link: "/charts",
  },
  {
    id: 12,
    label: "menuitems.tax.text",
    icon: "uil-money-bill",
    link: "/taxes",
  },
  {
    id: 15,
    label: "menuitems.reports.text",
    icon: "fas fa-clipboard-check",
    subItems: [
      {
        id: 16,
        label: "menuitems.reports.before.text",
        icon: "fas fa-hand-holding",
        link: "/reports/before",
        parentId: 15,
      },
      {
        id: 17,
        label: "menuitems.reports.after.text",
        link: "/reports/after",
        parentId: 15,
      }
    ],
  },
  {
    id: 18,
    label: "menuitems.analytics.text",
    icon: "fas fa-chart-line",
    subItems: [
      {
        id: 19,
        label: "menuitems.analytics.google.text",
        icon: "fas fa-hand-holding",
        link: "https://analytics.google.com/analytics/web",
        parentId: 18,
        target: "_blank",
        rel: "noopener noreferrer"
      },
      {
        id: 20,
        label: "menuitems.analytics.microsoft.text",
        link: "https://clarity.microsoft.com/projects/view/nyv9ayy44e/dashboard?date=Last%207%20days",
        parentId: 18,
        target: "_blank",
        rel: "noopener noreferrer"
      }
    ]
  }
  
];
