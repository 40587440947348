<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import {
  required,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import axios from "axios";

export default {
  page: {
    title: "Taxes",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },


  validations: {
    name: {
      required,
      name,
    },
    // fees is required and not less than 1 and not more than 100
    fees: {
      required,
      numeric: function (value) {
                if (value) {
                  return !isNaN(value) && value > 0 && !value.toString().includes(' ');
                } else {
                  return true;
                }
              },
      maxLength: minValue(0.01),
      minlength: maxValue(100),
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.taxes.length;
    },
  },
  data() {
    return {
      taxes: [],
      title: "",
      isBusy: false,
      name: null,
      fees: null,
      type: null,
      is_active: false,
      is_default: false,
      canCreate: false,
      submitted: false,
      isAuthError: false,
      authError: "",
      showForm: false,
      isEditing: false,
      id: null,
      adminFormButtonName: "Create",
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: false,
      fields: [
        {
          key: "name",
          label: "Tax Header",
          sortable: true,
        },
        {
          key: "fees",
          label: "Tax %",
          sortable: false,
        },
        // {
        //   key: "type",
        //   label: "Type",
        //   sortable: false,
        // },
       
        {
          key: "is_active",
          label: "Status",
          sortable: true,
        },
        {
          key: "is_default",
          label: "Default",
          sortable: true,
        },
        {
          key: "actions",
          sortable: false,
        }
      ],
      selectedItemId: null,
    };
  },

  mounted() {
    // Set the initial number of items
    this.getData();
  },
  methods: {
    capitalizeWords(string) {
      if (!string) return '';
      return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    confirmActiveInactive(is_active, id) {
      let confirmButtonText = is_active == 1 ? "Activate" : "In-Activate";
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: confirmButtonText,
      }).then((result) => {
        if (result.value) {
          this.activateInactivate(is_active, id);
        }
      });
    },

    async activateInactivate(is_active, id) {
      try {
        let successMessage = "Activated successfully";
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        let response = await axios.post("tax/update-status", {
          id,
          is_active: is_active,
        });
        if (response) {
          successMessage = response.data.message;
          Swal.fire("Success!", successMessage, "success");
        }
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },

    async getData() {
      try {
        this.isBusy = true;
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        let response = await axios.get("taxes");
        if (response) {
          this.isBusy = false;
          this.taxes = [];
          this.taxes = response.data.data;
        } else {
          this.isBusy = false;
          this.taxes = [];
          console.log("error");
        }
      } catch (error) {
        this.isBusy = false;
        this.taxes = [];
      }
    },

    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
    },
    taxForm(data = null) {
      window.scrollTo({top: 0, behavior: 'smooth'});
      this.name = null;
      this.fees = null;
      this.type = null;
      this.is_active = false;
      this.is_default = false;
      this.isEditing = false;
      this.id = null;
      if (data) {
        this.name = data.name;
        this.fees = data.fees;
        this.type = data.type;
        this.is_active = data.is_active;
        this.is_default = data.is_default;
        this.id = data.id;
        this.isEditing = true;
      }

      if (this.id) {
        this.adminFormButtonName = "Update";
      } else {
        this.adminFormButtonName = "Create";
      }
      this.showForm = !this.showForm;
    },

    async submitForm() {
      try {
        this.isAuthError = false;
        this.authError = null;
        this.submitted = true;
        // stop here if form is invalid
        this.$v.$touch();

        if (this.$v.$invalid) {
          return;
        } else {
          let token = localStorage.getItem("token");
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          if (this.id) {
            let { name, fees, type, is_active, id, is_default} = this;
            let response = await axios.post("tax/update", {
              name,
              fees,
              type,
              is_active,
              is_default,
              id,
            });
            if (response) {
              this.setCustomValidationMessage(
                response.data.message,
                "success",
                true
              );
              this.getData();
            } else {
              this.setCustomValidationMessage(
                localStorage.getItem("error_message"),
                "danger",
                true
              );
              return;
            }
          } else {
            let { name, fees, type, is_active , is_default} = this;
            let response = await axios.post("tax/create", {
              name,
              fees,
              type,
              is_active,
              is_default,
            });
            if (response) {
              this.setCustomValidationMessage(
                response.data.message,
                "success",
                true
              );
              this.getData();
            } else {
              this.setCustomValidationMessage(
                localStorage.getItem("error_message"),
                "danger",
                true
              );
              return;
            }
          }
          this.showForm = false;
          this.name = "";
          this.description = "";
          this.slug = "";
          this.$v.$reset();
        }
      } catch (error) {
        console.log("Error : ", error.message);
        this.showForm = false;
        this.name = "";
        this.description = "";
        this.slug = "";
        this.$v.$reset();
      }
    },
    
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="[]" />
    <div class="row mb-4">
      <div class="col-md-4">
        <h3>Taxes</h3>
      </div>
      <div class="col-md-8">
        <div class="float-end">
            <div>
                <button type="button" @click.prevent="taxForm(null)"
                    class="btn btn-warning ms-1 mb-3">
                    <i class="mdi mdi-plus me-1"></i> Add Tax
                </button>
            </div>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="showForm" class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <b-alert
                  :show="isAuthError"
                  variant="danger"
                  class="mt-3"
                  dismissible
                  >{{ authError }}</b-alert
                >

                <b-form @submit.prevent="submitForm">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="mb-3">
                        <label for="name" class="form-label">Tax Header</label>
                        <b-form-input
                          v-model="name"
                          type="text"
                          id="name"
                          placeholder="Enter Tax Header"
                        ></b-form-input>
                        <div v-if="$v.name.$error" class="text-danger">
                          <span v-if="!$v.name.required">Name is required</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3">
                        <label for="fees" class="form-label">Tax %</label>
                        <b-form-input
                          v-model="fees"
                          type="text"
                          id="fees"
                          placeholder="Enter Tax %"
                        ></b-form-input>
                        <div v-if="$v.fees.$error" class="text-danger">
                          <span v-if="!$v.fees.required">Fees is required</span>
                          <span
                          v-if="
                            !$v.fees.maxlength ||
                            !$v.fees.minlength
                          "
                          > Tax is in between 0.01 to 100. </span>
                        </div>
                       
                      </div>
                    </div>
                    <!-- // add dropdown for status -->
                    <!-- <div class="col-md-4">
                      <div class="mb-3">
                        <label for="type" class="form-label">Type</label>
                        <b-form-select
                          v-model="type"
                          :options="[
                            { value: 'percentage', text: 'Percentage' },
                            { value: 'fixed', text: 'Fixed' }
                          ]"
                          id="type"
                          placeholder="Select Type"
                        ></b-form-select>
                        <div v-if="$v.type.$error" class="text-danger">
                          <span v-if="!$v.type.required">Type is required</span>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-md-2">
                      <div class="mb-3">
                        <label for="is_active" class="form-label"></label>
                        <b-form-checkbox
                          v-model="is_active"
                          id="is_active"
                          name="is_active"
                          value="1"
                          unchecked-value="0"
                          ><span style="margin-left: 20px;">Active</span>
                          </b-form-checkbox
                        >
                      </div>
                    </div>
                    <!-- <div class="col-md-2">
                      <div class="mb-3">
                        <label for="is_default" class="form-label"></label>
                        <b-form-checkbox
                          v-model="is_default"
                          id="is_default"
                          name="is_default"
                          value="1"
                          unchecked-value="0"
                          ><span style="margin-left: 20px;">Is Default</span>
                          </b-form-checkbox
                        >
                      </div>
                    </div> -->

                    <div class="col-md-2">
                        <div class="mt-3 text-start">
                            <b-button
                              type="submit"
                              class="me-2"
                              size="md"
                              variant="warning"
                              >{{ adminFormButtonName }}</b-button
                            >
        
                            <b-button
                              type="reset"
                              class=""
                              @click.prevent="() => (showForm = false)"
                              size="md"
                              variant="secondary"
                              >Cancel</b-button
                            >
                          </div>
                    </div>

                      
                  </div>

                  
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="row">
      <div class="col-12">
        <div class="">
          <div class="">
            <div class="row">
              <div class="row">
                <!-- Search -->
                <div class="col-md-10">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-start"
                  >
                    <div class="input-group">
                      <div class="input-group-text">
                        <i class="uil-search"></i>
                      </div>
                      <b-form-input
                        v-model="filter"
                        placeholder="Search Taxes..."
                        type="search"
                        class="form-control form-control-md px-1"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
                <!-- End search -->

                <div class="col-md-2">
                  <div
                    id="tickets-table_length"
                    class="dataTables_length text-md-start"
                  >
                    <label
                      class="d-inline-flex align-items-center fw-normal mt-1"
                    >
                      Show&nbsp;&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="lg"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;&nbsp;entries
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                show-empty
                empty-text="No data found"
                :busy="isBusy"
                table-class="table table-centered datatable table-card-list"
                thead-tr-class="bg-transparent"
                :items="taxes"
                :fields="fields"
                responsive="sm"
                sort-icon-left
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #table-busy>
                  <div class="text-center text-theme m-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Loading...</strong>
                  </div>
                </template>
                <template #cell(name)="data">
                  <span>{{ data.item.name }}</span>
                </template>
                <template #cell(fees)="data">
                  <span>{{ data.item.fees }}</span>
                </template>
                <template #cell(type)="data">
                    <span>{{ capitalizeWords(data.item.type) }}</span>
                  </template>
                <template #cell(is_active)="data">
                  <span
                    class="text-dark badge fw-fold font-size-12 p-2"
                    :class="{
                      'bg-soft-success': data.item.is_active === 1,
                      'bg-soft-danger': data.item.is_active === 0,
                    }"
                    >{{ data.item.is_active ? 'Active' : 'In-Active' }}</span
                  >
                </template>

                <template #cell(is_default)="data">
                  <span
                    class="text-dark badge fw-fold font-size-12 p-2"
                    :class="{
                      'bg-soft-success': data.item.is_default === 1,
                      'bg-soft-danger': data.item.is_default === 0,
                    }"
                    >{{ data.item.is_default ? 'Default' : '' }}</span
                  >
                </template>

                <template #cell(actions)="data">
                    <div v-if="data.item.is_active == 1">
                        <b-button 
                        class="me-2 text-succdess"
                        @click.prevent="taxForm(data.item)"
                        size="sm"
                        variant="primary"
                        ><i class="fas fa-pen"></i
                      ></b-button>
                    
                      <!-- <b-button v-if="data.item.is_active == 1"
                        class="me-2 text-danger"
                        @click.prevent="() => confirmActiveInactive(0, data.item.id)"
                        size="sm"
                        variant="danger"
                        ><i class="fas fa-ban"></i
                      ></b-button> -->
                    </div>
                    <div v-else>
                      <b-button
                        class="me-2 text-success"
                        @click.prevent="() => confirmActiveInactive(1, data.item.id)"
                        size="sm"
                        variant="success"
                        ><i class="fas fa-check"></i
                      ></b-button>
                    </div>
                  </template>
                </b-table>
            </div>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      style="margin: auto"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>