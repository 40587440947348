<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import { Multiselect } from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import infoCard from "@/components/widgets/infoCard";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
const Excel = require("exceljs");

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
    Multiselect,
    infoCard,
    DateRangePicker,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      tableData: [],
      title: "Dashboard",
      isBusy: false,
      anyEvent: false,
      anyPromoter: false,
      user: null,
      event_options: [],
      promoter_options: [],
      event: null,
      promoter: null,
      total_events: null,
      net_sales: 0,
      gross_sales: 0,
      total_tickets: 0,
      admin_detail_operator: 0,
      admin_detail_customer: 0,
      admin_detail_worker: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [20,50,100,150],
      filter: '',
      filterOn: [],
      sortBy: "combined_order_id",
      sortDirection: "desc",
      sortDesc: true,
      fields: [
        {
          key: "combined_order_id",
          label: "#Order ID",
          // sortable: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "product",
          label: "Event",
        },
        {
          key: "no_of_tickets",
          label: "Tickets",
        },
        {
          key: "refunded_quantity",
          label: "Refunded Tickets",
        },
        {
          key: "event_type",
          label: "Ticket Type",
        },
        
        {
          key: "ticket_section",
          label: "Section-Row-Seat",
        },
        {
          key: "amount",
          label: "Amount",
        },
        {
          key: "refunded_amount",
          label: "Refunded Amount",
        },
        {
          key: "completed_at",
          label: "Purchased On",
        },
        {
          key: "payment_method",
          label: "Payment Method",
        },
        {
          key: "payment_id",
          label: "Payment ID",
        },
      ],
      ranges: {},
      timeZone: "",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    },
    filteredData() {
    if (this.filter) {
      return this.tableData.filter(item => {
        return Object.keys(item).some(key => {
          return String(item[key]).toLowerCase().includes(this.filter.toLowerCase());
        });
      });
    } else {
      return this.tableData;
    }
  }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // async downloadCSVData() {
    //   // export filtered data as an excel file
    //   var workbook = new Excel.Workbook();
    //   var worksheet = workbook.addWorksheet("Sheet1");
    //   worksheet.columns = [
    //     { header: "Order ID", key: "combined_order_id", width: 20, style: { alignment: {horizontal: 'left'} } },
    //     { header: "Name", key: "name", width: 20 },
    //     { header: "Email", key: "email", width: 20 },
    //     { header: "Event Name", key: "product", width: 20 },
    //     { header: "Tickets", key: "no_of_tickets", width: 20 },
    //     { header: "Ticket Type", key: "event_type", width: 20 },
    //     { header: "Section-Row-Seat", key: "ticket_section", width: 20 },
    //     { header: "Amount", key: "amount", width: 20 },
    //     { header: "Payment Method", key: "payment_method", width: 20 },
    //     { header: "Payment ID", key: "payment_id", width: 20 },
    //     { header: "Purchase Date", key: "completed_at", width: 20 },
    //   ];
    //   let excelData = JSON.parse(JSON.stringify(this.filteredData)).sort((a, b) => b.combined_order_id - a.combined_order_id);
    //   excelData = excelData.map((data) => {
    //     data.completed_at = moment(data.completed_at, "MM/DD/YYYY HH:mm:ss").format("MM/DD/YYYY");
    //     return data;
    //   });

    //   worksheet.addRows(excelData);
    //   worksheet.getRow(1).font = { bold: true };

    //   const buffer = await workbook.xlsx.writeBuffer();
    //   const data = new Blob([buffer], {
    //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    //   });
    //   var filename = "Orders_" + new Date().toDateString() + ".xlsx";
    //   saveAs(data, filename);
    // },

    async downloadCSVData() {
      this.isBusy = true;
      
      try {
        let token = localStorage.getItem("token");
        let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        
        // API call to fetch all filtered data (no pagination)

        let response = await axios.post(`dashboard?&string=${this.filter}&sort_by=${this.sortBy}&sorting_order=${this.sortDirection}`, {
          product_id: this.event.product_id,
          promoter_id: this.promoter.promoter_id,
          start_date_time: this.dateRange.startDate
            ? moment(this.dateRange.startDate).format("MM-DD-YYYY")
            : null,
          end_date_time: this.dateRange.endDate
            ? moment(this.dateRange.endDate).format("MM-DD-YYYY")
            : null,
          time_zone: userTimezone || "America/Chicago",
        });
        
        // Assuming response.data contains the full dataset
        let allData = response.data.data.users;
        // Process and prepare data for export
        var workbook = new Excel.Workbook();
        var worksheet = workbook.addWorksheet("Sheet1");
        worksheet.columns = [
          { header: "Order ID", key: "combined_order_id", width: 20, style: { alignment: {horizontal: 'left'} } },
          { header: "Name", key: "name", width: 20 },
          { header: "Email", key: "email", width: 20 },
          { header: "Event Name", key: "product", width: 20 },
          { header: "Tickets", key: "no_of_tickets", width: 20 },
          { header: "Ticket Type", key: "event_type", width: 20 },
          { header: "Section-Row-Seat", key: "ticket_section", width: 20 },
          { header: "Amount", key: "amount", width: 20 },
          { header: "Payment Method", key: "payment_method", width: 20 },
          { header: "Payment ID", key: "payment_id", width: 20 },
          { header: "Purchase Date", key: "completed_at", width: 20 },
        ];
        
        // Format data for Excel
        let excelData = allData.sort((a, b) => b.combined_order_id - a.combined_order_id)
                              .map(data => {
                                data.completed_at = moment(data.completed_at, "MM/DD/YYYY HH:mm:ss").format("MM/DD/YYYY");
                                return data;
                              });

        worksheet.addRows(excelData);
        worksheet.getRow(1).font = { bold: true };

        const buffer = await workbook.xlsx.writeBuffer();
        const data = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        
        var filename = "Orders_" + new Date().toDateString() + ".xlsx";
        saveAs(data, filename);
      } catch (error) {
        console.error("Error downloading data:", error);
      } finally {
        this.isBusy = false;
      }
    },
    async getData() {
      this.event = {
        product_id: -1,
        formatted_title: "All Events",
        timezone: "CST",
        timezone_name: "America/Chicago",
      };
      this.promoter = {
        promoter_id: -1,
        formatted_promoter: "All Promoters",
      };
      this.event_options = [];
      this.promoter_options = [];
      try {
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        if (
          this.user.role == "operator" ||
          this.user.role == "super-admin" ||
          this.user.role == "admin"
        ) {
          this.anyEvent = true;
          let response = await axios.get("products/list?list_type=dashboard");
          if (response) {
            this.anyEvent = false;
            this.event_options = response.data.data;
            this.event_options.unshift(this.event);
            await this.selectEvent(this.event);
          } else {
            this.anyEvent = false;
            this.event_options = [];
            console.log("error");
          }
        }
        if(
          this.user.role == "super-admin" ||
          this.user.role == "admin"
        ) {
          this.anyPromoter = true;
          let response = await axios.get("operators?list_type=dashboard");
          if (response) {
            this.anyPromoter = false;
            this.promoter_options = []; // Clear the array first
            this.promoter_options = response.data.data; // Assign new values
            this.promoter_options.unshift(this.promoter); // Prepend the `this.promoter` value
            await this.selectPromoter(this.promoter);
          } else {
            this.anyPromoter = false;
            this.promoter_options = [];
            console.log("error");
          }
        }
        await this.dashboardUserData(this.event);
        if (this.user.role == "super-admin" || this.user.role == "admin") {
          let response = await axios.post("dashboard", { product_id: null });
          if (response) {
            this.admin_detail_operator = response.data.data.admin_users.operator
              ? response.data.data.admin_users.operator
              : 0;
            this.admin_detail_customer = response.data.data.admin_users.customer
              ? response.data.data.admin_users.customer
              : 0;
            this.admin_detail_worker = response.data.data.admin_users.worker
              ? response.data.data.admin_users.worker
              : 0;
          } else {
            console.log("error");
          }
        }
      } catch (error) {
        this.anyEvent = false;
        this.anyPromoter = false;
        this.event_options = [];
        this.promoter_options = [];
        console.log(error);
      }
    },
    async selectEvent(event) {
      if(event.product_id != -1){
        await this.dashboardUserData(event);
      }
      // await this.dashboardUserData(event);
    },
    async selectPromoter(promoter) {
      this.promoter = promoter;
      if(promoter.promoter_id != -1){
        await this.dashboardUserData(this.event);
      }
      // await this.dashboardUserData(this.event);
    },
    async dashboardUserData(event) {
      try {
        this.isBusy = true;
        let token = localStorage.getItem("token");
        let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        let response = await axios.post(`dashboard?page=${this.currentPage}&limit=${this.perPage}&string=${this.filter}&sort_by=${this.sortBy}&sorting_order=${this.sortDirection}`, {
          product_id: event.product_id,
          promoter_id: this.promoter.promoter_id,
          start_date_time: this.dateRange.startDate
            ? moment(this.dateRange.startDate).format("MM-DD-YYYY")
            : null,
          end_date_time: this.dateRange.endDate
            ? moment(this.dateRange.endDate).format("MM-DD-YYYY")
            : null,
          time_zone: userTimezone || "America/Chicago",
        });
        if (response) {
          this.isBusy = false;
          this.tableData = [];
          this.tableData = response.data.data.users;
          this.net_sales = response.data.data.net_sales || 0;
          this.gross_sales = response.data.data.gross_sales || 0;
          this.total_tickets = response.data.data.total_tickets || 0;
          this.perPage = response.data.data.per_page || this.perPage;
          this.totalRows = response.data.data.total_count || this.totalRows;
          this.currentPage = response.data.data.current_page || this.currentPage;
        } else {
          this.isBusy = false;
          this.tableData = [];
          console.log("error");
        }
      } catch (error) {
        this.isBusy = false;
        this.tableData = [];
        console.log(error);
      }
    },
    confirmResendMail(id) {
      Swal.fire({
        title: "Are you sure you want to resend the email?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Resend",
      }).then((result) => {
        if (result.value) {
          this.resendMail(id);
        }
      });
    },
    async resendMail(id) {
      try {
        if (this.user.role == "super-admin" || this.user.role == "admin") {
          let token = localStorage.getItem("token");
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          let response = await axios.post("auth/resend-order-email", {
            id: id,
          });
          if (response) {
            this.setCustomValidationMessage(
              response.data.message,
              "success",
              true
            );
          } else {
            this.setCustomValidationMessage(
              localStorage.getItem("error_message"),
              "danger",
              true
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    setDateFilter() {
      this.startDate = null;
      this.endDate = null;

      this.dateRange.startDate = this.startDate;
      this.dateRange.endDate = this.endDate;
      this.ranges = {
        All: [null, null],
        Today: [moment().toDate(), moment().toDate()],
        Yesterday: [
          moment().subtract(1, "days").toDate(),
          moment().subtract(1, "days").toDate(),
        ],
        "Last 7 Days": [
          moment().subtract(6, "days").toDate(),
          moment().toDate(),
        ],
        "Last 30 Days": [
          moment().subtract(29, "days").toDate(),
          moment().toDate(),
        ],
        "This Month": [
          moment().startOf("month").toDate(),
          moment().endOf("month").toDate(),
        ],
        "Last Month": [
          moment().subtract(1, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
        ],
      };
    },
    selectDate() {
      this.dashboardUserData(this.event);
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.dashboardUserData(this.event);
    },
    handlePageSizeChange(perPage) {
      this.perPage = perPage;
      this.dashboardUserData(this.event);
    },
    onSearch() {
      this.dashboardUserData(this.event);
    },
    async resetFilters() {
      this.filter = '';
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      await this.getData();
      // await this.dashboardUserData(this.event);
    },
    sortingOrder(column) {
      this.sortBy = column;
      if (this.sortBy === column) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortBy = column;
        this.sortDirection = "asc";
      }
      this.dashboardUserData(this.event);
    },
  },
  mounted() {
    this.setDateFilter();
    // Set the initial number of items
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user.role == "super-admin" || this.user.role == "admin") {
      let obj = { key: "actions" };
      this.fields.push(obj);
    }
    this.getData();
    this.totalRows = this.items.length;
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-12"></div>
    </div>
    <div v-if="user.role == 'super-admin' || user.role == 'admin'">
      <div class="row">
        <div class="col-xl-4">
          <router-link to="/users/operators">
            <info-card
              :endVal="admin_detail_operator"
              cardText=""
              color="#4ef14c"
              title="Promoters"
            ></info-card>
          </router-link>
        </div>
        <div class="col-xl-4">
          <router-link to="/users/workers">
            <info-card
              :endVal="admin_detail_worker"
              cardText=""
              title="Active Team Members"
            ></info-card>
          </router-link>
        </div>
        <div class="col-xl-4">
          <router-link to="/users/customers">
            <info-card
              :endVal="admin_detail_customer"
              color="#f1b44c"
              cardText=""
              title="Active Customers"
            ></info-card>
          </router-link>
        </div>
      </div>
    </div>

    <div>
      <div class="row mb-2">
        <div class="col-xl-4">
          <div class="mb-2">
              <b>Events</b>
            </div>
          <multiselect
            v-model="event"
            :preselectFirst="true"
            :allowEmpty="false"
            :canDeselect="false"
            :options="event_options"
            label="formatted_title"
            valueProp="product_id"
            track-by="product_id"
            @select="selectEvent"
          >
          </multiselect>
        </div>
        <div class="col-xl-4" v-if="user.role == 'super-admin' || user.role == 'admin'">
          <div class="mb-2">
              <b>Promoters</b>
            </div>
          <multiselect
            v-model="promoter"
            :preselectFirst="true"
            :allowEmpty="false"
            :canDeselect="false"
            :options="promoter_options"
            label="formatted_promoter"
            valueProp="promoter_id"
            track-by="promoter_id"
            @select="selectPromoter"
          >
          </multiselect>
        </div>
        <div class="col-xl-4">
          <div class="row">
          <div class="mb-2">
            <b class="">Date Filter</b>
          </div>
            <date-range-picker
              class="col-md-12"
              ref="picker"
              opens="left"
              placeholder="Select"
              :showDropdowns="true"
              :showWeekNumbers="true"
              :always-show-calendars="false"
              :autoApply="false"
              v-model="dateRange"
              :linkedCalendars="true"
              :dateRange="dateRange"
              :ranges="ranges"
              @update="selectDate"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                <div v-if="!picker.startDate">All</div>
                <div v-else>
                  {{ picker.startDate | dateRangeFormat }} -
                  {{ picker.endDate | dateRangeFormat }}
                </div>
              </template>
            </date-range-picker>
          </div>
        </div>
      </div>
      <div v-if="anyEvent || anyPromoter">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center text-theme m-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Loading...</strong>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!event_options.length">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <div>
                <div class="row justify-content-center">
                  <div class="col-sm-2">
                    <div class="error-img"></div>
                  </div>
                </div>
              </div>
              <h4 class="text-uppercase mt-4">No events available.</h4>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-xl-4" v-if="user.role != 'operator'">
            <info-card
              :endVal="gross_sales"
              symbol="$"
              title="Gross Sales"
            ></info-card>
          </div>
          <div class="col-xl-4">
            <info-card
              :endVal="net_sales"
              symbol="$"
              title="Net Sales"
            ></info-card>
          </div>
          <div class="col-xl-4">
            <info-card
              :endVal="total_tickets"
              color="#f1b44c"
              title="Tickets Sold"
            ></info-card>
          </div>
        </div>

        <div class="row">
          <!-- Search -->
          <div class="col-md-8">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-start"
            >
              <div class="input-group">
                <div class="input-group-text"><i class="uil-search"></i></div>
                <b-form-input
                  v-model="filter"
                  placeholder="Search User"
                  type="search"
                  class="form-control form-control-md px-1"
                  @keyup.enter="onSearch"
                ></b-form-input>
              </div>
            </div>
          </div>
          <!-- End search -->

          <div class="col-md-3">
            <div
              id="tickets-table_length"
              class="dataTables_length text-md-center"
            >
              <label class="d-inline-flex align-items-center fw-normal mt-1">
                Show&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="lg"
                  :options="pageOptions"
                  @input="handlePageSizeChange"
                ></b-form-select
                >&nbsp;entries
              </label>
              <button
                type="button"
                class="btn btn-primary mb-3 float-end"
                @click="resetFilters"
                > Reset Filters
              </button>
            </div>
          </div>
          <div class="col-md-1">
            <div class="float-end">
             <button
                 type="button"
                 @click="downloadCSVData"
                 class="btn btn-info mb-3"
               >
                 <i class="mdi mdi-file me-1"></i> Export
               </button>
           </div>
         </div>
        </div>
        <template>
          <div>
            <div class="table-responsive mb-0">
              <table class="table table-centered datatable table-card-list">
                <thead class="bg-transparent">
                  <tr class="bg-transparent">
                    <th @click="sortingOrder('combined_order_id')">
                      #Order ID
                      <span v-if="sortBy === 'combined_order_id'">
                        <i class="fas" :class="{ 'fa-sort-up': sortDirection === 'asc', 'fa-sort-down': sortDirection === 'desc' }"></i>
                      </span>
                      <span v-else>
                        <i class="fas fa-sort"></i>
                      </span>
                    </th>
                    <th @click="sortingOrder('name')">
                      Name
                       <!-- <span v-if="sortBy === 'name'">
                        <i class="fas" :class="{ 'fa-sort-up': sortDirection === 'asc', 'fa-sort-down': sortDirection === 'desc' }"></i>
                      </span>
                      <span v-else>
                        <i class="fas fa-sort"></i>
                      </span> -->
                    </th>
                    <th>Email</th>
                    <th>Event</th>
                    <th>Tickets</th>
                    <th @click="sortingOrder('refunded_quantity')">
                      Refunded Tickets
                      <span v-if="sortBy === 'refunded_quantity'">
                        <i class="fas" :class="{ 'fa-sort-up': sortDirection === 'asc', 'fa-sort-down': sortDirection === 'desc' }"></i>
                      </span>
                      <span v-else>
                        <i class="fas fa-sort"></i>
                      </span>
                    </th>
                    <th>Ticket Type</th>
                    <th>Section-Row-Seat</th>
                    <th>Amount</th>
                    <th>Refunded Amount</th>
                    <th>Purchased On</th>
                    <th>Payment Method</th>
                    <th>Payment ID</th>
                    <th v-if="user.role == 'super-admin' || user.role == 'admin'">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="isBusy">
                    <td colspan="14" class="text-center text-theme m-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong> Loading...</strong>
                    </td>
                  </tr>
                  <tr v-else v-for="item in tableData" :key="item.order_id">
                    <td>{{ item.combined_order_id }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.product }}</td>
                    <td>{{ item.no_of_tickets }}</td>
                    <td>{{ item.refunded_quantity }}</td>
                    <td>{{ item.event_type }}</td>
                    <td>{{ item.ticket_section }}</td>
                    <td>{{ item.amount }}</td>
                    <td>{{ item.refunded_amount }}</td>
                    <td>{{ item.completed_at | formatDate }}</td>
                    <td>{{ item.payment_method }}</td>
                    <td>{{ item.payment_id }}</td>
                    <td v-if="user.role == 'super-admin' || user.role == 'admin'">
                      <b-dropdown variant="transparent" class="btn-group me-2 mb-2 mb-sm-0" right>
                        <template #button-content>
                          <i class="fas fa-ellipsis-v"></i>
                        </template>
                        <b-dropdown-item @click="confirmResendMail(item.order_id)">Resend Email</b-dropdown-item>
                        <b-dropdown-item>
                          <router-link :to="`/orders/${item.combined_order_id}/${item.special_category_id}`" class="text-dark font-size-15">
                            Cancel Ticket
                          </router-link>
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                  <tr v-if="!isBusy && tableData.length === 0">
                    <td colspan="14" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination style="margin: auto" :total-rows="rows" :value="currentPage"
                      :per-page="perPage"  @input="handlePageChange"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </Layout>
</template>
