<style scoped>
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import moment from 'moment';
import { saveAs } from "file-saver";
const Excel = require("exceljs");
import Swal from "sweetalert2";

export default {
  page: {
    title: "Team Members",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.workers.length;
    },
  },
  data() {
    return {
      workers: [],
      title: "",
      isBusy: false,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      fields: [
        {
          key: "first_name",
          label: "Name",
          sortable: true,
        },
        {
          key: "email",
          sortable: false,
        },
        {
          key: "date",
          sortable: true,
        },
        {
          key: "status",
          sortable: false,
        },
        {
          key: "actions",
          sortable: false,
        },

      ],
    };
  },

  mounted() {
    this.getData();
  },
  methods: {

    confirmResendMail(id) {
      Swal.fire({
        title: "Are you sure you want to resend the email?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Resend",
      }).then((result) => {
        if (result.value) {
          this.resendMail(id);
        }
      });
    },
    async resendMail(id) {
      try {
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        let response = await axios.post("auth/resend-activation-mail", {
          id: id,
        });
        if (response) {
          this.setCustomValidationMessage(
            response.data.message,
            "success",
            true
          );
        } else {
          this.setCustomValidationMessage(
            localStorage.getItem("error_message"),
            "danger",
            true
          );
        }

      } catch (error) {
        console.log(error);
      }
    },

    async getData() {
      try {
        this.isBusy = true;
        let token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        let response = await axios.get("workers");
        if (response) {
          this.isBusy = false;
          this.workers = [];
          this.workers = response.data.data;
        } else{
          this.isBusy = false;
          this.workers = [];
          console.log("error");
        }
      } catch (error) {
        this.canCreate = false;
        this.workers = [];
      }
    },

    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
    },

    async downloadCSVData() {
      //export all product data as an excel file
      var workbook = new Excel.Workbook();
      var worksheet = workbook.addWorksheet("Sheet1");
      worksheet.columns = [
        { header: "Name", key: "first_name", width: 20, style: { alignment: {horizontal: 'left'} } },
        { header: "Email", key: "email", width: 20 },
        { header: "Date", key: "date", width: 20 },
        { header: "Status", key: "status", width: 20 },
      ];
      let excelData = JSON.parse(JSON.stringify(this.workers));
      excelData = excelData.map((data) => {
        let status = data.status == 'Active' ? "Verified" : 'Not Verified';
        data.date = moment(data.date, "MM/DD/YYYY HH:mm:ss").format("MM/DD/YYYY");
        data.status = status;
        return data;
      });

      worksheet.addRows(excelData);
      worksheet.getRow(1).font = { bold: true };

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      var filename = "Team_Members_" + new Date().toDateString() + ".xlsx";
      saveAs(data, filename);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="[]" />
    <div class="row mb-2">
      <div class="col-md-4">
        <h3>Team Members</h3>
      </div>
      <div class="col-md-8">
         <div class="float-end">
          <button
              type="button"
              @click="downloadCSVData"
              class="btn btn-info mb-3"
            >
              <i class="mdi mdi-file me-1"></i> Export
            </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="">
          <div class="">
            <div class="row">
              <div class="row">
                <!-- Search -->
                <div class="col-md-10">
                  <div
                    id="admin-table_filter"
                    class="dataTables_filter text-md-start"
                  >
                    <div class="input-group">
                      <div class="input-group-text">
                        <i class="uil-search"></i>
                      </div>
                      <b-form-input
                        v-model="filter"
                        placeholder="Search Team Member"
                        type="search"
                        class="form-control form-control-md px-1"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
                <!-- End search -->

                <div class="col-md-2">
                  <div
                    id="tickets-table_length"
                    class="dataTables_length text-md-start"
                  >
                    <label
                      class="d-inline-flex align-items-center fw-normal mt-1"
                    >
                      Show&nbsp;&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="lg"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;&nbsp;entries
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                show-empty
                empty-text="No data found"
                :busy="isBusy"
                table-class="table table-centered datatable table-card-list"
                thead-tr-class="bg-transparent"
                :items="workers"
                :fields="fields"
                responsive="sm"
                sort-icon-left
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #table-busy>
                  <div class="text-center text-theme m-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Loading...</strong>
                  </div>
                </template>
                <template #cell(first_name)="data">
                  <span
                    >{{ data.item.first_name }} {{ data.item.last_name }}</span
                  >
                </template>

                <template #cell(contact_number)="data">
                  <span v-if="data.item.contact_number">{{
                    data.item.contact_number | VMask('(###)-###-####')
                  }}</span>
                  <span v-else>
                    -
                  </span>
                </template>

                <template #cell(date)="data">
                  <span>{{ data.item.date | formatDate }}</span>
                </template>

                <template #cell(status)="data">
                  <span
                    class="
                      text-dark
                      badge
                      fw-fold
                      bg-soft-success
                      font-size-12
                      p-2
                    "
                    :class="{
                      'bg-soft-success': data.item.status === 'Active',
                      'bg-soft-danger': data.item.status === 'Inactive',
                    }"
                    >{{ (data.item.status == 'Active') ? 'Verified' : ((data.item.status == 'Inactive') ? 'Not Verified' : data.item.status) }}</span
                  >
                </template>
                <template
                  #cell(actions)="data"
                >
                  <b-button
                    v-if="data.item.status !== 'Active'"
                    class="me-2 text-succdess"
                    @click.prevent="confirmResendMail(data.item.id)"
                    size="sm"
                    variant="transparent"
                    ><i
                      class="far fa-envelope fa-lg"
                      v-b-tooltip.top.hover.o--100
                      title="Resend Activation Mail"
                    ></i
                  ></b-button>
                </template>
              </b-table>
            </div>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      style="margin: auto"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>